import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField as TextFieldMUI,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { FastField, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Clear, PersonAdd } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import LoadingOverlay from 'generic/components/ui/LoadingOverlay';
import { useTheme } from '@mui/material/styles';
import { CONSTANTS } from 'generic/core/constants';

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props) => <Table size="small" {...props} style={{ borderCollapse: 'separate' }} />,
  TableHead,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const UsersMailingList = ({
  tableDatas,
  editLoading,
  filterGroup,
  filterMailingLists,
  goToEmail,
  mailingLists,
  searchUsers,
  handleAddOrRemoveMailingList,
  handleEditMail,
  handleFilterGroup,
  handleFilterMailingLists,
  handleOpenDeleteUserMailingListConfirmDialog,
  handleOpenUserMailingListDialog,
  handleSearchUsers,
}) => {
  const { t } = useTranslation();
  const virtuoso = useRef(null);
  const theme = useTheme();
  const { EMAIL_REGEX } = CONSTANTS;
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (goToEmail) {
      const indexCreateUser = _.findIndex(tableDatas, (user) => user.email === goToEmail);
      if (indexCreateUser > -1) {
        virtuoso.current.scrollToIndex({
          index: indexCreateUser,
          behavior: 'smooth',
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToEmail]);

  return (
    <Fragment>
      {editLoading && (<LoadingOverlay />)}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            px: 2,
            py: 1,
            rowGap: 1,
            columnGap: 4,
            boxSizing: 'border-box',
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box sx={{ width: smallerThanMedium ? '100%' : '380px' }}>
            <TextFieldMUI
              label={t('users_mailing_list.filter_emails')}
              value={searchUsers}
              onChange={handleSearchUsers}
            />
          </Box>
          <Box sx={{ width: smallerThanMedium ? '100%' : '380px' }}>
            <FormControl>
              <InputLabel>{t('users_mailing_list.filter_mailing_list')}</InputLabel>
              <Select
                multiple
                value={filterMailingLists}
                onChange={handleFilterMailingLists}
                renderValue={(selected) => (_.map(selected, 'mailingList').join(', '))}
                input={<OutlinedInput label={t('users_mailing_list.filter_mailing_list')} />}
              >
                {_.orderBy(mailingLists, ['libelle'], ['asc']).map((item) => (
                  <MenuItem
                    key={item.listeDiffusion}
                    value={
                      { idMailingList: item.listeDiffusion, mailingList: item.libelle }
                    }
                  >
                    <Checkbox
                      checked={_.findIndex(filterMailingLists, { idMailingList: item.listeDiffusion }) > -1}
                    />
                    <ListItemText primary={item.libelle} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <RadioGroup
            value={filterGroup}
            name="filter"
            onChange={handleFilterGroup}
            sx={{
              flexDirection: 'row',
            }}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              disabled={_.isEmpty(filterMailingLists)}
              label={t('users_mailing_list.all')}
            />
            <FormControlLabel
              value="nogroup"
              control={<Radio />}
              disabled={_.isEmpty(filterMailingLists)}
              label={t('users_mailing_list.in_nogroup')}
            />
            <FormControlLabel
              value="onegroup"
              control={<Radio />}
              disabled={_.isEmpty(filterMailingLists)}
              label={t('users_mailing_list.in_onegroup')}
            />
            <FormControlLabel
              value="allgroup"
              control={<Radio />}
              disabled={_.isEmpty(filterMailingLists)}
              label={t('users_mailing_list.in_allgroup')}
            />
          </RadioGroup>
        </Box>
        <TableVirtuoso
          data={tableDatas}
          ref={virtuoso}
          components={TableComponents}
          sx={{ boxShadow: 'none' }}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(index, row) => (
            <Fragment>
              <TableCell sx={{ width: smallerThanMedium ? 'calc(100vw - 110px)' : '380px' }}>
                <Formik
                  initialValues={{
                    email: row.email,
                    idsUser: row.idsUser,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup
                      .string()
                      .email(t('form.errors.not_valid_email'))
                      .matches(EMAIL_REGEX, t('form.errors.not_valid_email'))
                      .required(t('form.errors.mandatory')),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    handleEditMail(values);
                    setSubmitting(false);
                  }}
                  enableReinitialize
                  validateOnMount
                >
                  {(form) => (
                    <Form
                      onKeyPress={(event) => {
                        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
                          form.handleSubmit();
                        }
                      }}
                    >
                      <FastField
                        component={TextField}
                        fullWidth
                        label={t('users_mailing_list.email')}
                        name="email"

                      />
                    </Form>
                  )}
                </Formik>
                {smallerThanMedium && (
                  <FormControl sx={{ width: 'calc(100vw - 110px)' }}>
                    <InputLabel>{t('users_mailing_list.mailing_list')}</InputLabel>
                    <Select
                      multiple
                      value={_.filter(row.mailingLists, 'checked')}
                      onChange={
                        (event, value) => handleAddOrRemoveMailingList(
                          value.props.value.checked,
                          value.props.value.idMailingList,
                          value.props.value.idUser,
                          row,
                        )
                      }
                      renderValue={(selected) => (_.map(selected, 'libelle').join(', '))}
                      input={<OutlinedInput label={t('users_mailing_list.mailing_list')} />}
                    >
                      {row.mailingLists.map((item) => (
                        <MenuItem
                          key={item.listeDiffusion}
                          value={
                            { checked: !item.checked, idMailingList: item.idMailingList, idUser: item.idUser }
                          }
                        >
                          <Checkbox checked={item.checked} />
                          <ListItemText primary={item.libelle} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              {!smallerThanMedium && (
                <TableCell align="left" sx={{ width: 'calc(100vw - 520px)' }}>
                  {_.map(row.mailingLists, (item) => (
                    <FormControlLabel
                      key={item.idMailingList}
                      label={(
                        <Typography noWrap>
                          {item.libelle}
                        </Typography>
                      )}
                      title={item.libelle}
                      sx={{ width: '11.25rem' }}
                      control={(
                        <Checkbox
                          checked={item.checked}
                          onChange={
                            (event) => handleAddOrRemoveMailingList(
                              event.target.checked,
                              item.idMailingList,
                              item.idUser,
                              row,
                            )
                          }
                        />
                      )}
                    />
                  ))}
                </TableCell>
              )}
              <TableCell align="right">
                <TooltipButton
                  title={t('actions.delete')}
                  tag="fab"
                  color="error"
                  size="extraSmall"
                  sx={{ zIndex: '1' }}
                  onClick={() => handleOpenDeleteUserMailingListConfirmDialog(row.idsUser)}
                >
                  <Clear />
                </TooltipButton>
              </TableCell>
            </Fragment>
          )}
        />
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            gap: 1,
            p: 1,
            borderTop: '1px solid',
            borderColor: theme.palette.mode === 'dark' ? '#515151' : '#e0e0e0',
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpenUserMailingListDialog}
            startIcon={<PersonAdd />}
          >
            {t('users_mailing_list.create')}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

UsersMailingList.propTypes = {
  tableDatas: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    idsUser: PropTypes.arrayOf(PropTypes.number),
    mailingLists: PropTypes.arrayOf(PropTypes.shape({
      checked: PropTypes.bool,
      idMailingList: PropTypes.number.isRequired,
      idUser: PropTypes.number,
      libelle: PropTypes.string,
    })),
  })).isRequired,
  editLoading: PropTypes.bool.isRequired,
  filterGroup: PropTypes.string.isRequired,
  filterMailingLists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  goToEmail: PropTypes.string.isRequired,
  mailingLists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchUsers: PropTypes.string.isRequired,
  handleAddOrRemoveMailingList: PropTypes.func.isRequired,
  handleEditMail: PropTypes.func.isRequired,
  handleFilterGroup: PropTypes.func.isRequired,
  handleFilterMailingLists: PropTypes.func.isRequired,
  handleOpenDeleteUserMailingListConfirmDialog: PropTypes.func.isRequired,
  handleOpenUserMailingListDialog: PropTypes.func.isRequired,
  handleSearchUsers: PropTypes.func.isRequired,
};

export default UsersMailingList;
