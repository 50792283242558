import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';

const BanSiteDialog = ({
  host,
  banReasons,
  handleClose,
  handleSubmit,
  loading = false,
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');

  return (
    <Dialog
      scroll="paper"
      onKeyPress={(event) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
          handleSubmit(reason);
        }
      }}
      open
    >
      <DialogTitle>
        {t('anr.ban_site')}
      </DialogTitle>
      <DialogContent
        dividers
      >
        <Fragment>
          {t('anr.ban_site_sure')}
          &nbsp;
          <b>{host}</b>
          &nbsp;?
        </Fragment>
        <FormControl
          sx={{ marginTop: 2, width: '100%' }}
        >
          <InputLabel id="select-reason-label">
            {t('anr.ban_reason_label')}
          </InputLabel>
          <Select
            label={t('anr.ban_reason_label')}
            value={reason}
            labelId="select-reason-label"
            onChange={(event) => setReason(event.target.value)}
            displayEmpty
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {_.map(banReasons, (value, key) => (
              <MenuItem key={key} value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => handleSubmit(reason)}
          color="error"
        >
          {t('ui.yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

BanSiteDialog.propTypes = {
  banReasons: PropTypes.shape().isRequired,
  host: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default BanSiteDialog;
