import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TextSnippet } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import UploadFilesContainer from 'generic/containers/UploadFilesContainer';

const PreviewMedia = ({ type, preview }) => {
  let previewMedia = 'iframe';
  let styleSx = {
    width: '100%',
    height: '500px',
  };
  if (_.includes(type, 'video')) {
    previewMedia = 'video';
    styleSx = {
      maxWidth: '500px',
      maxHeight: '300px',
    };
  } else if (_.includes(type, 'audio')) {
    previewMedia = 'audio';
    styleSx = {
      maxWidth: '500px',
    };
  } else if (_.includes(type, 'image')) {
    previewMedia = 'img';
    styleSx = {
      maxWidth: '500px',
      maxHeight: '300px',
    };
  }

  return (
    <CardMedia
      sx={{
        '&.MuiCardMedia-root': {
          ...styleSx,
        },
      }}
      component={previewMedia}
      controls
      src={preview}
    />
  );
};

PreviewMedia.propTypes = {
  type: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
};

const UploadFilesDialog = ({
  corpora,
  idCorpusSelect = null,
  filesContents = {},
  filesToUpload = [],
  handleClose,
  handleSelectCorpus,
  handleUploadClick,
  withCollections,
  noChoiceSelection,
  ...props
}) => {
  const { t } = useTranslation();

  const appTheme = useTheme();
  const smallerThanLarge = useMediaQuery(appTheme.breakpoints.down('lg'));

  const previewOpen = _.find(filesToUpload, { openPreview: true });

  return (
    <Dialog
      scroll="paper"
      fullWidth
      open
      sx={{
        '& .MuiDialog-paper': {
          flexDirection: smallerThanLarge ? 'column' : 'row',
          gap: 2,
          maxWidth: _.isEmpty(filesToUpload) ? '900px' : 'none',
        },
      }}
    >
      <Box sx={{ width: !_.isEmpty(previewOpen) && !smallerThanLarge ? '67%' : '100%' }}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <TextSnippet color="primary" />
          {t('collections.documents.add_dialog')}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            height: '500px',
          }}
        >
          {withCollections && (
            <FormControl size="small">
              <InputLabel>{t('collections.corpus.type')}</InputLabel>
              <Select
                value={idCorpusSelect}
                onChange={handleSelectCorpus}
                displayEmpty
                input={<OutlinedInput label={t('collections.corpus.type')} />}
                disabled={noChoiceSelection}
              >
                {(
                  corpora.map((corpus) => (
                    <MenuItem
                      key={corpus.collection}
                      value={corpus.collection}
                    >
                      {corpus.libelle}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          )}
          <UploadFilesContainer
            filesContents={filesContents}
            filesToUpload={filesToUpload}
            {...props}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>{t('ui.close')}</Button>
          <LoadingButton
            onClick={handleUploadClick}
            disabled={_.isEmpty(filesToUpload) || _.filter(filesToUpload, (item) => !item.success).length === 0}
            loading={_.find(filesToUpload, { loading: true }) !== undefined}
          >
            {t('file_upload.upload')}
          </LoadingButton>
        </DialogActions>
      </Box>
      {!_.isEmpty(previewOpen) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            boxSizing: 'border-box',
            ...(!smallerThanLarge && {
              width: '33%',
            }),
          }}
        >
          <PreviewMedia type={previewOpen.type} preview={previewOpen.preview} />
        </Box>
      )}
    </Dialog>
  );
};

UploadFilesDialog.propTypes = {
  corpora: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    collection: PropTypes.number,
    collection_parente: PropTypes.number,
    description: PropTypes.string,
    libelle: PropTypes.string,
    utilisateur: PropTypes.number,
  })).isRequired,
  idCorpusSelect: PropTypes.number,
  filesContents: PropTypes.shape({ current: PropTypes.shape() }),
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  handleClose: PropTypes.func.isRequired,
  handleSelectCorpus: PropTypes.func.isRequired,
  handleUploadClick: PropTypes.func.isRequired,
  withCollections: PropTypes.bool.isRequired,
  noChoiceSelection: PropTypes.bool.isRequired,
};

export default UploadFilesDialog;
