import React from 'react';
import PropTypes from 'prop-types';
import { LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import Dailymotion from 'generic/components/icons/Dailymotion';
import { prettyPrintNumber } from 'generic/utils/mathUtils';
import ANR_CONSTANTS from 'generic/core/anr/constants';

const { QUOTES_TYPES_MAPPING } = ANR_CONSTANTS;

const NetworkProfile = ({
  atfrom = '',
  followersCount = null,
  quoteType,
  quoteFrom = '',
  iconFontSize = 'small',
}) => {
  let QuoteProfileIcon;
  if (quoteType === QUOTES_TYPES_MAPPING.TWITTER) {
    QuoteProfileIcon = <Twitter color="twitter" fontSize={iconFontSize} />;
  } else if (quoteType === QUOTES_TYPES_MAPPING.YOUTUBE) {
    QuoteProfileIcon = <YouTube color="youtube" fontSize={iconFontSize} />;
  } else if (quoteType === QUOTES_TYPES_MAPPING.DAILYMOTION) {
    QuoteProfileIcon = <Dailymotion color="primary" fontSize={iconFontSize} />;
  } else if (quoteType === QUOTES_TYPES_MAPPING.LINKEDIN) {
    QuoteProfileIcon = <LinkedIn color="linkedin" fontSize={iconFontSize} />;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: '0' }}>
      {QuoteProfileIcon}
      <Box sx={{ ml: '2px' }}>
        <Typography
          component="div"
          variant="body2"
          color="text.neutral"
        >
          {quoteFrom}
          {followersCount && ` (${prettyPrintNumber(followersCount, true)} followers)`}
        </Typography>
        {atfrom && (
          <Typography
            component="div"
            variant="body2"
            color="text.neutral"
          >
            {`@${atfrom}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

NetworkProfile.propTypes = {
  atfrom: PropTypes.string,
  followersCount: PropTypes.number,
  quoteFrom: PropTypes.string,
  quoteType: PropTypes.number.isRequired,
  iconFontSize: PropTypes.oneOf([
    'inherit',
    'large',
    'medium',
    'small',
  ]),
};

export default NetworkProfile;
