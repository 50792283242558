import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { overrideRessource } from 'generic/utils/utils';
import { qesdocumentPropType } from 'generic/core/qes/proptypes';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { Delete, Edit, Menu } from '@mui/icons-material';

const DocumentForWidgetList = overrideRessource('components/documents/DocumentForWidgetList');

const DocumentList = ({
  title = {
    text: '',
    align: 'center',
  },
  qesdocuments,
  handleDocumentClick = _.noop,
  handleOpenFastGed = _.noop,
  handleOpenEdition = null,
  handleOpenDeleteDialog = null,
  groupField = null,
  fullHeight = false,
}) => {
  const { t } = useTranslation();

  const currentTheme = useColorSchemeDetector();

  let finalDocs = qesdocuments;
  if (groupField) {
    finalDocs = _.reduce(qesdocuments, (acc, curr) => {
      const groupName = curr[groupField];
      if (_.isEmpty(acc) || (_.last(acc).groupName !== groupName)) {
        const header = {
          label: groupName,
          isHeader: true,
          nbDocs: _.filter(qesdocuments, { [groupField]: groupName }).length,
        };
        acc.push(header);
      }
      acc.push({
        ...curr,
        groupName,
      });
      return acc;
    }, []);
  }

  const stickyProps = {
    position: 'sticky',
    zIndex: 1200,
    backgroundColor: 'background.paper',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 10px)' }}>
      <Box sx={{ display: 'flex', pb: '16px', alignItems: 'center' }}>
        {!_.isEmpty(title.text) && (
          <Typography
            component="div"
            sx={{
              ...currentTheme.HIGHCHARTS.title.style,
              top: 0,
              flexGrow: '1',
              height: '35px',
              paddingTop: '5px',
              paddingLeft: '15px',
              textAlign: title.align || 'center',
            }}
          >
            {title.text}
          </Typography>
        )}
        {(handleOpenEdition || handleOpenDeleteDialog) && (
          <DropdownMenu
            btnChildren={<Menu fontSize="medium" />}
          >
            {handleOpenEdition && (
              <MenuItem
                onClick={handleOpenEdition}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('dashboard.edit_widget_menu')}
                />
              </MenuItem>
            )}
            {handleOpenDeleteDialog && (
              <MenuItem
                onClick={handleOpenDeleteDialog}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('dashboard.delete_widget')}
                />
              </MenuItem>
            )}
          </DropdownMenu>
        )}
      </Box>

      <List dense sx={{ padding: '0', overflowY: fullHeight ? 'unset' : 'auto' }}>
        {_.isEmpty(qesdocuments) && (
          <ListItemText
            primaryTypographyProps={{ textAlign: 'center' }}
          >
            {t('results.no_results')}
          </ListItemText>
        )}
        {!_.isEmpty(qesdocuments) && (
          finalDocs.map(
            (item) => {
              if (item?.isHeader) {
                return (
                  <ListItem
                    key={item.label}
                    alignItems="flex-start"
                    dense
                    sx={{
                      ...stickyProps,
                      top: '35px',
                      borderBottom: '1px solid #aaa',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: '15px',
                    }}
                  >
                    <Tooltip title={item.label}>
                      <Typography noWrap sx={{ width: '75%' }}>{item.label}</Typography>
                    </Tooltip>
                    {item.nbDocs}
                  </ListItem>
                );
              }
              return (
                <ListItem
                  key={item?.idext}
                  alignItems="flex-start"
                >
                  <DocumentForWidgetList
                    qesdocument={item}
                    handleDocumentClick={() => handleDocumentClick(item.idext)}
                    openFastGed={() => handleOpenFastGed(item.idext)}
                  />
                </ListItem>
              );
            },
          )
        )}
      </List>
    </Box>
  );
};

DocumentList.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string,
    align: PropTypes.oneOf(['start', 'end', 'left', 'right', 'center', 'justify', 'match-parent']),
  }),
  qesdocuments: PropTypes.arrayOf(qesdocumentPropType).isRequired,
  handleDocumentClick: PropTypes.func,
  handleOpenFastGed: PropTypes.func,
  handleOpenEdition: PropTypes.func,
  handleOpenDeleteDialog: PropTypes.func,
  groupField: PropTypes.string,
  fullHeight: PropTypes.bool,
};

export default DocumentList;
