/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  fetchWidgets,
} from 'generic/core/dashboard/actions';
import { snackActions } from 'generic/utils/snackbar';
import Highcharts from 'highcharts';
import 'react-grid-layout/css/styles.css';
import {
  getColorForFrequency,
  getRandomColor,
  getToneColor,
  monoColors,
} from 'generic/utils/colorUtils';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import {
  OpenInNewOutlined,
} from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import { format } from 'generic/utils/dateUtils';
import {
  getActiveBaseDashboardParams,
  getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';
import goToAlienVault from 'generic/utils/alienVault';
import goToMitre from 'generic/utils/mitreUtils';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import Bar from 'generic/components/dashboard-items/Bar';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import Datatable from 'generic/components/dashboard-items/Datatable';
import DocumentList from 'generic/components/dashboard-items/DocumentList';
import Map from 'generic/components/dashboard-items/Map';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Pie from 'generic/components/dashboard-items/Pie';
import Sankey from 'generic/components/dashboard-items/Sankey';
import SolidGauge from 'generic/components/dashboard-items/SolidGauge';
import Sunburst from 'generic/components/dashboard-items/Sunburst';
import Treemap from 'generic/components/dashboard-items/Treemap';
import WidgetContainer from 'generic/containers/WidgetContainer';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';

const computeTitle = (title, serie) => {
  let finalTitle = title || '';
  if (!_.isEmpty(serie)) {
    const { totalUnique } = getValueOrFirstValueFromArray(serie);
    if (totalUnique) {
      finalTitle = `${finalTitle} (${totalUnique})`;
    }
  }

  return finalTitle;
};

const generateDatatableFirstCell = (dispatch, fields, fieldName, computeLink) => (value) => (
  <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'flex-start' }}>
    <IconButton
      size="small"
      onClick={
        () => { window.open(computeLink(value), '_blank'); }
      }
      sx={{
        padding: 0,
        marginRight: 1,
        '& .MuiSvgIcon-root': {
          color: 'primary.attenuated',
        },
        '&:hover .MuiSvgIcon-root': {
          color: 'primary.main',
        },
      }}
    >
      <OpenInNewOutlined fontSize="small" />
    </IconButton>
    <Typography
      sx={{
        width: '240px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '14px',
      }}
      title={value}
    >
      {value}
    </Typography>
  </Box>
);

const DashboardPreviewChartsContainer = ({ widget }) => {
  const currentTheme = useColorSchemeDetector();
  const { entitiesColors, relationsEntitiesColors } = currentTheme.HIGHCHARTS;
  const strategy = useSelector((state) => state.search.results.strategie);
  const widgetRef = useRef({});

  const {
    base: activeBaseId,
    champs: fields,
  } = useSelector((state) => state.config.activeBase);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  Highcharts.setOptions(currentTheme.HIGHCHARTS);

  const {
    COOCCURRENCES_FIELDS,
    RELATIONS_NODES_FIELDS,
    RELATIONS_LINKS_FIELDS,
  } = getActiveBaseDashboardParams(activeBaseId);

  let cooccurrenceFields = _.map(
    COOCCURRENCES_FIELDS,
    (field, index) => ({
      ...field,
      color: entitiesColors[index],
    }),
  );

  let relationsNodesFields = _.map(
    RELATIONS_NODES_FIELDS,
    (field, index) => ({
      ...field,
      color: RELATIONS_NODES_FIELDS.length > 15 ? getRandomColor(index) : relationsEntitiesColors[index],
    }),
  );

  let relationsLinksFields = _.map(
    RELATIONS_LINKS_FIELDS,
    (field, index) => ({
      ...field,
      color: getRandomColor(index),
    }),
  );
  // On utilise ici une ref pour pouvoir la mettre à jour
  // depuis le widget cooccurrences sans re-render tout le
  // dashboard
  let activeCooccurrencesFields = [];
  if (cooccurrenceFields) {
    activeCooccurrencesFields = _.map(_.filter(cooccurrenceFields, 'active'), 'value');
  }
  const cooccurrencesNodesFilterValue = useRef(activeCooccurrencesFields);
  const cooccurrencesNodesFilterValueJoined = cooccurrencesNodesFilterValue.current.join(',');

  let activeRelationsNodesFields = [];
  if (relationsNodesFields) {
    activeRelationsNodesFields = _.map(_.filter(relationsNodesFields, 'active'), 'value');
  }
  const relationsNodesFilterValue = useRef(activeRelationsNodesFields);
  let activeRelationsLinksFields = [];
  if (relationsLinksFields) {
    activeRelationsLinksFields = _.map(_.filter(relationsLinksFields, 'active'), 'value');
  }
  const relationsLinksFilterValue = useRef(activeRelationsLinksFields);
  const relationsLinksFilterValueJoined = relationsLinksFilterValue.current.join(',');

  const buildNodesTypesAdditionalQuery = () => {
    const nodesTypesSources = [];
    const nodesTypesDestinations = [];
    _.forEach(
      relationsNodesFilterValue.current,
      (nodeTypeValue) => {
        nodesTypesSources.push(
          `QES_Relation_Source_Type:${nodeTypeValue}`,
        );
        nodesTypesDestinations.push(
          `QES_Relation_Destination_Type:${nodeTypeValue}`,
        );
      },
    );
    let additionalQuery = '';
    if (nodesTypesSources) {
      additionalQuery = `(${nodesTypesSources.join(' OR ')}) AND (${nodesTypesDestinations.join(' OR ')})`;
    }
    return additionalQuery;
  };

  const widgetAltered = { ...widget };
  let preConditionOk = !_.isEmpty(strategy);

  if (widgetAltered.aggregates) {
    widgetAltered.aggregates = _.map(widgetAltered.aggregates.split(','), _.trim);
  } else if (widgetAltered.aggregates === '') {
    widgetAltered.aggregates = [];
  }
  if (widgetAltered.pivots) {
    widgetAltered.pivots = _.map(widgetAltered.pivots.split(','), _.trim);
  } else if (widgetAltered.pivots === '') {
    widgetAltered.pivots = [];
  }
  if (['sankey', 'sunburst'].includes(widgetAltered.type)) {
    if (widgetAltered.facets.length < 2) {
      preConditionOk = false;
    }
  }
  if (widgetAltered.facets && _.isArray(widgetAltered.facets)) {
    widgetAltered.facets = widgetAltered.facets.join(',');
  }
  if (widgetAltered.type === 'relations') {
    widgetAltered.relations = relationsLinksFilterValueJoined;
    widgetAltered.aggregates = [relationsLinksFilterValueJoined];
    widgetAltered.additionalQuery = buildNodesTypesAdditionalQuery();
  } else if (widgetAltered.type === 'cooccurrences') {
    widgetAltered.facets = cooccurrencesNodesFilterValueJoined;
    widgetAltered.aggregates = [cooccurrencesNodesFilterValueJoined];
  }

  if (!_.isEqual(widget, widgetRef.current) && preConditionOk) {
    dispatch(fetchWidgets(strategy, [widgetAltered], true));
    widgetRef.current = widget;
  }

  const getLinkColor = (linkItem) => {
    let linkColor = '#c5c5c5';
    if (theme.palette.dark === 'dark') {
      linkColor = '#dddddd';
    }
    if (linkItem) {
      if (linkItem.type === 'multipleLinks') {
        linkColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';
      } else {
        linkColor = _.find(relationsLinksFields, { value: linkItem.type })?.color || linkColor;
      }
    }
    return linkColor;
  };

  const globalHighChartsOptions = {
    options: {
      accessibility: { enabled: false },
      credits: { enabled: false },
    },
  };

  const renderWidget = (widgetParams) => {
    switch (widgetParams.type) {
      case 'spline':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
            }) => (
              <ColumnHisto
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        tooltip: {
                          formatter: function tooltipFormatter() {
                            // eslint-disable-next-line react/no-this-in-sfc
                            return `${format(this.x)}: <b>${this.y}</b>`;
                          },
                        },
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                        series,
                        yAxis: {
                          type: 'logarithmic',
                          allowNegativeLog: true,
                          title: { text: '' },
                        },
                        legend: false,
                        xAxis: {
                          title: { text: '' },
                          startOnTick: true,
                          endOnTick: true,
                          gridLineWidth: 0,
                          dateTimeLabelFormats: {
                            second: '%d/%m/%Y<br/>%H:%M:%S',
                            minute: '%d/%m/%Y<br/>%H:%M',
                            hour: '%d/%m/%Y<br/>%H:%M',
                            day: '%Y<br/>%d/%m',
                            month: '%m/%Y',
                            year: '%Y',
                          },
                        },
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'bar':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
            }) => (
              <Bar
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        tooltip: {
                          enabled: false,
                        },
                        legend: false,
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                        yAxis: {
                          title: {
                            text: '',
                          },
                        },
                        series,
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'map':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
            }) => (
              <Map
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        plotOptions: {
                          series: {
                            tooltip: {
                              pointFormatter: function getTranslatedCountryName() {
                                // eslint-disable-next-line react/no-this-in-sfc
                                return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                              },
                            },
                            point: {
                              events: {
                                mouseOver: (event) => {
                                  if (event.target.value !== undefined) {
                                    const { element } = event.target.graphic;
                                    element.style.cursor = 'pointer';
                                  }
                                },
                              },
                            },
                          },
                        },
                        series,
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'sunburst':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
            }) => (
              <Sunburst
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        series: [{
                          data: _.get(series, '[0].data', []),
                        }],
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'documentlist':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({ series }) => (
              <DocumentList
                title={{
                  text: computeTitle(widgetParams.title, series),
                  align: 'left',
                  margin: 20,
                }}
                qesdocuments={_.get(series, '[0]', [])}
              />
            )}
          </WidgetContainer>
        );
      case 'pie':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
            }) => {
              let data = _.get(series, '[0].data', []);
              if (widgetParams.tones) {
                data = _.sortBy(_.get(series, '[0].data', []), 'name').map(
                  (item) => ({
                    ...item,
                    color: getToneColor(item.name),
                  }),
                );
              }
              const additionnalOptions = {};
              if (widgetParams.monoColors) {
                additionnalOptions.colors = monoColors(0.35, _.get(series, '[0].data.length', 15), 0);
              }
              return (
                <Pie
                  highchartsOptions={
                    _.merge(
                      {},
                      globalHighChartsOptions,
                      {
                        options: {
                          exporting: {
                            enabled: false,
                          },
                          plotOptions: {
                            pie: {
                              data,

                            },
                          },
                          title: {
                            text: computeTitle(widgetParams.title, series),
                            align: 'left',
                            margin: 20,
                          },
                          ...additionnalOptions,
                        },
                      },
                    )
                  }
                />
              );
            }}
          </WidgetContainer>
        );
      case 'solidgauge':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
              axisX,
            }) => (
              <SolidGauge
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        pane: {
                          background: axisX.categories.map((aggregate, index) => ({
                            outerRadius: aggregate.outerRadius,
                            innerRadius: aggregate.innerRadius,
                            borderWidth: 0,
                            backgroundColor: `${Highcharts.getOptions().colors[index]}4D`,
                          })),
                        },
                        tooltip: {
                          enabled: false,
                        },
                        legend: {
                          align: 'center',
                          labelFormatter: function lf() {
                            // eslint-disable-next-line react/no-this-in-sfc
                            return `${this.name} - ${this.data[0].y}%`;
                          },
                        },
                        series: _.get(series, '[0].data', []).map((aggregate, index) => ({
                          name: aggregate.name,
                          data: aggregate.data.map((aggdata) => ({
                            radius: aggdata.radius,
                            innerRadius: aggdata.innerRadius,
                            name: aggdata.name,
                            y: aggdata.y,
                            color: Highcharts.getOptions().colors[index],
                          })),
                          color: Highcharts.getOptions().colors[index],
                          showInLegend: true,
                        })),
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'sankey':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
              // additionalData,
            }) => (
              <Sankey
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        series,
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'treemap':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
            }) => (
              <Treemap
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        plotOptions: {
                          treemap: {
                            animation: false,
                            colorByPoint: true,
                          },
                        },
                        series,
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'cooccurrences':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({ series, additionalData }) => {
              cooccurrenceFields = cooccurrenceFields.map(
                (cooccurrenceField) => ({
                  ...cooccurrenceField,
                  nbNodes: _.get(additionalData?.nodeCounts, cooccurrenceField.value, 0),
                }),
              );
              return (
                <NetworkGraph
                  isPreview
                  nodesFilterValue={cooccurrencesNodesFilterValue.current}
                  nodesFilterItems={cooccurrenceFields}
                  handleRefreshChart={(filterValues) => {
                    if (_.isEmpty(filterValues)) {
                      snackActions.warning(t('dashboard.widget.at_least_or_node'));
                      return;
                    }
                    cooccurrencesNodesFilterValue.current = filterValues;
                    const keepOtherWidgetsData = true;
                    const joinedValue = filterValues.join(',');
                    dispatch(fetchWidgets(strategy, [
                      {
                        dashboard_widget: widgetParams.dashboard_widget,
                        facets: joinedValue,
                        aggregates: [joinedValue],
                        facetmax: 200,
                        facetmax2: 5,
                        mindoccount: 1,
                        type: 'cooccurrences',
                      },
                    ], keepOtherWidgetsData));
                  }}
                  highchartsOptions={
                    _.merge(
                      {},
                      globalHighChartsOptions,
                      {
                        options: {
                          exporting: {
                            enabled: false,
                          },
                          chart: {
                            height: 720,
                          },
                          series: [{
                            nodes: _.get(series, '[0].nodes', []).map(
                              (node) => ({
                                ...node,
                                color: _.find(cooccurrenceFields, { value: node.group })?.color,
                              }),
                            ),
                            data: _.get(series, '[0].data', []).map(
                              (link) => ({
                                ...link,
                                color: getLinkColor(),
                              }),
                            ),
                          }],
                          title: {
                            text: computeTitle(widgetParams.title, series),
                            align: 'left',
                            margin: 20,
                          },
                        },
                      },
                    )
                  }
                />
              );
            }}
          </WidgetContainer>
        );
      case 'relations':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({ series, additionalData }) => {
              relationsLinksFields = relationsLinksFields.map(
                (relationsLinksField) => ({
                  ...relationsLinksField,
                  nbLinks: _.get(additionalData?.linkCounts, relationsLinksField.value, 0),
                }),
              );
              relationsNodesFields = relationsNodesFields.map(
                (relationsNodesField) => ({
                  ...relationsNodesField,
                  nbNodes: _.get(additionalData?.nodeCounts, relationsNodesField.value, 0),
                }),
              );
              return (
                <NetworkGraph
                  isPreview
                  iterations={additionalData?.iterations}
                  withExploration={widgetParams.withExploration}
                  linksFilterValue={relationsLinksFilterValue.current}
                  linksFilterItems={_.orderBy(
                    relationsLinksFields,
                    ['nbLinks', (item) => relationsLinksFilterValue.current.indexOf(item.value) > -1, 'name'],
                    ['desc', 'desc', 'asc'],
                  )}
                  nodesFilterValue={relationsNodesFilterValue.current}
                  nodesFilterItems={_.orderBy(
                    relationsNodesFields,
                    ['nbNodes', 'name'],
                    ['desc', 'asc'],
                  )}
                  handleRefreshChart={(nodesTypesValues, linksTypesValues) => {
                    if (_.isEmpty(nodesTypesValues) || _.isEmpty(linksTypesValues)) {
                      snackActions.warning(t('dashboard.widget.at_least_one_link_or_node'));
                      return;
                    }
                    relationsNodesFilterValue.current = nodesTypesValues;
                    relationsLinksFilterValue.current = linksTypesValues;
                    const joinedTypesValue = linksTypesValues.join(',');

                    const keepOtherWidgetsData = true;
                    dispatch(fetchWidgets(
                      strategy,
                      [
                        {
                          dashboard_widget: widgetParams.dashboard_widget,
                          facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
                          relations: joinedTypesValue,
                          aggregates: [joinedTypesValue],
                          facetmax: 200,
                          facetmax2: 5,
                          mindoccount: 1,
                          additionalQuery: buildNodesTypesAdditionalQuery(),
                          type: 'relations',
                        },
                      ],
                      keepOtherWidgetsData,
                    ));
                  }}
                  highchartsOptions={
                    _.merge(
                      {},
                      globalHighChartsOptions,
                      {
                        options: {
                          exporting: {
                            enabled: false,
                          },
                          chart: {
                            height: 720,
                          },
                          series: [{
                            nodes: _.get(series, '[0].nodes', []).map(
                              (node) => ({
                                ...node,
                                color: _.find(relationsNodesFields, { value: node.group })?.color,
                              }),
                            ),
                            data: _.get(series, '[0].data', []).map(
                              (link) => ({
                                ...link,
                                color: getLinkColor(link),
                              }),
                            ),
                          }],
                          title: {
                            text: computeTitle(widgetParams.title, series),
                            align: 'left',
                            margin: 20,
                          },
                        },
                      },
                    )
                  }
                />
              );
            }}
          </WidgetContainer>
        );
      case 'wordcloud':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({
              series,
            }) => (
              <Wordcloud
                component={Wordcloud}
                highchartsOptions={
                  _.merge(
                    {},
                    globalHighChartsOptions,
                    {
                      options: {
                        exporting: {
                          enabled: false,
                        },
                        tooltip: {
                          formatter: function tooltipFormatter() {
                            // eslint-disable-next-line react/no-this-in-sfc
                            return `${this.key}: <b>${this.point.weight}</b>`;
                          },
                        },
                        series: [{
                          data: _.map(_.get(series, '[0].data', []), ([word, weight]) => ({
                            name: word,
                            weight,
                            color: getColorForFrequency(
                              theme.palette.mode === 'dark' ? 0.55 : 0.35,
                              series[0].minFreq,
                              series[0].maxFreq,
                              weight,
                              0,
                              theme.palette.mode === 'dark' ? '#ffffff' : null,
                            ),
                          })),
                          name: widgetParams.title,
                        }],
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: 'left',
                          margin: 20,
                        },
                      },
                    },
                  )
                }
              />
            )}
          </WidgetContainer>
        );
      case 'datatable':
        return (
          <WidgetContainer widgetId={widgetParams.dashboard_widget}>
            {({ series, additionalData }) => {
              const firstColumn = { name: '' };
              if (widgetParams.computeLink) {
                let computeLinkMethod;
                if (widgetParams.computeLink === 'goToMitre') {
                  computeLinkMethod = goToMitre;
                } else if (widgetParams.computeLink === 'goToAlienVault') {
                  computeLinkMethod = goToAlienVault;
                }
                firstColumn.cellRenderer = generateDatatableFirstCell(
                  dispatch,
                  fields,
                  additionalData.facets,
                  computeLinkMethod,
                );
              }
              return (
                <Datatable
                  title={{
                    text: computeTitle(widgetParams.title, series),
                    align: 'left',
                    margin: 20,
                  }}
                  columns={[
                    firstColumn,
                    { name: t('dashboard.widget.count'), align: 'right' },
                  ]}
                  data={_.get(series, '[0].data', [])}
                />
              );
            }}
          </WidgetContainer>
        );
      default:
        return () => _.noop;
    }
  };

  return (
    <Box className="layout" sx={{ width: '100%', height: '100%' }}>
      {preConditionOk && renderWidget(widgetAltered)}
    </Box>
  );
};

DashboardPreviewChartsContainer.propTypes = {
  widget: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    dashboard_widget: PropTypes.number.isRequired, // id du widget
  }).isRequired,
};

export default React.memo(DashboardPreviewChartsContainer);
