import React from 'react';
import { overrideRessource } from 'generic/utils/utils';
import PropTypes from 'prop-types';

const Document = overrideRessource('components/documents/Document');

const DocumentWrapper = ({ fallbackForLoadable = '', ...rest }) => (
  <Document fallback={fallbackForLoadable} {...rest} />
);

DocumentWrapper.propTypes = {
  fallbackForLoadable: PropTypes.node,
};

export default DocumentWrapper;
