import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box, CircularProgress, Divider, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Add,
} from '@mui/icons-material';
import MUIDataTable from 'mui-datatables';
import { sourcePropType } from 'generic/core/anr/proptypes';
import TooltipButton from 'generic/components/ui/TooltipButton';

const ResultsSources = ({
  count = 0,
  columns = [],
  handleOpenDemandSourceDialog,
  handlePageChangePagination,
  handleSearch,
  loading = false,
  page = 0,
  sortOrder,
  sources = [],
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const options = {
    count,
    download: false,
    filter: true,
    filterType: 'dropdown',
    fixedHeader: true,
    jumpToPage: true,
    page,
    print: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    selectableRows: 'none',
    serverSide: true,
    responsive: responsiveTableType,
    setTableProps: () => ({ size: 'small' }),
    sortOrder,
    textLabels: t('datatable.textLabels', { returnObjects: true }),
    onChangePage: () => {
      const table = document.querySelector('#datatable .MuiTable-root');
      table.scrollIntoView(true);
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    customToolbar: () => (
      <Box sx={{ display: 'flex' }}>
        <Divider orientation="vertical" sx={{ mx: 1 }} />
        <TooltipButton tag="iconButton" onClick={handleOpenDemandSourceDialog} title={t('anr.demand_source')}>
          <Add />
        </TooltipButton>
      </Box>
    ),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChangePagination(tableState);
          break;
        case 'sort':
        case 'search':
        case 'filterChange':
        case 'resetFilters':
          handleSearch(tableState);
          break;
        default:
          break;
      }
    },
  };
  return (
    <Box
      sx={{
        width: '100%',
        m: smallerThanLarge ? 0 : 2,
      }}
      id="datatable"
    >
      <MUIDataTable
        title={(
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">{t('anr.sources')}</Typography>
            {loading && <CircularProgress size={16} style={{ marginLeft: 10 }} />}
          </Box>
        )}
        data={sources}
        columns={columns}
        options={options}
        dense
      />
    </Box>
  );
};

ResultsSources.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()),
  count: PropTypes.number,
  handleOpenDemandSourceDialog: PropTypes.func.isRequired,
  handlePageChangePagination: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  page: PropTypes.number,
  sortOrder: PropTypes.shape().isRequired,
  sources: PropTypes.arrayOf(sourcePropType),
};

export default ResultsSources;
