import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ConfirmDialog = ({
  loading,
  handleSubmit,
  handleClose,
  title,
  textButton = 'ui.yes',
  dialogContent,
  submitColor = 'primary',
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      scroll="paper"
      onClose={handleClose}
      onKeyPress={(event) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
          handleSubmit();
        }
      }}
      open
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ minHeight: '100px', minWidth: '450px' }}
      >
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          color={submitColor}
        >
          {t(textButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  textButton: PropTypes.string,
  dialogContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitColor: PropTypes.string,
};

export default ConfirmDialog;
