import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  FormContainer, FormFieldsContainer, LoadingButtonWithIndicator, PasswordTextField,
} from 'generic/components/forms/FormLoginCommonComponents';

const FormResetPasswordConfirmation = ({ loading }) => {
  const { t } = useTranslation();

  const usernameInput = useRef(null);

  return (
    <FormContainer
      title={t('logon.reset_password_confirmation_title')}
    >
      <FormFieldsContainer>

        <Field name="a" type="hidden" />

        <Field
          autoFocus
          component={PasswordTextField}
          name="newPassword"
          label={t('logon.reset_password_confirmation_field_label')}
          placeholder={t('logon.password_placeholder')}
          innerRef={usernameInput}
          required
          autoComplete="new-password"
        />

        <Field
          component={PasswordTextField}
          name="newPasswordConfirmation"
          label={t('logon.reset_password_confirmation_field_label')}
          placeholder={t('logon.password_confirmation')}
          required
          autoComplete="new-password"
        />

        <LoadingButtonWithIndicator
          disabled={loading}
          loading={loading}
          type="submit"
        >
          {t('logon.reset_password_confirmation_submit')}
        </LoadingButtonWithIndicator>
      </FormFieldsContainer>
    </FormContainer>
  );
};

FormResetPasswordConfirmation.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default FormResetPasswordConfirmation;
