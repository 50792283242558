import React, { Fragment, useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
  MenuItem,
  TextField,
  Menu,
} from '@mui/material';
import { Category } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';

const DropdownWithCreate = ({
  onClick,
  onCreate,
  title,
  values = [],
  disabled = true,
  color = 'secondary',
  size = 'small',
  inputLabel = i18next.t('form.create_new_value'),
  btnTitle = '',
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    stopImmediatePropagation(event);
    setAnchorEl(null);
    setValue('');
  };

  const handleItemClick = (event) => {
    const newValue = event.currentTarget.getAttribute('data-value');
    onClick(newValue);
    handleClose(event);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && value !== '') {
      const newValue = value;
      onCreate(newValue);
      handleClose(event);
    }
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Fragment>
      <TooltipButton
        variant="contained"
        onClick={handleClick}
        disabled={disabled}
        color={color}
        size={size}
        title={title}
        startIcon={<Category />}
      >
        {btnTitle}
      </TooltipButton>

      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        <MenuItem
          dense
          divider
          onClickCapture={stopImmediatePropagation}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <TextField
            label={inputLabel}
            variant="outlined"
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
          />
        </MenuItem>

        {values.map((str) => (
          <MenuItem key={str} onClick={handleItemClick} data-value={str}>
            {str}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

DropdownWithCreate.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  inputLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  btnTitle: PropTypes.string,
};

export default DropdownWithCreate;
