import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  ListItemButton,
  TextField,
} from '@mui/material';
import { CreateNewFolder, Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import FolderItem from 'generic/components/pages/anr/FolderItem';
import { folderPropType } from 'generic/core/anr/proptypes';

const Folders = ({
  folders = [],
  hasGlimpse,
  activeItemId = null,
  canCreateFolders = false,
  canCollapseRoot = true,
  noResults,
  numberFolders = 0,
  searchFolders = '',
  handleToggleFavoriteStatus = null,
  handleOpenDeleteConfirmDialog = null,
  handleEditNewFolder = null,
  handleSearchFolders = null,
}) => {
  const { t } = useTranslation();
  const currentItemRef = useRef();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const {
    scroll,
  } = useParams();

  useEffect(() => {
    if (!smallerThanLarge && scroll && !_.isEmpty(currentItemRef.current)) {
      setTimeout(() => currentItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' }, 0));
    }
  }, [activeItemId, currentItemRef, scroll, smallerThanLarge]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{
        display: 'flex',
        overflow: 'auto',
        flexGrow: '1',
      }}
    >
      <Box
        sx={{
          overflow: 'auto',
          flexGrow: '1',
        }}
      >
        {folders && (
          <React.Fragment>
            {hasGlimpse && (
              <ListItemButton
                component={Link}
                to="/anr/folders/0/glimpse"
                selected={activeItemId === 'glimpse'}
                sx={{
                  paddingLeft: 1.5,
                  fontWeight: (activeItemId === 'glimpse') ? 500 : 400,
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: 1,
                  }}
                >
                  <Visibility />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('anr.glimpse')}
                />
              </ListItemButton>
            )}
            {numberFolders >= 20 && (
              <TextField
                label={t('anr.actions.filter')}
                value={searchFolders}
                onChange={handleSearchFolders}
                sx={{
                  mt: 2,
                  width: '90%',
                  mx: '5%',
                }}
              />
            )}
            {_.map(folders, (list, index) => (
              <React.Fragment key={index === 0 ? 'favorites' : 'not_favorites'}>
                {index === 0 && !_.isEmpty(list) && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mx: '10%',
                      my: 1,
                    }}
                  >
                    <Divider className="flexGrow1" mx="10%">
                      <Typography>{t('anr.favorites')}</Typography>
                    </Divider>
                  </Box>
                )}
                {_.map(list, (item) => (
                  <FolderItem
                    activeItemId={activeItemId}
                    canCollapseRoot={canCollapseRoot}
                    canCreateFolders={canCreateFolders}
                    currentItemRef={currentItemRef}
                    handleEditNewFolder={handleEditNewFolder}
                    handleOpenDeleteConfirmDialog={handleOpenDeleteConfirmDialog}
                    handleToggleFavoriteStatus={handleToggleFavoriteStatus}
                    searchFolders={searchFolders}
                    levelSearch={0}
                    levelTree={0}
                    allHighlight={false}
                    completeRoot={false}
                    item={item}
                    key={item.id}
                  />
                ))}
                {index === 0 && !_.isEmpty(list) && (
                  <Box
                    sx={{
                      mx: '10%',
                      my: 1,
                    }}
                  >
                    <Divider />
                  </Box>
                )}
              </React.Fragment>
            ))}
            {noResults && (
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {t('results.no_search_results')}
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
      <Box
        sx={{ display: 'flex' }}
      >
        {canCreateFolders && (
          <Button
            component={Link}
            to="/anr/folders/0/new"
            color="primary"
            onClick={(event) => handleEditNewFolder(event, {})}
            className="leftColumnAnRBtn"
            startIcon={<CreateNewFolder />}
          >
            {t('anr.new_folder')}
          </Button>
        )}
      </Box>
    </Grid>
  );
};

Folders.propTypes = {
  activeItemId: PropTypes.string,
  canCollapseRoot: PropTypes.bool,
  canCreateFolders: PropTypes.bool,
  folders: PropTypes.arrayOf(
    PropTypes.arrayOf(folderPropType), // Premier tableau les favoris
    PropTypes.arrayOf(folderPropType), // Second tableau les non favoris
  ),
  handleEditNewFolder: PropTypes.func,
  handleOpenDeleteConfirmDialog: PropTypes.func,
  handleSearchFolders: PropTypes.func,
  handleToggleFavoriteStatus: PropTypes.func,
  hasGlimpse: PropTypes.bool.isRequired,
  noResults: PropTypes.bool.isRequired,
  numberFolders: PropTypes.number,
  searchFolders: PropTypes.string,
};

export default Folders;
