import React, { useMemo } from 'react';
import reactDom from 'react-dom';

import { Dialog, DialogContent } from '@mui/material';

import GEDContainer from 'generic/containers/GEDContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fastGedClose } from 'generic/core/ged/actions';
import { fetchResultsComplete, refreshResults } from 'generic/core/search/actions';

const GEDModalContainer = () => {
  const dispatch = useDispatch();
  const { id, base, scope } = useSelector((state) => state.ged.fastGed);
  const isVisible = !!(id || base); // si pas d'id, c'est une création, sinon, c'est une édition

  const isResultCompleteScope = (scope === 'result-complete');

  const dialog = useMemo(() => {
    if (!isVisible) { return null; }

    const afterSave = () => {
      dispatch(fastGedClose());
      if (isResultCompleteScope) dispatch(fetchResultsComplete(id));
      const keepSelection = true;
      dispatch(refreshResults(keepSelection));
    };

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isVisible}
      >
        <DialogContent sx={{
          maxHeight: '80vh', wrap: 'nowrap', display: 'flex', p: 0,
        }}
        >
          <GEDContainer
            id={id}
            base={base}
            afterSave={afterSave}
            variant="light"
            onCloseDialog={() => dispatch(fastGedClose())}
            sx={{
              '.GEDContent': {
                px: 1,
              },
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }, [base, dispatch, id, isResultCompleteScope, isVisible]);

  return reactDom.createPortal(dialog, document.body);
};

GEDModalContainer.propTypes = {};

export default GEDModalContainer;
