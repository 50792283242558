import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
} from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';

const ArticleViewActions = ({
  handleOpenNextArticle,
  handleOpenPreviousArticle,
  openNextDisabled = false,
  openPreviousDisabled = false,
}) => (
  <Fragment>
    <IconButton
      size="small"
      onClick={handleOpenPreviousArticle}
      disabled={openPreviousDisabled}
    >
      <KeyboardArrowLeft />
    </IconButton>
    <IconButton
      size="small"
      onClick={handleOpenNextArticle}
      disabled={openNextDisabled}
    >
      <KeyboardArrowRight />
    </IconButton>
  </Fragment>
);

ArticleViewActions.propTypes = {
  handleOpenNextArticle: PropTypes.func.isRequired,
  handleOpenPreviousArticle: PropTypes.func.isRequired,
  openNextDisabled: PropTypes.bool,
  openPreviousDisabled: PropTypes.bool,
};

export default ArticleViewActions;
