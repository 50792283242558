import { types } from 'generic/core/sendToFriend/actions';
import { types as actionsTypes } from 'generic/core/actions/actions';

const sendToFriendReducer = (
  state = {
    data: {},
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case types.SEND_TO_FRIEND_BUILD: {
      return {
        ...state,
        building: true,
        data: {},
      };
    }
    case types.SEND_TO_FRIEND_BUILD_SUCCESS: {
      return {
        ...state,
        building: false,
        data: action.data,
      };
    }
    case types.SEND_TO_FRIEND_BUILD_ERROR: {
      return {
        ...state,
        building: false,
        data: {},
      };
    }
    case types.SEND_TO_FRIEND_SUBMIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.SEND_TO_FRIEND_SUBMIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {},
      };
    }
    case types.SEND_TO_FRIEND_SUBMIT_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
      };
    }
    case actionsTypes.CLOSE_DIALOG_SEND_TO_FRIEND: {
      return {
        ...state,
        loading: false,
        data: {},
      };
    }
    default:
      return state;
  }
};

export default sendToFriendReducer;
