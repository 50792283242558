import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  Skeleton,
} from '@mui/material';
import DocumentLiteSkeleton from 'generic/components/skeletons/DocumentLiteSkeleton';

const DocumentsListSkeleton = ({
  nbDocs = 3,
  withHeader = false,
  withLastLine = true,
  withImages = false,
}) => {
  const rows = [];
  if (withHeader) {
    rows.push(
      <ListItem key="header">
        <Box sx={{ width: 'inherit', my: 0.5 }}>
          <Skeleton variant="text" width="100%" height={25} />
        </Box>
      </ListItem>,
    );
  }

  for (let i = 0; i < nbDocs; i += 1) {
    rows.push(
      <ListItem key={`item${i}`}>
        <Box sx={{ width: 'inherit', ml: 1, my: 0.5 }}>
          <DocumentLiteSkeleton withImages={withImages} withLastLine={withLastLine} />
        </Box>
      </ListItem>,
    );
  }
  return (
    <List dense sx={{ py: 0 }}>
      {rows}
    </List>
  );
};

DocumentsListSkeleton.propTypes = {
  nbDocs: PropTypes.number,
  withHeader: PropTypes.bool,
  withImages: PropTypes.bool,
  withLastLine: PropTypes.bool,
};

export default DocumentsListSkeleton;
