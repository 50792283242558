export const types = {
  SET_CONFIRM_DIALOG_LOADING: '@CONFIRMDIALOG/SET_CONFIRM_DIALOG_LOADING',
  UNSET_CONFIRM_DIALOG_LOADING: '@CONFIRMDIALOG/UNSET_CONFIRM_DIALOG_LOADING',
  SET_CONFIRM_DIALOG_DATA: '@CONFIRMDIALOG/SET_CONFIRM_DIALOG_DATA',
  UNSET_CONFIRM_DIALOG_DATA: '@CONFIRMDIALOG/UNSET_CONFIRM_DIALOG_DATA',
  CONFIRM_DIALOG_SUBMIT: '@CONFIRMDIALOG/CONFIRM_DIALOG_SUBMIT',
};

/**
 * Dispatch the current state of the dialog actually open
 * @param {object} dialogData
 * @param {string[]} payload.waitForActions actions to listen to, to dispose the dialog (`@FOO/SUCCESS`, `@BAR/ERROR`)
 * @param {object} payload.action redux action to dispatch as is
 */
export const setConfirmDialogSumbit = (dialogData) => ({
  type: types.CONFIRM_DIALOG_SUBMIT,
  dialogData,
});

export const setConfirmDialogLoading = () => ({
  type: types.SET_CONFIRM_DIALOG_LOADING,
});

export const unsetConfirmDialogLoading = () => ({
  type: types.UNSET_CONFIRM_DIALOG_LOADING,
});

/**
 * Configure and open the generic dialog
 *
 * @param {object} payload configuration
 * @param {string} payload.title dialog title
 * @param {string} payload.message dialog main message
 * @param {string} [payload.textButton=ui.yes] text to be displayed on the "submit" button
 * @param {string} [payload.submitColor=primary] color of the submit button
 * @param {string[]} payload.waitForActions actions to listen to, to dispose the dialog
 * @param {object} payload.action redux action to be dispatched as is when the submit button will be clicked
 */
export const setConfirmDialogData = (payload) => ({
  type: types.SET_CONFIRM_DIALOG_DATA,
  payload,
});

/**
 * Resets the generic dialog config to its initial state (ie: closed and empty)
 */
export const unsetConfirmDialogData = () => ({
  type: types.UNSET_CONFIRM_DIALOG_DATA,
});
