import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { articlePropType } from 'generic/core/anr/proptypes';
import { setSingleItem } from 'generic/core/selection/actions';
import { openDialogAddToCart, openDialogAddToNewsletter, openDialogSendToFriend } from 'generic/core/actions/actions';
import {
  collect,
  openBanSiteDialog,
  openShareDialog,
  types,
} from 'generic/core/anr/actions';
import { setBackPageLocation } from 'generic/core/ux/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import ArticleActions from 'generic/components/ui/anr/ArticleActions';
import { cleanupResultsComplete } from 'generic/core/search/actions';

const ArticleActionsContainer = ({
  article = {},
  visible = false,
  elevation = true,
}) => {
  const baseId = useSelector((state) => state.config.settings.baseInterne_368);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const handleOpenInGoogleTranslate = () => {
    window.open(`${article.anrUrl}&window=1&translate=1`);
  };

  const handleOpenShareDialog = () => {
    if (navigator.share) {
      navigator.share({
        title: article.title,
        url: article.shareUrl,
      });
    } else {
      dispatch(openShareDialog(article));
    }
  };

  const handleOpenDeleteArticleDialog = () => {
    dispatch(setConfirmDialogData({
      title: t('anr.actions.delete'),
      textButton: 'form.delete',
      message: (
        <Fragment>
          {t(article.is_quote ? 'anr.actions.delete_quote' : 'anr.actions.delete_article')}
          &nbsp;
          <b>{article.title}</b>
          &nbsp;?
        </Fragment>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_ARTICLES_SUCCESS, types.DELETE_ARTICLES_ERROR],
      action: {
        type: types.DELETE_ARTICLES,
        articles: [article],
      },
    }));
  };

  const handleOpenInNewTab = () => {
    window.open(`${article.anrUrl}&window=true`);
  };

  const handleOpenBanSiteDialog = () => {
    dispatch(openBanSiteDialog(article));
  };

  const handleOpenReportExtractionDialog = () => {
    dispatch(setConfirmDialogData({
      title: t('anr.report_extraction_title'),
      message: (
        <Trans>
          {t(
            'anr.report_extraction_text',
            { source: article.source },
          )}
        </Trans>
      ),
      submitColor: 'error',
      waitForActions: [types.REPORT_EXTRACTION_SUCCESS, types.REPORT_EXTRACTION_ERROR],
      action: {
        type: types.REPORT_EXTRACTION,
        article,
      },
    }));
  };

  const handleCollect = () => {
    dispatch(collect(article));
  };

  const handleOpenAddToCartDialog = () => {
    dispatch(setSingleItem(article));
    dispatch(openDialogAddToCart());
  };

  const handleOpenAddToNewsletterDialog = () => {
    dispatch(setSingleItem(article));
    dispatch(openDialogAddToNewsletter());
  };

  const handleOpenSendToFriend = () => {
    dispatch(setSingleItem(article));
    dispatch(openDialogSendToFriend());
  };

  const handleOpenGed = () => {
    let externalIdKey = 'validation.external_id';
    // Si jamais l'article est dans un Œil sur l'actu, validation sera
    // un tableau (car l'article peut-être validé dans plusieurs dossiers)
    // quoiqu'il arrive, on prendra le premier objet "validation" trouvé
    if (Array.isArray(_.get(article, 'validation', null))) {
      externalIdKey = 'validation[0].external_id';
    }
    const externalId = _.get(article, externalIdKey, null);
    if (!_.isEmpty(externalId)) {
      // On s'assure qu'on a bien un ID QES avant de basculer, on ne
      // sait jamais...
      dispatch(setBackPageLocation(location));
      dispatch(cleanupResultsComplete());
      history.push(`/ged/${baseId}/${encodeURIComponent(externalId)}`);
    }
  };

  return (
    <ArticleActions
      article={article}
      handleOpenAddToCartDialog={handleOpenAddToCartDialog}
      handleOpenAddToNewsletterDialog={handleOpenAddToNewsletterDialog}
      handleOpenBanSiteDialog={handleOpenBanSiteDialog}
      handleOpenDeleteArticleDialog={handleOpenDeleteArticleDialog}
      handleOpenReportExtractionDialog={handleOpenReportExtractionDialog}
      handleOpenInGoogleTranslate={handleOpenInGoogleTranslate}
      handleOpenInNewTab={handleOpenInNewTab}
      handleOpenShareDialog={handleOpenShareDialog}
      handleOpenSendToFriend={handleOpenSendToFriend}
      handleCollect={handleCollect}
      handleOpenGed={handleOpenGed}
      visible={visible}
      elevation={elevation}
    />
  );
};

ArticleActionsContainer.propTypes = {
  article: articlePropType,
  elevation: PropTypes.bool,
  visible: PropTypes.bool,
};

export default ArticleActionsContainer;
