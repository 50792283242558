import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  getContrastRatio,
  useTheme,
} from '@mui/material';

const IconWithBackground = ({
  backgroundColor = 'primary.main',
  fontSize = 'extraSmall',
  icon: Icon,
}) => {
  const theme = useTheme();
  const bgColor = _.get(theme.palette, backgroundColor, backgroundColor);
  const color = getContrastRatio(bgColor, '#fff') > 3 ? '#ffffff' : '#000000';
  let pxSize = '32px';
  if (fontSize === 'medium') {
    pxSize = '48px';
  }
  return (
    <Box
      component="span"
      sx={{
        alignItems: 'center',
        backgroundColor: bgColor,
        borderRadius: '50%',
        display: 'inline-flex',
        height: pxSize,
        width: pxSize,
      }}
    >
      <Icon sx={{ color, margin: 'auto' }} fontSize={fontSize} />
    </Box>
  );
};

IconWithBackground.propTypes = {
  backgroundColor: PropTypes.string,
  fontSize: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default IconWithBackground;
