import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CollectionsMonitoring from 'generic/components/pages/CollectionsMonitoring';
import { forceRefreshMonitoring } from 'generic/core/collections/actions';

const CollectionsMonitoringContainer = () => {
  const dispatch = useDispatch();
  const [statusFilter, setStatusFilter] = useState('all');

  const handleForceRefresh = (idTraitement) => {
    dispatch(forceRefreshMonitoring(idTraitement));
  };

  const traitementsQta = useSelector((state) => {
    if (!_.isEmpty(statusFilter) && statusFilter !== 'all') {
      return state.collections.activeCollection
        ?.traitementsQta
        ?.filter((traitement) => traitement.etat === statusFilter) || [];
    }

    return state.collections.activeCollection.traitementsQta || [];
  });

  return (
    <CollectionsMonitoring
      traitementsQta={traitementsQta}
      loading={false}
      setStatusFilter={setStatusFilter}
      statusFilter={statusFilter}
      handleForceRefresh={handleForceRefresh}
    />
  );
};

export default CollectionsMonitoringContainer;
