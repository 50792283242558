import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Layout from 'generic/components/pages/Layout';

const PublicRoutes = ({
  component: Component,
  path,
  location = { state: { referrer: {} } },
  title = 'Infomedia',
  exact = true,
}) => {
  const logged = useSelector((state) => state.auth.logged);
  if (logged) {
    let referrerRedirect = _.get(location, 'state.referrer', '/');
    if (referrerRedirect.pathname === '/logout') {
      referrerRedirect = '/';
    }

    return <Redirect to={referrerRedirect} />;
  }

  document.title = title;
  return (
    <Layout
      exact={exact}
      path={path}
      component={Component}
    />
  );
};

PublicRoutes.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      referrer: PropTypes.shape().isRequired,
    }),
  }),
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  exact: PropTypes.bool,
};

export default PublicRoutes;
