import { addSeconds } from 'date-fns';
import { getRandomColor } from 'generic/utils/colorUtils';
import { format } from 'generic/utils/dateUtils';

/**
 * Computes the transcription data from raw text JSON and raw translation.
 *
 * @param {string} rawTextJSON - The raw text in JSON format (an array of sentences).
 * @param {string} rawAnnotations - The raw annotations in JSON format. (an array of sentences like objects)
 * @param {string} rawTranslation - The raw translation text. (a string with carriage returns)
 * @param {Date} recordingDate - The date of the recording.
 * @param {Array} speakersColors - List of hexa colors for the speakers.
 * @returns {Object|null} transcription - The computed transcription object or null if rawTextJSON is empty.
 * @returns {Object} transcription.speakers - The speakers with their assigned colors.
 * @returns {Array} transcription.sentences - The sentences with their translations.
 */
const useComputedTranscription = (rawTextJSON, rawAnnotations, rawTranslation, recordingDate, speakersColors) => {
  if (rawTextJSON === null || rawTextJSON.length === 0) {
    return null;
  }

  let translatedSentences = [];
  if (rawTranslation !== null && rawTranslation.indexOf('\n') !== -1) {
    translatedSentences = rawTranslation.split('\n');
  }

  const transcription = {
    speakers: {/* speakerId, speakerColor */},
    sentences: [
      // { ...annotations }
      // { ...sentence, startDate, translation }
    ],
  };
  const sentencesParsed = JSON.parse(rawTextJSON);
  const annotationsParsed = JSON.parse(rawAnnotations) || [];
  let finalTranscriptionSentences = [];
  const enoughTranslations = (translatedSentences.length === sentencesParsed.length);
  let millisTimer = 0;
  sentencesParsed.forEach((sentence, index) => {
    finalTranscriptionSentences = finalTranscriptionSentences.concat(
      annotationsParsed.filter((annotation) => {
        const inRange = annotation.start >= millisTimer && annotation.start < sentence.start;
        return inRange;
      }),
    );

    if (!transcription.speakers[sentence.spkid]) {
      const speakersLength = Object.keys(transcription.speakers).length;
      let speakerColor;
      if (speakersLength < speakersColors.length) {
        speakerColor = speakersColors[speakersLength];
      } else {
        speakerColor = getRandomColor(speakersLength, 'hexa');
      }
      transcription.speakers[sentence.spkid] = speakerColor;
    }

    finalTranscriptionSentences.push({
      ...sentence,
      startDate: recordingDate
        ? format(addSeconds(recordingDate, +sentence.start), "P HH:mm''ss")
        : null,
      translation: enoughTranslations ? translatedSentences[index] : null,
    });
    millisTimer = sentence.start;
  });
  finalTranscriptionSentences = finalTranscriptionSentences.concat(
    annotationsParsed.filter((annotation) => annotation.start > millisTimer),
  );
  transcription.sentences = finalTranscriptionSentences;

  return transcription;
};

// eslint-disable-next-line import/prefer-default-export
export { useComputedTranscription };
