import { Box, Skeleton } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

/** @type {import('@mui/material').SxProps<import('@mui/material').Theme>} */
const commonSx = (theme) => ({
  margin: 'auto',
  objectFit: 'cover',
  display: 'block',
  borderRadius: '4px',
  boxSizing: 'border-box',
  border: '1px solid',
  borderColor: theme.palette.divider,
});

const ImgLoader = ({
  src,
  fallbackSrc = null,
  fallbackComponent = null,
  skeletonClassName = '',
  alt = 'Image with no alt text',
  minHeight = null,
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const { width, height = null } = { ...rest };

  const onError = () => {
    if (fallbackSrc !== null && fallbackSrc !== imgSrc) {
      setImgSrc(fallbackSrc);
    } else {
      setIsLoaded(true);
      setIsErrored(true);
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    setImgSrc(src);
    setIsErrored(false);
  }, [src]);

  return !_.isEmpty(fallbackComponent) && isErrored ? (
    fallbackComponent
  ) : (
    <Fragment>
      {!isLoaded && (
        <Skeleton
          variant="rectangular"
          component="div"
          width={width}
          height={height || minHeight}
          sx={commonSx}
          className={skeletonClassName}
        />
      )}
      <Zoom zoomImg={{ src: imgSrc }} zoomMargin={80}>
        <Box
          component="img"
          src={imgSrc}
          sx={[commonSx, !isLoaded && { width: 0, height: 0, border: 'none' }]}
          onLoad={() => setIsLoaded(true)}
          onError={onError}
          alt={alt}
          {...rest}
        />
      </Zoom>
    </Fragment>
  );
};

ImgLoader.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fallbackComponent: PropTypes.node,
  fallbackSrc: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  skeletonClassName: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ImgLoader;
