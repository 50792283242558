import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';

import { cartOrNewsletterDocumentPropType } from 'generic/core/qes/proptypes';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);
const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const DocumentForNewsletter = ({
  panierDocument,
  handleGetDocumentComplete = null,
}) => {
  const {
    title, linkout, date, source,
  } = useDocumentFields(panierDocument);

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }

  return (
    <Box sx={{ display: 'block', alignItems: 'center' }}>
      <Typography
        component="span"
        sx={{ display: 'block' }}
      >
        <Box
          {...titleLinkProps}
          color="text.primary"
          sx={{ cursor: 'pointer' }}
        >
          {title}
        </Box>

        <Box
          color="text.neutral"
        >
          {panierDocument.texte}
        </Box>
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexGrow: '1',
          lineHeight: '1rem',
          wordBreak: 'break-word',
        }}
      >
        {date && (
          <Fragment>
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              sx={{ fontSize: '0.8rem' }}
            >
              {date}
            </Typography>
            <Box sx={{ mx: 1, py: '3px' }}>
              <Divider
                orientation="vertical"
              />
            </Box>
          </Fragment>
        )}
        {source && (
          <Typography
            component="span"
            variant="body2"
            color="text.neutral"
            sx={{ fontSize: '0.8rem' }}
          >
            {source}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

DocumentForNewsletter.propTypes = {
  panierDocument: cartOrNewsletterDocumentPropType.isRequired,
  handleGetDocumentComplete: PropTypes.func,
};

export default DocumentForNewsletter;
