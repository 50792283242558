import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  IconButton,
  Popper,
  TextField,
  Tooltip,
} from '@mui/material';
import TranscriptionWord from 'generic/components/ui/TranscriptionWord';
import {
  Delete,
  Edit,
  Save,
  SmsOutlined,
} from '@mui/icons-material';
import { formatTime } from 'generic/utils/dateUtils';
import SentenceCheckboxContainer from 'generic/containers/SentenceCheckboxContainer';
import { useSingleAndDoubleClick } from 'generic/core/hooks/useSingleAndDoubleClick';
import { t } from 'i18next';
import { transcriptionSentencePropType } from 'generic/core/qes/proptypes';

const TranscriptionSentence = ({
  activeUserId,
  currentMediaTime = null,
  displayHours = true,
  displayTranslatedSentence = false,
  handleTranscriptClick,
  handleOpenPatchSpeakerDialog,
  handleOpenEditAnnotationDialog,
  handleOpenConfirmDeleteAnnotationDialog,
  index,
  patchDocumentInComplete,
  sentence,
  speakers,
  toggleManualUserEdition,
  wasRead = false,
  displayDatetime = false,
}) => {
  const theme = useTheme();

  const previousAnchorElPosition = useRef(undefined);
  const selectionStartTime = useRef(undefined);
  const selectionEndTime = useRef(undefined);
  const selectedWordsStarts = useRef([]);
  const [selectedText, setSelectedText] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef();
  const cartouchRef = useRef();
  const [cartouchSize, setCartouchSize] = useState(0);
  const translationRef = useRef();
  const [translationHeight, setTranslationHeight] = useState(0);

  const handleCloseEditionPopper = useCallback(() => {
    if (!_.isEmpty(selectedText) && !_.isEmpty(anchorEl)) {
      selectedWordsStarts.current = [];
      setSelectedText(null);
      setAnchorEl(null);
      toggleManualUserEdition(false);
    }
  }, [anchorEl, selectedText, toggleManualUserEdition]);

  const handleEditTranscription = (event, fromInput) => {
    if (!fromInput || event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      const { value } = inputRef.current;
      if (value) {
        patchDocumentInComplete({
          transcription_detection: 'Text_json',
          transcription_sentence_start: parseFloat(sentence.start),
          transcription_word_start: selectionStartTime.current,
          transcription_word_end: selectionEndTime.current,
          transcription_replace_with: value,
        });
        handleCloseEditionPopper();
      }
    }
    if (fromInput && event.key === 'Escape') {
      handleCloseEditionPopper();
    }
  };

  useEffect(() => {
    if (cartouchRef.current) {
      setCartouchSize(cartouchRef.current.clientWidth);
    }
  }, [cartouchRef]);

  useEffect(() => {
    if (translationRef.current && cartouchSize) {
      setTranslationHeight(translationRef.current.clientHeight);
    }
  }, [translationRef, cartouchSize, displayTranslatedSentence]);

  useEffect(() => {
    if (anchorEl) {
      if (typeof anchorEl === 'object') {
        previousAnchorElPosition.current = anchorEl.getBoundingClientRect();
      } else {
        previousAnchorElPosition.current = anchorEl().getBoundingClientRect();
      }
    }
  }, [anchorEl]);

  useEffect(() => (
    () => handleCloseEditionPopper()
  ), [handleCloseEditionPopper]);

  // Le hook useSingleAndDoubleClick permet de définir une action pour le simple ET le
  // double clic. Ici on veut déclencher une action UNIQUEMENT sur le double clic (donc
  // on ne définit rien pour le premier paramètre)
  const handleOpenPatchSpeakerDialogLocal = () => {
    handleOpenPatchSpeakerDialog(sentence.spkid, sentence.start);
  };
  const clicks = useSingleAndDoubleClick(null, handleOpenPatchSpeakerDialogLocal);

  const handleSelectMenu = () => {
    const selection = document.getSelection();

    if (!selection || selection.anchorOffset === selection.focusOffset) {
      handleCloseEditionPopper();
      return;
    }

    const getBoundingClientRect = () => {
      if (selection.rangeCount === 0 && previousAnchorElPosition.current) {
        setSelectedText(null);
        return previousAnchorElPosition.current;
      }
      return selection.getRangeAt(0).getBoundingClientRect();
    };
    const startTime = selection.anchorNode.parentElement.getAttribute('start');
    let endTime = selection.focusNode.parentElement.getAttribute('start');
    if (!endTime) {
      if (sentence.sentence.startsWith(selection.text)) {
        endTime = sentence.words[0].start;
      } else {
        // NE FONCTIONNE PAS : Si start était dans la phrase précédente (le div au dessus),
        // on ne pourra pas retrouver le texte
        endTime = _.findLast(sentence.words, (word) => !_.isNil(word.start)).start;
      }
    }
    if (startTime && endTime) {
      const chronologicalStart = Math.min(parseFloat(startTime), parseFloat(endTime));
      const chronologicalEnd = Math.max(parseFloat(startTime), parseFloat(endTime));
      if (chronologicalStart >= sentence.start
        && chronologicalEnd <= parseFloat(_.findLast(sentence.words, (word) => !_.isNil(word.start)).start)) {
        let textToEdit = '';
        sentence.words.forEach(
          (word) => {
            if (parseFloat(word.start) >= chronologicalStart
              && parseFloat(word.start) <= chronologicalEnd) {
              textToEdit += `${word.word.trim()} `;
              selectedWordsStarts.current.push(word.start);
            }
          },
        );
        if (textToEdit) {
          setSelectedText(textToEdit.trim());
          selectionStartTime.current = chronologicalStart;
          selectionEndTime.current = chronologicalEnd;
          setAnchorEl({ getBoundingClientRect });
          toggleManualUserEdition(true);
          setTimeout(() => {
            inputRef.current.focus();
          }, 0);
        }
      }
    }
  };

  let sentencePadding = '0 0 12px';
  const isAnnotation = !!sentence.user;
  if (isAnnotation && displayTranslatedSentence) {
    sentencePadding = '12px 0 0';
  }
  return (
    <Fragment>
      <Box
        key={index}
        sx={{
          padding: sentencePadding,
          position: 'relative',
          minHeight: displayTranslatedSentence ? translationHeight : 'auto',
          pr: 2,
        }}
        onPointerUp={!_.isEmpty(sentence.words) ? handleSelectMenu : _.noop}
      >
        <SentenceCheckboxContainer sentenceStart={sentence.start} />
        <Box
          component="span"
          sx={{
            display: 'inline-flex',
            alignItems: 'baseline',
            userSelect: 'none',
            opacity: wasRead || isAnnotation ? 1 : 0.5,
            zIndex: 1,
            position: 'relative',
          }}
          ref={cartouchRef}
        >
          <Divider sx={{ mx: 1, py: '3px' }} orientation="vertical" flexItem />
          {!isAnnotation && (
            <Fragment>
              <Box
                component="span"
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                  opacity: wasRead ? 1 : 0.5,
                  minWidth: '45px',
                  textAlign: 'center',
                }}
                onClick={() => handleTranscriptClick(sentence.start)}
              >
                { displayDatetime
                  ? sentence.startDate
                  : formatTime(sentence.start * 1000, displayHours) }
              </Box>
              <Divider sx={{ mx: 1, py: '3px' }} orientation="vertical" flexItem />
            </Fragment>
          )}
          <Box
            component="span"
            sx={{
              fontWeight: '500',
              color: speakers[sentence.spkid],
              display: 'inline-flex',
              alignItems: 'center',
            }}
            onClick={isAnnotation ? _.noop : clicks}
          >
            {sentence.spkid || (
              <Chip label={(
                <Fragment>
                  {`${sentence.utilisateurNom} ${sentence.utilisateurPrenom}`}
                  <SmsOutlined sx={{ verticalAlign: 'middle', marginLeft: '4px', fontSize: '18px' }} />
                </Fragment>
              )}
              />
            )}
            {sentence.spkid && (
              <Tooltip title={t('ged.edit_speaker_here')}>
                <IconButton
                  size="extraSmall"
                  sx={{
                    height: '20px',
                    width: '20px',
                    '& svg': {
                      fontSize: '16px',
                    },
                    ml: '5px',
                  }}
                  onClick={handleOpenPatchSpeakerDialogLocal}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          &nbsp;
          {isAnnotation ? ':' : '>' }
          &nbsp;
        </Box>
        {_.isEmpty(sentence.words) ? (
          <Box
            component="span"
            sx={{
              opacity: wasRead || isAnnotation ? 1 : 0.5,
              fontWeight: isAnnotation ? '600' : 'normal',
              lineHeight: '30px',
              '&::first-letter': { textTransform: 'uppercase' },
            }}
          >
            {isAnnotation ? sentence.comment : sentence.sentence}
            {isAnnotation && sentence.user === activeUserId && (
              <Fragment>
                <Tooltip title={t('ged.edit_transcription_annotation')}>
                  <IconButton
                    size="extraSmall"
                    sx={{
                      height: '20px',
                      width: '20px',
                      '& svg': {
                        fontSize: '16px',
                      },
                      ml: '5px',
                    }}
                    onClick={() => handleOpenEditAnnotationDialog(sentence.start, sentence.comment)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('ged.delete_transcription_annotation')}>
                  <IconButton
                    size="extraSmall"
                    sx={{
                      height: '20px',
                      width: '20px',
                      '& svg': {
                        fontSize: '16px',
                      },
                      ml: '5px',
                    }}
                    onClick={() => handleOpenConfirmDeleteAnnotationDialog(sentence.start, sentence.comment)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Fragment>
            )}
          </Box>
        ) : (
          <Fragment>
            <Box
              component="span"
              sx={{
                lineHeight: displayTranslatedSentence && sentence.translation ? '60px' : '30px',
                zIndex: 1,
                position: 'relative',
              }}
            >
              {_.map(
                sentence.words,
                (word) => (
                  <TranscriptionWord
                    wasRead={(wasRead && !currentMediaTime) || currentMediaTime > word.start}
                    highlight={selectedWordsStarts.current.includes(word.start)}
                    handleTranscriptClick={handleTranscriptClick}
                    key={`${String(word.start)}_${word.word}`}
                    preventClick={!!selectedText}
                    word={word}
                  />
                ),
              )}
            </Box>
            {displayTranslatedSentence && sentence.translation && (
              <Box
                sx={{
                  fontStyle: 'italic',
                  userSelect: 'none',
                  opacity: cartouchSize ? 0.4 : 0,
                  lineHeight: '60px',
                  position: 'absolute',
                  display: 'block',
                  top: '30px',
                  zIndex: 0,
                }}
                ref={translationRef}
              >
                <Box sx={{ width: cartouchSize + 24, display: 'inline-block' }}>&nbsp;</Box>
                {sentence.translation}
              </Box>
            )}
          </Fragment>
        )}
      </Box>
      <ClickAwayListener onClickAway={handleCloseEditionPopper} mouseEvent="onMouseDown">
        <Popper
          anchorEl={anchorEl}
          keepMounted={false}
          open={!_.isEmpty(selectedText) && !_.isEmpty(anchorEl)}
          sx={{ zIndex: 1800, paddingTop: '3px' }}
        >
          <Box
            component="form"
            onSubmit={handleEditTranscription}
            sx={{
              alignItems: 'center',
              backgroundColor: theme.palette.mode === 'light' ? '#dddddd' : '#333333',
              borderRadius: '8px',
              display: 'flex',
              p: 1,
            }}
          >
            <TextField
              defaultValue={selectedText}
              inputRef={inputRef}
              maxRows="3"
              minRows="1"
              multiline
              onFocus={(event) => event.target.select()}
              onKeyDown={(event) => handleEditTranscription(event, true)}
              sx={{ width: '250px', mr: 1 }}
              variant="standard"
            />
            <Button type="submit">
              <Save />
            </Button>
          </Box>
        </Popper>
      </ClickAwayListener>
    </Fragment>
  );
};

TranscriptionSentence.propTypes = {
  activeUserId: PropTypes.number.isRequired,
  currentMediaTime: PropTypes.number,
  displayTranslatedSentence: PropTypes.bool,
  displayHours: PropTypes.bool,
  handleTranscriptClick: PropTypes.func.isRequired,
  handleOpenConfirmDeleteAnnotationDialog: PropTypes.func.isRequired,
  handleOpenEditAnnotationDialog: PropTypes.func.isRequired,
  handleOpenPatchSpeakerDialog: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  patchDocumentInComplete: PropTypes.func.isRequired,
  sentence: transcriptionSentencePropType.isRequired,
  speakers: PropTypes.shape(/* speakerId: speakerColor  */).isRequired,
  toggleManualUserEdition: PropTypes.func.isRequired,
  wasRead: PropTypes.bool,
  displayDatetime: PropTypes.bool,
};

export default React.memo(TranscriptionSentence);
