import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Box, Button,
  Divider,
  FormControl, FormControlLabel,
  InputLabel,
  MenuItem,
  Stack,
  Tab, Tabs,
} from '@mui/material';
import {
  FastField,
  Field,
  Form,
  Formik,
} from 'formik';
import {
  CheckboxWithLabel, Select, Switch, TextField,
} from 'formik-mui';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

import AutocompleteAjax from 'generic/components/forms/AutocompleteAjax';
import FileUpload from 'generic/components/forms/FileUpload';
import NewsletterHisto from 'generic/components/ui/NewsletterHisto';
import NewsletterDocumentsContainer from 'generic/containers/NewsletterDocumentsContainer';
import NewsletterPreviewAndSendContainer from 'generic/containers/NewsletterPreviewAndSendContainer';
import PageBackAndTitle from 'generic/components/ui/PageBackAndTitle';
import TabPanel from 'generic/components/ui/TabPanel';

import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import { getWeekdays } from 'generic/utils/dateUtils';
import { doAutocompleteUser, doAutocompleteEmail } from 'generic/api/users';
import { cartOrNewsletterPropType, newsletterThemePropType } from 'generic/core/qes/proptypes';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { CONSTANTS } from 'generic/core/constants';
import useAuthorizedExtensions from 'generic/core/hooks/useAuthorizedExtensions';

const { CK_EDITOR_CONFIG } = CONSTANTS;
const { API_ENTRY_POINT, NEWSLETTER_VISIBILITY, FILE_UPLOAD_FORMATS } = QES_CONSTANTS;

const Newsletter = ({
  handleClearUploadField,
  handleResetUploadField,
  onSave,
  fileUploading = false,
  fileUploadName = '',
  newsletter = {},
  saveNewsletterLoading = false,
  uploadActions = [],
  themeOptions = [],
}) => {
  const { t } = useTranslation();

  const visibilityOptions = Object.values(NEWSLETTER_VISIBILITY).map(
    (id) => ({ code: id, libelle: t(`newsletters.visibility_values.${id}`) }),
  );

  const initialValues = {
    panier: newsletter.panier,
    intitule: newsletter.intitule,
    visibilite_panier: newsletter.visibilite_panier,
    image: newsletter.image,
    texte: newsletter.texte,
    panierUtilisateurs: newsletter.panierUtilisateurs,
    jours: newsletter.jours,
    heures: newsletter.heures,
    destinataires: newsletter.destinataires,
    theme_panier: newsletter.theme_panier,
    mode_manuel: newsletter.mode_manuel,
  };

  // Pour differencier les homonymes dans la fenetre contextuelle de selection
  const handleRenderOption = (props, option) => {
    const {
      email,
      nom,
      prenom,
      utilisateur,
    } = option;
    const prenomStr = (prenom) ? `${prenom} ` : '';
    const emailStr = (email && email !== nom) ? ` (${email})` : '';

    return (
      <li {...props} key={utilisateur}>
        <div>
          {`${prenomStr}${nom}${emailStr}`}
        </div>
      </li>
    );
  };

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();
  const authorizedExtensions = useAuthorizedExtensions().getFileUpload(FILE_UPLOAD_FORMATS.newsletter);

  let imgSrc = '';
  if (fileUploadName) {
    imgSrc = `${API_ENTRY_POINT}/file?type=0&file=${fileUploadName}&logon=${logon}&key=${key}`;
  }

  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTab = useCallback(
    (__, tabIndex) => { setActiveTab(tabIndex); },
    [setActiveTab],
  );

  return (
    <Box
      className="desktopOnlyOverflow"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        className="stickyHeader"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          zIndex: '1200',
        }}
      >
        <PageBackAndTitle
          backLink="/newsletters"
          backBtnTitle={t('newsletters.back_to_list')}
          title={newsletter.intitule}
          showImg
          imgSrc={imgSrc}
        />
        <Tabs
          allowScrollButtonsMobile
          onChange={handleActiveTab}
          scrollButtons="auto"
          sx={{ px: 1 }}
          value={activeTab}
          variant="scrollable"
        >
          <Tab label={t('newsletters.edit.details')} />
          <Tab label={t('newsletters.edit.documents')} />
          <Tab label={t('newsletters.edit.visualize')} />
          <Tab label={t('newsletters.edit.historic')} />
        </Tabs>
      </Box>

      <Box sx={{ display: 'flex', flexGrow: '1' }} className="desktopOnlyOverflow">

        <TabPanel
          value={activeTab}
          index={0}
          className={clsx('desktopOnlyOverflow', 'padding0')}
          sx={{
            display: activeTab === 0 ? 'flex' : 'none',
            flexDirection: 'column',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              intitule: Yup.string().required(t('form.errors.mandatory')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              onSave(values);
              setSubmitting(false);
            }}
            onReset={handleResetUploadField}
            enableReinitialize
            validateOnMount
          >
            {(form) => (
              <Form
                className={
                  clsx(
                    'displayFlex',
                    'flexGrow1',
                    'flexDirectionColumn',
                    'desktopOnlyOverflow',
                  )
                }
              >
                <Box
                  className="desktopOnlyOverflow"
                  sx={{
                    flexGrow: '1',
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '8px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexGrow: '1',
                      }}
                    >
                      <Field
                        sx={{ mr: 2, flexGrow: 1 }}
                        component={TextField}
                        label={t('newsletters.name')}
                        autoFocus
                        name="intitule"
                        fullWidth
                        color="primary"
                        required
                      />

                      <Field
                        sx={{ mr: 2, flexGrow: 1 }}
                        component={Select}
                        label={t('newsletters.themes')}
                        name="theme_panier"
                        // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                        // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                        // override donc juste le onClose pour qu'il ne fasse rien.
                        onClose={_.noop}
                      >
                        {_.map(themeOptions, (option) => (
                          <MenuItem key={option.theme_panier} value={option.theme_panier}>{option.libelle}</MenuItem>
                        ))}
                      </Field>
                    </Box>

                    <FormControlLabel
                      sx={{ flexShrink: 0 }}
                      control={(
                        <Field
                          component={Switch}
                          name="mode_manuel"
                          type="checkbox"
                        />
                      )}
                      label={t('newsletters.manual_mode')}
                    />
                  </Box>

                  <Box
                    sx={{
                      mt: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: '1',
                        flexBasis: '210px',
                      }}
                    >
                      <Field
                        component={Select}
                        label={t('newsletters.visibility')}
                        name="visibilite_panier"
                        // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                        // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                        // override donc juste le onClose pour qu'il ne fasse rien.
                        onClose={_.noop}
                      >
                        {_.map(visibilityOptions, (option) => (
                          <MenuItem key={option.code} value={option.code}>{option.libelle}</MenuItem>
                        ))}
                      </Field>
                    </Box>
                    {form.values.visibilite_panier === NEWSLETTER_VISIBILITY.collaborative && (
                      <Box sx={{ width: 'auto', flexGrow: '1000', flexBasis: '350px' }}>
                        <FastField
                          component={AutocompleteAjax}
                          name="panierUtilisateurs"
                          facet=""
                          multiple
                          highlight={false}
                          doAutocompleteOverride={doAutocompleteUser}
                          getOptionLabel={(userObjectOrEmail) => {
                            if (typeof userObjectOrEmail === 'string') return userObjectOrEmail;
                            const nom = _.get(userObjectOrEmail, 'nom', '');
                            const prenom = _.get(userObjectOrEmail, 'prenom', '');
                            return _.compact([prenom, nom]).join(' ');
                          }}
                          renderOption={handleRenderOption}
                          isOptionEqualToValue={(option, value) => option.utilisateur === value.utilisateur}
                          fullWidth
                          textFieldProps={{
                            label: t('newsletters.contributors'),
                          }}
                        />
                      </Box>
                    )}
                  </Box>

                  <Box
                    sx={{ mt: 1 }}
                  >
                    <Field
                      component={FileUpload}
                      name="image"
                      fileUploadName={fileUploadName}
                      label={t('newsletters.image')}
                      uploadParams={{ type: FILE_UPLOAD_FORMATS.newsletter }}
                      accept={authorizedExtensions}
                      fileUploading={fileUploading}
                      uploadActions={uploadActions}
                      handleClearField={handleClearUploadField}
                      withThumb={false}
                    />
                  </Box>

                  <Box sx={{ width: '100%', mt: 1 }}>
                    <FastField
                      component={AutocompleteAjax}
                      name="destinataires"
                      multiple
                      freeSolo
                      highlight={false}
                      doAutocompleteOverride={doAutocompleteEmail}
                      renderOption={handleRenderOption}
                      getOptionLabel={
                        (userObjectOrEmail) => {
                          if (typeof userObjectOrEmail === 'string') return userObjectOrEmail;
                          return _.get(userObjectOrEmail, 'email');
                        }
                      }
                      disableCloseOnSelect
                      isOptionEqualToValue={(option, value) => {
                        const compareValue = typeof value === 'string' ? value : value?.email;
                        return option.email === compareValue;
                      }}
                      fullWidth
                      textFieldProps={{
                        label: t('newsletters.recipients_other'),
                      }}
                    />
                  </Box>

                  <Box
                    sx={{ mt: 1 }}
                  >
                    <FormControl
                      component="fieldset"
                      className="formFieldset"
                      fullWidth
                    >
                      <InputLabel>
                        {t('newsletters.scheduled_sending_days')}
                      </InputLabel>
                      <Box>
                        { getWeekdays('abbreviated').map((day, i) => (
                          <FastField
                            component={CheckboxWithLabel}
                            name="jours"
                            key={`jour_${day}`}
                            value={`${i + 1}`}
                            Label={{ label: day }}
                            type="checkbox"
                            color="primary"
                          />
                        )) }
                      </Box>
                    </FormControl>
                  </Box>

                  <Box
                    sx={{ mt: 1 }}
                  >
                    <FormControl
                      component="fieldset"
                      className="formFieldset"
                      fullWidth
                    >
                      <InputLabel>
                        {t('newsletters.scheduled_sending_hours')}
                      </InputLabel>
                      <Box sx={{ p: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                          <div>
                            {
                              _.range(0, 12).map((hour, i) => (
                                <FastField
                                  component={CheckboxWithLabel}
                                  name="heures"
                                  key={`heures_${hour}`}
                                  value={`${i}`}
                                  Label={{ label: `${String(hour).padStart(2, '0')}h` }}
                                  type="checkbox"
                                  color="primary"
                                />
                              ))
                            }
                          </div>
                        </Box>

                        <Divider />

                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                          <div>
                            {
                              _.range(12, 24).map((hour, i) => (
                                <FastField
                                  component={CheckboxWithLabel}
                                  name="heures"
                                  key={`heures_${hour}`}
                                  value={`${i + 12}`}
                                  Label={{ label: `${String(hour).padStart(2, '0')}h` }}
                                  type="checkbox"
                                  color="primary"
                                />
                              ))
                            }
                          </div>
                        </Box>
                      </Box>
                    </FormControl>
                  </Box>

                  <Box
                    sx={{ mt: 1 }}
                  >
                    <FormControl
                      fullWidth
                    >
                      <InputLabel>
                        {t('newsletters.introduction')}
                      </InputLabel>
                      <CKEditor
                        editor={ClassicEditor}
                        name="texte"
                        data={form.values.texte}
                        config={CK_EDITOR_CONFIG}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          form.setFieldValue('texte', data);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  className="bottomBtnsContainer"
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ justifyContent: 'center' }}
                  >
                    <Button
                      color="primary"
                      variant="text"
                      type="reset"
                      onClick={form.resetForm}
                      disabled={saveNewsletterLoading || fileUploading || !form.dirty}
                    >
                      {t('form.reset')}
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={saveNewsletterLoading || fileUploading || !form.isValid || !form.dirty}
                    >
                      {t('form.save')}
                    </Button>
                  </Stack>
                </Box>

              </Form>
            )}
          </Formik>
        </TabPanel>

        <TabPanel
          value={activeTab}
          index={1}
          className={clsx('desktopOnlyOverflow', 'padding0')}
          sx={{
            display: activeTab === 1 ? 'flex' : 'none',
            flexDirection: 'column',
            zIndex: 1300,
          }}
        >
          <NewsletterDocumentsContainer newsletter={newsletter} />
        </TabPanel>

        <TabPanel
          value={activeTab}
          index={2}
          className={clsx('desktopOnlyOverflow', 'padding0')}
          sx={{
            display: activeTab === 2 ? 'flex' : 'none',
            flexDirection: 'column',
          }}
        >
          <NewsletterPreviewAndSendContainer
            variant="newsletter"
            id={newsletter.panier}
          />
        </TabPanel>

        <TabPanel
          value={activeTab}
          index={3}
          className={clsx('desktopOnlyOverflow', 'padding0')}
          sx={{
            display: activeTab === 3 ? 'flex' : 'none',
            flexDirection: 'column',
            zIndex: 1300,
          }}
        >
          <NewsletterHisto
            historic={newsletter.newsletterHisto}
          />
        </TabPanel>

      </Box>
    </Box>
  );
};

Newsletter.propTypes = {
  fileUploading: PropTypes.bool,
  fileUploadName: PropTypes.string,
  handleClearUploadField: PropTypes.func.isRequired,
  handleResetUploadField: PropTypes.func.isRequired,
  newsletter: cartOrNewsletterPropType,
  onSave: PropTypes.func.isRequired,
  saveNewsletterLoading: PropTypes.bool,
  themeOptions: PropTypes.arrayOf(newsletterThemePropType),
  uploadActions: PropTypes.shape({
    loading: PropTypes.shape({ type: PropTypes.string.isRequired }),
    success: PropTypes.shape({ type: PropTypes.string.isRequired }),
    error: PropTypes.shape({ type: PropTypes.string.isRequired }),
  }),
};

export default Newsletter;
