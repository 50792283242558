import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Fab, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CenteredMessage from 'generic/components/ui/CenteredMessage';
import ArticleViewBlock from 'generic/components/pages/anr/ArticleViewBlock';
import { articlePropType } from 'generic/core/anr/proptypes';
import { ContentCopyOutlined, OpenInNew } from '@mui/icons-material';
import { snackActions } from 'generic/utils/snackbar';

const ArticleViewDisplay = ({ article = {}, loading, handleIsLoaded }) => {
  const { t } = useTranslation();

  const handleCopyShareUrl = () => {
    navigator.clipboard.writeText(article.url);
    snackActions.info(t('anr.actions.link_copied'));
  };

  const handleOpenInNewTab = () => {
    window.open(`${article.anrUrl}&window=true`);
  };

  const fabProps = {
    size: 'extraSmall',
    color: 'secondary',
    sx: {
      boxShadow: 'none',
      '&:active': { boxShadow: 'none' },
      ml: '5px',
      mr: '10px',
    },
  };

  const showIframe = !article.ext_opening && !article.is_from_dark_web;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        borderTop: '1px solid',
        borderColor: 'secondary.main',
        overflow: article.ext_opening ? 'auto' : 'hidden',
      }}
    >
      <ArticleViewBlock article={article} />
      {article.iframeUrl ? (
        // Une URL pour l'iframe est présente
        <Fragment>
          {showIframe && (
            // L'article peut être ouvert dans une iframe: on créée l'iframe
            <Box
              component="iframe"
              src={article.iframeUrl}
              title="article view"
              sx={{
                border: 'none',
                width: '100%',
                height: '100%',
                opacity: loading ? '0' : '1',
              }}
              onLoad={handleIsLoaded}
            />
          )}
          {!showIframe && (
            // Si l'article ne peut pas être ouvert dans l'iframe, on affiche les messages d'information
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: '1',
                  m: 3,
                }}
              >
                <Typography>
                  {article.is_from_dark_web ? (
                    <Trans>{t('anr.cant_open_dark_web_article_message')}</Trans>
                  ) : (
                    t('anr.cant_open_article_message')
                  )}
                  <Fab {...fabProps} onClick={handleOpenInNewTab}>
                    <OpenInNew />
                  </Fab>
                </Typography>
                {article.is_from_dark_web && (
                  <Typography>
                    <Fragment>
                      {t('anr.or_copy_link_message')}
                      <Fab {...fabProps} onClick={handleCopyShareUrl}>
                        <ContentCopyOutlined />
                      </Fab>
                    </Fragment>
                  </Typography>
                )}
                <Typography sx={{ mt: 2, fontStyle: 'italic' }}>
                  <Trans>{t('anr.cant_open_article_tip')}</Trans>
                </Typography>
              </Box>
            </Box>
          )}
        </Fragment>
      ) : (
        // Aucune URL présente, on est dans le cas d'une citation sans URL partagée,
        // on affiche un message pour informer l'utilisateur
        <CenteredMessage>{t('anr.no_url_for_quote')}</CenteredMessage>
      )}
    </Box>
  );
};

ArticleViewDisplay.propTypes = {
  article: articlePropType,
  handleIsLoaded: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ArticleViewDisplay;
