import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';

const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
  setUseSnackbarRef(useSnackbar());
  return null;
};
InnerSnackbarUtilsConfigurator.propTypes = {
  setUseSnackbarRef: PropTypes.func.isRequired,
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => (
  <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
);

const toast = (msg, options = { variant: 'default' }) => useSnackbarRef.enqueueSnackbar(msg, options);

/**
 * Liste des options dans la doc notistack
 * https://notistack.com/api-reference#mutual-props
 */
export const snackActions = {
  success(msg, options) {
    toast(msg, { variant: 'success', ...options });
  },
  warning(msg, options) {
    toast(msg, { variant: 'warning', ...options });
  },
  info(msg, options) {
    toast(msg, { variant: 'info', ...options });
  },
  error(msg, options) {
    toast(msg, { variant: 'error', ...options });
  },
  toast,
};
