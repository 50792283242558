import React from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  TextField,
} from 'formik-mui';
import {
  Box,
} from '@mui/material';
import {
  Search,
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { isHeaderBackgroundColorDark } from 'generic/utils/colorUtils';

/** @type {import('@mui/material').SxProps<import('@mui/material').Theme>} */
const searchSx = (theme) => ({
  borderRadius: '18px',
  position: 'relative',
  backgroundColor: isHeaderBackgroundColorDark(theme)
    ? alpha(theme.palette.common.white, 0.3)
    : alpha(theme.palette.common.black, 0.1),
  '&:hover': {
    backgroundColor: isHeaderBackgroundColorDark(theme)
      ? alpha(theme.palette.common.white, 0.4)
      : alpha(theme.palette.common.black, 0.2),
  },
  marginRight: '16px',
  marginLeft: 0,
  width: '100%',
  color: isHeaderBackgroundColorDark(theme) ? theme.palette.common.white : theme.palette.common.black,
  [theme.breakpoints.up('sm')]: {
    marginLeft: '24px',
    width: 'auto',
  },
});

function FormSearchSimple({
  onSubmit,
  initialValues = {},
  fieldCode,
  fieldPlaceholder,
  fieldIcon = null,
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {() => (
        <Box sx={searchSx}>
          <Box sx={{
            padding: '0 8px',
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            {fieldIcon || <Search />}
          </Box>

          <Form>
            <Field
              sx={{ m: 0 }}
              variant="standard"
              component={TextField}
              fullWidth
              label=""
              name={fieldCode}
              autoFocus
              placeholder={fieldPlaceholder}
              InputProps={{
                sx: (theme) => ({
                  '&.MuiInput-root': {
                    '&::before, &:hover': {
                      border: 'none',
                    },
                    color: isHeaderBackgroundColorDark(theme) ? theme.palette.common.white : theme.palette.common.black,
                  },
                  '& .MuiInput-input': {
                    padding: '9px 8px 8px 40px',
                    transition: theme.transitions.create('width'),
                    [theme.breakpoints.up('md')]: {
                      width: '60ch',
                    },
                  },
                }),
                disableUnderline: true,
              }}
            />
          </Form>
        </Box>
      )}
    </Formik>
  );
}

FormSearchSimple.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
  fieldCode: PropTypes.string.isRequired,
  fieldPlaceholder: PropTypes.string.isRequired,
  fieldIcon: PropTypes.node,
};

export default FormSearchSimple;
