import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Virtuoso } from 'react-virtuoso';

import { checkSentences, clearCheckedSentences } from 'generic/core/ux/actions';
import { openEditTranscriptionAnnotationDialog, openPatchSpeakerDialog, types } from 'generic/core/ged/actions';
import { fetchResults } from 'generic/core/search/actions';
import ImageDetection from 'generic/components/ui/ImageDetection';
import TranscriptionSentence from 'generic/components/ui/TranscriptionSentence';
import Waveform from 'generic/components/ui/WaveSurfer';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { formatTime } from 'generic/utils/dateUtils';
import { transcriptionSentencePropType } from 'generic/core/qes/proptypes';

const MediaWithTranscription = ({
  activeBaseId,
  activeUserId,
  mediaType,
  mediaUrl,
  faces = [],
  frames = [],
  patchDocumentInComplete: originalPatchDocumentInComplete,
  documentId,
  displayTranslatedSentences = false,
  transcription = [],
  hasDisplayDateTime = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mediaRef = useRef(null);
  const virtuoso = useRef(null);
  const manualUserScroll = useRef(false);
  const manualUserEdition = useRef(false);
  const mediaWasPlayingBeforeEdition = useRef(false);
  const [currentMediaTime, setCurrentMediaTime] = useState(0);
  const [shownSpeakers, setShownSpeakers] = useState(_.keys(transcription.speakers));

  const [displayDatetime, setDisplayDatetime] = useState(hasDisplayDateTime && true);
  const toggleDisplayDatetime = useCallback(() => { setDisplayDatetime((prev) => !prev); }, []);

  const displayedSentences = useMemo(() => {
    if (!_.isArray(transcription.sentences)) return [];

    const displayedSentencesFinal = [];
    let countHidden = 0;
    _.forEach(
      transcription.sentences,
      (sentence) => {
        if (shownSpeakers.includes(sentence.spkid) || sentence.user) {
          displayedSentencesFinal.push(sentence);
          countHidden = 0;
        } else {
          if (countHidden === 0) {
            displayedSentencesFinal.push({ ellipsis: true });
          }
          countHidden++;
          _.last(displayedSentencesFinal).countHidden = countHidden;
        }
      },
    );
    return displayedSentencesFinal;
  }, [transcription.sentences, shownSpeakers]);

  const sentencesCheckedDisplayed = useSelector((state) => state.ux.checkedSentences.map((s) => ({ start: s })));
  const atLeastOneChecked = !_.isEmpty(sentencesCheckedDisplayed);
  const isCheckallChecked = atLeastOneChecked && _.differenceBy(
    displayedSentences.filter((s) => !('ellipsis' in s)),
    sentencesCheckedDisplayed,
    'start',
  ).length === 0;
  const isCheckallIndeterminate = !isCheckallChecked && atLeastOneChecked;

  const handleToggleCheckboxAll = useCallback(() => {
    if (isCheckallChecked) {
      dispatch(clearCheckedSentences());
    } else {
      const toCheck = displayedSentences.filter((s) => !s.ellipsis).map((s) => s.start);
      dispatch(checkSentences(toCheck));
    }
  }, [dispatch, displayedSentences, isCheckallChecked]);

  const waveRegions = useMemo(() => {
    if (!_.isArray(transcription.sentences)) return [];

    const waveRegionsFinal = [];
    _.each(transcription.sentences, (sentence) => {
      if (sentence.user) {
        waveRegionsFinal.push({
          start: sentence.start,
          color: theme.palette.mode === 'light' ? '#000000' : '#ffffff',
          content: sentence.comment,
        });
      } else if (shownSpeakers.includes(sentence.spkid)) {
        waveRegionsFinal.push({
          start: sentence.start,
          end: sentence.end,
          color: `${transcription.speakers[sentence.spkid]}50`,
        });
      }
    });
    return waveRegionsFinal;
  }, [transcription, shownSpeakers, theme.palette.mode]);

  useEffect(() => {
    let shownSpeakersFinal = [...shownSpeakers];
    // If any shown speaker has been renamed
    if (_.difference(shownSpeakers, _.keys(transcription.speakers)).length !== 0) {
      shownSpeakersFinal = _.keys(transcription.speakers);
      setShownSpeakers(shownSpeakersFinal);
    }
    // Filter of displayed sentences depending on shown speakers
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcription]);

  const wasUpdated = useRef(false);
  const avecEnrichADemanderALUpdate = useSelector((state) => state.config.activeBase.avecEnrichADemanderALUpdate);

  const patchDocumentInComplete = useCallback((data) => {
    wasUpdated.current = true;
    originalPatchDocumentInComplete(data);
  }, [originalPatchDocumentInComplete]);

  const onExit = () => {
    if (avecEnrichADemanderALUpdate && wasUpdated.current) {
      originalPatchDocumentInComplete({ enrich: true });
      wasUpdated.current = false;
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onExit); // libère le document courant si on ferme l'onglet

    return () => {
      onExit(); // libère le document courant si on passe au doc suivant.
      window.removeEventListener('beforeunload', onExit);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleFilterOnSpeaker = (event) => {
    const {
      target: { value, checked },
    } = event;
    const shownSpeakersFinal = [...shownSpeakers];
    if (!checked) {
      _.pull(shownSpeakersFinal, value);
    } else {
      shownSpeakersFinal.push(value);
    }
    setShownSpeakers(shownSpeakersFinal);
  };

  let manualScrollTimeout = null;
  const handleManualScroll = () => {
    clearTimeout(manualScrollTimeout);
    manualUserScroll.current = true;
    manualScrollTimeout = setTimeout(() => {
      manualUserScroll.current = false;
    }, 3000);
  };

  const handlePlayOrPauseMediaWithSpace = (event) => {
    const { activeElement } = document;
    const isInputField = activeElement.tagName === 'INPUT'
      || activeElement.tagName === 'TEXTAREA'
      || activeElement.getAttribute('contenteditable') === 'true';

    if (!isInputField && mediaRef.current && !manualUserEdition.current && event.key === ' ') {
      if (!mediaRef.current.paused) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (mediaRef.current) {
      document.getElementById('transcription').addEventListener('wheel', handleManualScroll);
      document.addEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    }
    return () => {
      dispatch(clearCheckedSentences());
      if (document.getElementById('transcription')) {
        document.getElementById('transcription').removeEventListener('wheel', handleManualScroll);
      }
      document.removeEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaRef]);

  const handleTranscriptClick = useCallback((time) => {
    if (mediaRef.current) {
      manualUserScroll.current = false;
      manualUserEdition.current = false;
      mediaRef.current.currentTime = time;
      if (!mediaRef.current.paused) {
        mediaRef.current.play();
      }
    }
  }, [mediaRef]);

  const handleTimeUpdate = () => {
    if (mediaRef.current && !manualUserScroll.current && !manualUserEdition.current) {
      const { currentTime } = mediaRef.current;
      setCurrentMediaTime(currentTime);

      // Trouver le texte associé au moment actuel
      const activeItemIndex = displayedSentences.findIndex((item, index) => {
        const nextItem = displayedSentences[index + 1];
        if (!item.start) {
          return false;
        }
        return currentTime >= parseFloat(item.start) && (!nextItem || currentTime < parseFloat(nextItem.start));
      });

      // Faire défiler vers le texte actif
      if (activeItemIndex !== -1) {
        virtuoso.current.scrollToIndex({
          index: activeItemIndex,
          offset: -100,
          behavior: 'instant',
        });
      }
    }
  };

  const toggleManualUserEdition = useCallback((toggle) => {
    manualUserEdition.current = toggle;
    if (toggle && !mediaRef.current.paused) {
      mediaRef.current.pause();
      mediaWasPlayingBeforeEdition.current = true;
    } else if (!toggle) {
      if (mediaWasPlayingBeforeEdition.current) {
        mediaRef.current.play();
      }
      mediaWasPlayingBeforeEdition.current = false;
    }
  }, [mediaRef]);

  const handleImageClick = (time) => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = time;
      mediaRef.current.play();
    }
  };

  const handleOpenPatchSpeakerDialog = useCallback((speakerId, sentenceStart) => {
    mediaRef.current.pause();
    dispatch(openPatchSpeakerDialog(
      speakerId,
      sentenceStart,
      _.without(_.keys(transcription.speakers), speakerId),
    ));
  }, [dispatch, transcription.speakers]);

  const handleOpenEditAnnotationDialog = useCallback((start, annotation) => {
    mediaRef.current.pause();
    dispatch(openEditTranscriptionAnnotationDialog(
      start,
      annotation,
    ));
  }, [dispatch]);

  const handleOpenConfirmDeleteAnnotationDialog = useCallback((start, annotation) => {
    mediaRef.current.pause();
    dispatch(setConfirmDialogData({
      title: t('ged.delete_transcription_annotation'),
      textButton: 'form.delete',
      message: (
        <Trans>
          {t('ged.delete_transcription_annotation_sure', { annotation })}
        </Trans>
      ),
      submitColor: 'error',
      waitForActions: [types.SAVE_DOCUMENT_SUCCESS, types.SAVE_DOCUMENT_ERROR],
      action: {
        type: types.SAVE_DOCUMENT,
        params: {
          article: documentId,
          base: activeBaseId,
          patchInComplete: true,
          transcription_comments_detection: 'Text_comments_json',
          transcription_comment_start: start,
          transcription_comment: '',
        },
      },
    }));
  }, [activeBaseId, dispatch, documentId, t]);

  const handleSimilarityImage = (idChamp) => (itemVector) => {
    const nameChamp = `F_${idChamp}`;
    dispatch(fetchResults({
      bodyItems: {
        premier: 1,
        dernier: 20,
        base: 22801201,
        champs: {
          [nameChamp]: itemVector,
        },
      },
      refreshForm: true,
      clearSelection: true,
      clearResults: true,
    }));
  };

  return (
    <Fragment>
      {/* LECTEUR AUDIO */}
      {mediaType === 'audio' && (
        <Box sx={{ mr: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            <CardMedia
              sx={{
                maxWidth: '500px',
                mt: 2,
                mb: 2,
                flexShrink: 0,
              }}
              component="audio"
              controls
              onTimeUpdate={handleTimeUpdate}
              src={mediaUrl}
              ref={mediaRef}
            />
            <Button
              onClick={() => handleOpenEditAnnotationDialog(currentMediaTime)}
            >
              {t('ged.add_transcription_annotation_at', { start: formatTime(currentMediaTime * 1000) })}
            </Button>
          </Box>
          <Waveform
            mediaRef={mediaRef}
            regions={waveRegions}
            handleOpenEditAnnotationDialog={handleOpenEditAnnotationDialog}
          />
        </Box>
      )}
      {/* LECTEUR VIDEO */}
      <Box sx={{ display: 'flex', gap: '32px' }}>
        {mediaType === 'video' && (
          <Card
            sx={{
              maxWidth: '500px',
              maxHeight: '300px',
              mt: 2,
              mb: 0,
              flexShrink: 0,
            }}
          >
            <CardMedia
              sx={{
                '&.MuiCardMedia-root': {
                  maxHeight: '300px',
                },
              }}
              component="video"
              controls
              src={mediaUrl}
              ref={mediaRef}
              onTimeUpdate={handleTimeUpdate}
            />
          </Card>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'hidden',
          }}
        >
          {/* DETECTION SCENES */}
          {!_.isEmpty(frames) && (
            <ImageDetection
              data={frames}
              title={t('document.frames')}
              handleImageClick={handleImageClick}
            />
          )}
          {mediaType === 'video' && (
            <div>
              <Button
                onClick={() => handleOpenEditAnnotationDialog(currentMediaTime)}
              >
                {t('ged.add_transcription_annotation_at', { start: formatTime(currentMediaTime * 1000) })}
              </Button>
            </div>
          )}
          {/* DETECTION IMAGE */}
          {!_.isEmpty(faces) && (
            <ImageDetection
              data={faces}
              title={t('document.faces')}
              handleImageClick={handleImageClick}
              handleSimilarityImage={handleSimilarityImage(1032000592)}
            />
          )}
        </Box>
      </Box>

      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          mt: 1,
        }}
      >
        <Tooltip fontSize="small" title={t('document.check_all_sentences')}>
          <Checkbox
            sx={{ m: 0, p: 0 }}
            checked={isCheckallChecked}
            indeterminate={isCheckallIndeterminate}
            onChange={handleToggleCheckboxAll}
          />
        </Tooltip>

        <Divider sx={{ mx: 1, py: '3px', color: 'success' }} orientation="vertical" flexItem />
        {hasDisplayDateTime && (
          <Button
            variant="outlined"
            onClick={toggleDisplayDatetime}
            sx={{ mr: 1 }}
          >
            {t(`document.show_datetime_${displayDatetime ? 'on' : 'off'}`)}
          </Button>
        )}

        <ButtonGroup
          size="small"
          variant="outlined"
        >
          <Button variant="outlined" onClick={() => setShownSpeakers(_.keys(transcription.speakers))}>
            {t('document.show_all_speakers')}
          </Button>

          <Button variant="outlined" onClick={() => setShownSpeakers([])}>
            {t('document.hide_all_speakers')}
          </Button>
        </ButtonGroup>
      </Box>

      <Box
        sx={{
          mt: 1,
          whiteSpace: 'pre-wrap',
          lineHeight: '30px',
          clear: 'both',
          overflow: 'auto',
          flexGrow: '1',
        }}
        id="transcription"
      >
        <Virtuoso
          ref={virtuoso}
          data={displayedSentences}
          style={{ height: '100%' }}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(index, sentence) => {
            let currentMediaTimeValue;
            if (
              sentence.spkid
              && currentMediaTime >= sentence.start
              && (!sentence.end || currentMediaTime < sentence.end)
            ) {
              currentMediaTimeValue = currentMediaTime;
            }
            return (
              sentence.ellipsis ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    pr: 2,
                    my: 1,
                    opacity: 0.7,
                  }}
                  key={index}
                >
                  <Divider sx={{ flexBasis: '87px', borderStyle: 'dashed' }} />
                  <Box
                    mx={2}
                  >
                    <Typography color="text.neutral" fontSize="13px">
                      {t('document.hidden_intervention', { count: sentence.countHidden })}
                    </Typography>
                  </Box>
                  <Divider className="flexGrow1" sx={{ borderStyle: 'dashed' }} />
                </Box>
              ) : (
                <TranscriptionSentence
                  activeUserId={activeUserId}
                  currentMediaTime={currentMediaTimeValue}
                  displayHours={parseFloat(_.last(transcription.sentences).start) >= 3600}
                  displayTranslatedSentence={displayTranslatedSentences}
                  handleOpenPatchSpeakerDialog={handleOpenPatchSpeakerDialog}
                  handleTranscriptClick={handleTranscriptClick}
                  index={index}
                  patchDocumentInComplete={patchDocumentInComplete}
                  sentence={sentence}
                  speakers={transcription.speakers}
                  toggleManualUserEdition={toggleManualUserEdition}
                  handleOpenEditAnnotationDialog={handleOpenEditAnnotationDialog}
                  handleOpenConfirmDeleteAnnotationDialog={handleOpenConfirmDeleteAnnotationDialog}
                  wasRead={currentMediaTime >= sentence.start}
                  displayDatetime={displayDatetime}
                />
              )
            );
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 2,
          width: 'calc(100% - 60px)',
          pl: '11px',
        }}
      >
        {_.map(
          transcription.speakers,
          (speakerColor, speakerId) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: '25px',
                mb: '4px',
              }}
              key={speakerId}
            >
              <Tooltip title={t(shownSpeakers.includes(speakerId) ? 'ged.hide_speaker' : 'ged.show_speaker')}>
                <FormControlLabel
                  value={speakerId}
                  sx={{ mr: '5px' }}
                  onClick={(event) => handleToggleFilterOnSpeaker(event)}
                  control={(
                    <Switch
                      size="small"
                      checked={shownSpeakers.includes(speakerId)}
                    />
                  )}
                  label={(
                    <Typography noWrap sx={{ color: speakerColor }}>
                      {speakerId}
                    </Typography>
                  )}
                />
              </Tooltip>
              <Tooltip title={t('ged.edit_speaker_full')}>
                <IconButton
                  size="extraSmall"
                  sx={{
                    height: '22px',
                    width: '22px',
                    '& svg': {
                      fontSize: '18px',
                    },
                  }}
                  onClick={() => handleOpenPatchSpeakerDialog(speakerId)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          ),
        )}
      </Box>
    </Fragment>
  );
};

MediaWithTranscription.propTypes = {
  activeBaseId: PropTypes.number.isRequired,
  activeUserId: PropTypes.number.isRequired,
  documentId: PropTypes.string.isRequired,
  displayTranslatedSentences: PropTypes.bool,
  faces: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      time: PropTypes.string,
      vector: PropTypes.string,
    }),
  ),
  frames: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      time: PropTypes.string,
    }),
  ),
  mediaType: PropTypes.oneOf(['audio', 'video']).isRequired,
  mediaUrl: PropTypes.string.isRequired,
  patchDocumentInComplete: PropTypes.func.isRequired,
  transcription: PropTypes.shape({
    sentences: PropTypes.arrayOf(transcriptionSentencePropType),
    speakers: PropTypes.shape(/* speakerId: speakerColor */),
  }),
  hasDisplayDateTime: PropTypes.bool,
};

export default MediaWithTranscription;
