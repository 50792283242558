import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
} from '@mui/material';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  EmailTextField, ForgottenPasswordButton, FormContainer, FormFieldsContainer, LoadingButtonWithIndicator,
} from 'generic/components/forms/FormLoginCommonComponents';

const FormResetPassword = ({
  resetPasswordLoading,
  resetPasswordError,
  toggleResetPassword,
}) => {
  const { t } = useTranslation();

  const usernameOrEmailInput = useRef(null);

  return (

    <FormContainer
      title={t('logon.forgotten_password_title')}
      desc={t('logon.forgotten_password_label')}
    >
      <FormFieldsContainer>
        <React.Fragment>
          <Field
            autoFocus
            component={EmailTextField}
            name="emailOrLogin"
            label={t('logon.login')}
            placeholder={t('logon.login_placeholder')}
            innerRef={usernameOrEmailInput}
            required
            type="text"
          />

          {resetPasswordError && (
          <Typography color="error" variant="subtitle2">
            {t('logon.forgotten_password_error')}
          </Typography>
          )}

          <ForgottenPasswordButton
            onClick={() => {
              toggleResetPassword();
              usernameOrEmailInput.current.focus();
            }}
          >
            {t('logon.forgotten_password_back')}
          </ForgottenPasswordButton>
          <LoadingButtonWithIndicator
            disabled={resetPasswordLoading}
            loading={resetPasswordLoading}
            type="submit"
          >
            {t('form.send')}
          </LoadingButtonWithIndicator>
        </React.Fragment>
      </FormFieldsContainer>
    </FormContainer>
  );
};

FormResetPassword.propTypes = {
  resetPasswordLoading: PropTypes.bool.isRequired,
  resetPasswordError: PropTypes.bool.isRequired,
  toggleResetPassword: PropTypes.func.isRequired,
};

export default FormResetPassword;
