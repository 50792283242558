import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Box, IconButton } from '@mui/material';
import { Download, PlayArrow, Search } from '@mui/icons-material';

import ImgLoader from 'generic/components/ui/ImgLoader';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import { downloadImage } from 'generic/utils/qesUtils';

/** @type {import('@mui/material').SxProps<import('@mui/material').Theme>} */
const buttonImgSx = (theme) => ({
  backgroundColor: theme.palette.common.black,
  border: '1px solid',
  borderColor: '#888888',
  color: theme.palette.common.white,
  padding: '1px',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    borderColor: 'initial',
  },
});

function ImageDetection({
  data,
  title,
  handleImageClick,
  handleSimilarityImage = null,
}) {
  return (
    <Box sx={{ width: '100%', pr: 2 }}>
      <Box>{title}</Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        overflow: 'auto',
      }}
      >
        {_.map(data, (item, index) => (
          <Box sx={{ position: 'relative' }} key={index}>
            <ImgLoader
              src={item.image}
              fallbackComponent={(
                <ImageNotFound
                  height={100}
                  width={100}
                />
              )}
              height={100}
              width={100}
              loading="lazy"
            />
            <Box sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              display: 'flex',
              gap: '2px',
              padding: '2px',
            }}
            >
              <IconButton
                sx={buttonImgSx}
                disabled={!item.time}
                onClick={() => handleImageClick(item.time)}
              >
                <PlayArrow fontSize="small" />
              </IconButton>
              {handleSimilarityImage && (
                <IconButton
                  sx={buttonImgSx}
                  disabled={!item.vector}
                  onClick={() => handleSimilarityImage(item.vector)}
                >
                  <Search fontSize="small" />
                </IconButton>
              )}
              <IconButton
                sx={buttonImgSx}
                onClick={() => downloadImage(item.image, item.image.split('file=').pop(), true)}
              >
                <Download fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

ImageDetection.propTypes = {
  handleImageClick: PropTypes.func.isRequired,
  handleSimilarityImage: PropTypes.func,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      time: PropTypes.string,
      vector: PropTypes.string,
    }),
  ).isRequired,
};

export default ImageDetection;
