import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  CircularProgress,
  TextField,
} from '@mui/material';
import { Autocomplete } from 'formik-mui';
import { matchSorter } from 'match-sorter';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { doAutocomplete } from 'generic/api/search';

const wordOperators = [
  'et',
  'ou',
  'sauf',
  'and',
  'or',
  'not',
];
const limitOperators = ['(', ')', '"'];
const operators = _.concat(wordOperators, limitOperators);

const handleRenderOption = (props, option, inputValue) => {
  const matches = match(option, inputValue, { insideWords: true });
  const parts = parse(option, matches);

  const highlightStyle = {
    fontWeight: 'bold',
  };

  return (
    <li {...props}>
      <div>
        {parts.map((part, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} style={part.highlight ? highlightStyle : {}}>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

const AutocompleteAjaxSearch = ({
  activeBaseId,
  minChars = 2,
  textFieldProps,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [endValue, setEndValue] = useState('');
  const [loading, setLoading] = useState(open && options.length === 0);
  const { field, form } = rest;
  // useEffect au changement de "endValue" (valeur à la fin de l'input,
  // après le dernier opérateur) :
  // permet de lancer la requête ajax pour récupérer les propositions
  useEffect(() => {
    let active = true;

    if (!endValue || endValue.length < minChars) {
      setOpen(false);
      return undefined;
    }

    setLoading(true);
    (async () => {
      let values = [];
      try {
        values = await doAutocomplete({
          uriParams: {
            base: activeBaseId,
            query: '*',
            facetmax: 100,
            champ: field.name,
            facetQuery: `${endValue}`,
          },
        });
      } catch (err) {
        console.error('error lors de la récupération des valeurs autocomplete');
      }

      setLoading(false);
      if (active) {
        setOptions(_.map(values, 'key'));
      }
    })();

    return () => {
      active = false;
    };
  }, [minChars, endValue, field.name, activeBaseId]);

  // useEffect pour virer les options lorsque le "dropdown" des
  // résultats est fermé
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...rest}
      open={open}
      onOpen={(event) => {
        // On ferme le "dropdown" si l'input est vide quand on clique dedans
        if (!_.isEmpty(event.target.value)) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      filterOptions={(opts) => (
        // On utilise matchSorter pour trier les résultats et surtout
        // filtrer uniquement sur "endValue"
        matchSorter(opts, endValue)
      )}
      value={field.value || ''}
      onChange={(e, value, reason) => {
        // Le onChange de Autocomplete s'execute pour différentes raisons bien
        // précises
        if (reason === 'selectOption') {
          // Si on a choisi une option dans la liste des propositions
          // On met de côté la valeur de "début", c'est à dire tout ce qu'il
          // y a avant le dernier opérateur
          const beginValue = field.value.substring(0, field.value.lastIndexOf(endValue));
          // On ajoute si besoin les guillemets autour de la valeur choisie
          // en fonction de si l'utilisateur a précisé des guillemets lui-même
          // ou non
          const appendValue = !_.isEmpty(beginValue) && beginValue.trim().slice(-1) === '"' ? value : `"${value}"`;
          // On set la valeur du field dans formik avec la valeur "début" plus
          // l'option finale avec ou sans guillemets autour
          form.setFieldValue(field.name, beginValue + appendValue);
        } else if (reason === 'clear') {
          // Si on a vidé entièrement le champ (via backspace ou la croix en fin
          // d'input), on set la valeur dans formik à vide
          form.setFieldValue(field.name, '');
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          onKeyUp={(event) => {
            // Si la valeur n'a pas changé (on a appuyé sur les flèches par
            // exemple, on ne fait rien)
            if (field.value === event.target.value) {
              return;
            }
            // Sinon on "debounce" de 300 ms pour ne pas prendre en compte
            // instantanément le keyDown
            _.debounce(() => {
              // On garde de côté la valeur de l'input
              const inputValue = !_.isEmpty(event.target.value) ? event.target.value : '';
              let index = -1;
              if (!_.isEmpty(inputValue)) {
                // On va regarder si on trouve les opérateurs dans le texte de l'input
                // l'idée étant de trouver l'opérateur plus proche de la fin du texte
                // afin de conserver dans le state React le reste du texte (ce qui se trouve
                // après cet opérateur)
                _.some(operators, (operator) => {
                  // Si il s'agit d'un opérateur "mot" (et, ou...), on va chercher uniquement
                  // leur présence avec des espaces autour (on ne veut pas couper au milieu)
                  // des mots
                  const operatorString = _.includes(wordOperators, operator) ? ` ${operator} ` : operator;
                  // On garde de côté l'index éventuellement trouvé de l'opérateur + sa longueur
                  const searchedIndex = inputValue.lastIndexOf(operatorString)
                    + operatorString.length;
                  // Si l'opérateur est trouvé dans le texte et qu'il est plus proche
                  // de la fin que les autres opérateurs, on stocke son index
                  index = inputValue.lastIndexOf(operatorString) !== -1
                    && searchedIndex > index ? searchedIndex : index;
                });
              }
              // Si un opérateur est trouvé dans le texte de l'input, on met de côté
              // la fin du texte (qui suit l'opérateur)
              const finalValue = index > 0 ? (
                inputValue.substring(index, inputValue.length).trim()
              ) : inputValue;
              // On set dans le state React local la valeur "endValue"
              setEndValue(finalValue);
              // on set dans formik la valeur complète du field
              form.setFieldValue(field.name, inputValue);
            }, 300)();
          }}
        />
      )}
      renderOption={(props, option) => (
        // handleRenderOption sur "endValue" permet de mettre en valeur
        // (en gras par exemple) le texte de fin de l'input (après le
        // dernier opérateur) dans les résultats du dropdown
        handleRenderOption(props, option, endValue)
      )}
    />
  );
};

AutocompleteAjaxSearch.propTypes = {
  activeBaseId: PropTypes.number.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  minChars: PropTypes.number,
  textFieldProps: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
};

export default AutocompleteAjaxSearch;
