import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

const ResultItemActionsMenu = React.memo(({
  children,
}) => {
  const [isVisibleActionsMenu, setIsVisibleActionsMenu] = useState(false);
  return (
    <ClickAwayListener
      onClickAway={() => setIsVisibleActionsMenu(false)}
      mouseEvent="onMouseDown"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton
          size="small"
          sx={{ padding: '2px', margin: '0 4px 0 2px' }}
          onClick={() => setIsVisibleActionsMenu(!isVisibleActionsMenu)}
        >
          <MoreHoriz />
        </IconButton>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: isVisibleActionsMenu ? 'block' : 'none',
              position: 'absolute',
            }}
          >
            {React.cloneElement(children, { visible: isVisibleActionsMenu })}
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
});

ResultItemActionsMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ResultItemActionsMenu;
