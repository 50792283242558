import React from 'react';
import _ from 'lodash';
import {
  Avatar,
} from '@mui/material';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const UserAvatar = ({ user = {}, ...props }) => {
  const userInitial = _.get((user.nom || user.prenom || user.logon), '[0]', '?');
  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();
  // eslint-disable-next-line max-len
  const avatarSrc = `${API_ENTRY_POINT}/utilisateur/${user.utilisateur}/avatar?logon=${logon}&key=${key}&a=${user.image}`;

  return (
    <Avatar
      src={avatarSrc}
      {...props}
    >
      {userInitial}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  user: utilisateurPropType,
};

export default UserAvatar;
