import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlined,
  CheckCircle,
  CheckCircleOutline,
  Route,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { unsetContextMenu } from 'generic/core/dashboard/actions';

HighchartsNoData(Highcharts);

/**
 * @description
 * Nécessite l'import `NetworkGraphWrapper` au plus haut possible dans l'arborescence, généralement dans `App.jsx` sous
 * `ConfigWrapper`
 * @example
 * ```jsx
 * import NetworkGraphWrapper from 'generic/components/dashboard-items/highchart-extensions/NetworkGraphWrapper';
 * <Provider store={store}>
 *    <ConfigWrapper>
 *      <NetworkGraphWrapper />
 * ```
 * @param {Object} props
 * @returns {React.ComponentElement} NetworkGraph component
 */
const NetworkGraph = ({
  highchartsOptions,
  handleLinkClick = null,
  handleLinkContextmenu = null,
  handleRefreshChart = null,
  linksFilterItems = null,
  linksFilterValue = null,
  nodesFilterItems,
  nodesFilterValue,
  isPreview = false,
  iterations = [],
  withExploration = false,
}) => {
  const [localLinksFilterValue, setLocalLinksFilterValue] = useState(linksFilterValue);
  const [localNodesFilterValue, setLocalNodesFilterValue] = useState(nodesFilterValue);

  const saveUserLoading = useSelector((state) => state.config.user.saveUserLoading);
  const [currentScale, setCurrentScale] = useState(1);
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const chartRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (chartRef.current) {
      const iteration = (_.max(_.map(highchartsOptions.options.series[0].data, 'iteration'))) || 1;
      const newLinks = _.filter(highchartsOptions.options.series[0].data, (link) => link.iteration === iteration);
      if (newLinks.length > 0) {
        newLinks.forEach((link) => {
          chartRef.current.chart.series[0].addPoint(link, false);
        });
        const newNodes = [];
        highchartsOptions.options.series[0].nodes.forEach(
          (node) => {
            if (node.lastExplored) {
              const { nodes } = chartRef.current.chart.series[0];
              const index = _.findIndex(nodes, { id: node.id });
              if (!nodes[index].options.wasExplored) {
                nodes[index].options.marker.radius += 15;
                chartRef.current.chart.renderer.text(node.iteration, nodes[index].dataLabel.bBox.width / 2, 35)
                  .css({
                    color: '#ffffff',
                    fontSize: '19px',
                    fontWeight: 'bold',
                    fill: '#ffffff',
                  })
                  .add(nodes[index].dataLabel);
                nodes[index].options.wasExplored = true;
              }
            }

            if (node.iteration === iteration) {
              newNodes.push(node);
            }
          },
        );
        chartRef.current.chart.series[0].update({
          nodes: newNodes,
        });
      }
    }
  }, [highchartsOptions.options.series]);

  if (saveUserLoading) {
    return false;
  }

  const onMouseOverCheckboxItem = (checkboxValue, itemsType) => {
    const nodes = _.get(chartRef, 'current.chart.series[0].nodes', []);
    const links = _.get(chartRef, 'current.chart.series[0].points', []);
    nodes.forEach((chartNode) => {
      chartNode.graphic.attr({
        opacity: 0.1,
      });
      if (chartNode.dataLabel.attr('originalOpacity') > 0) {
        chartNode.dataLabel.attr({
          opacity: 0.1,
        });
      }
    });
    links.forEach((chartLine) => {
      chartLine.graphic.attr({
        opacity: 0.1,
      });
      if (chartLine.options.hasSiblings) {
        chartLine.graphic.attr({
          opacity: 0,
        });
      }
    });
    links.forEach((chartLine) => {
      const { fromNode, toNode } = chartLine;
      let shouldShowFromNode = false;
      let shouldShowToNode = false;
      let shouldShowLink = false;
      if (itemsType === 'links') {
        if (chartLine.options.type === checkboxValue) {
          shouldShowFromNode = true;
          shouldShowToNode = true;
          shouldShowLink = true;
        }
      } else {
        if (fromNode.options.group === checkboxValue) {
          shouldShowFromNode = true;
        }
        if (toNode.options.group === checkboxValue) {
          shouldShowToNode = true;
        }
        if (shouldShowFromNode && shouldShowToNode) {
          shouldShowLink = true;
        }
      }
      if (shouldShowFromNode) {
        fromNode.graphic.attr({ opacity: 1 });
        fromNode.dataLabel.attr({ opacity: 1 });
      }
      if (shouldShowToNode) {
        toNode.graphic.attr({ opacity: 1 });
        toNode.dataLabel.attr({ opacity: 1 });
      }
      if (shouldShowLink) {
        chartLine.graphic.attr({ opacity: 1 });
      }
    });
  };

  const onMouseOverExplorationIteration = (iteration, allPath) => {
    const nodes = _.get(chartRef, 'current.chart.series[0].nodes', []);
    const links = _.get(chartRef, 'current.chart.series[0].points', []);
    nodes.forEach((chartNode) => {
      chartNode.graphic.attr({
        opacity: 0.1,
      });
      if (chartNode.dataLabel.attr('originalOpacity') > 0) {
        chartNode.dataLabel.attr({
          opacity: 0.1,
        });
      }
    });
    links.forEach((chartLine) => {
      chartLine.graphic.attr({
        opacity: 0.1,
      });
      if (chartLine.options.hasSiblings) {
        chartLine.graphic.attr({
          opacity: 0,
        });
      }
    });
    links.forEach((chartLine) => {
      const { fromNode, toNode } = chartLine;
      if (chartLine.options.iteration === iteration
        || (allPath && chartLine.options.iteration <= iteration)) {
        if (fromNode.options.wasExplored) {
          fromNode.graphic.attr({ opacity: 1 });
          fromNode.dataLabel.attr({ opacity: 1 });
        } else {
          fromNode.graphic.attr({ opacity: 0.5 });
          fromNode.dataLabel.attr({ opacity: 0.8 });
        }
        if (toNode.options.wasExplored) {
          toNode.graphic.attr({ opacity: 1 });
          toNode.dataLabel.attr({ opacity: 1 });
        } else {
          toNode.graphic.attr({ opacity: 0.5 });
          toNode.dataLabel.attr({ opacity: 0.8 });
        }
        chartLine.graphic.attr({ opacity: 0.8 });
      }
    });
  };

  const onMouseOutHoverItem = () => {
    const nodes = _.get(chartRef, 'current.chart.series[0].nodes', []);
    const links = _.get(chartRef, 'current.chart.series[0].points', []);
    nodes.forEach((chartNode) => {
      chartNode.graphic.attr({
        opacity: 1,
      });
      chartNode.dataLabel.attr({
        opacity: chartNode.dataLabel.attr('originalOpacity'),
      });
    });
    links.forEach((chartLink) => {
      chartLink.graphic.attr({
        opacity: 1,
      });
    });
  };

  const defaultOptions = {
    highcharts: Highcharts,
    options: {
      chart: {
        style: {
          fontSize: '20px',
        },
        type: 'networkgraph',
        events: {
          render: function load() {
            const chart = this;
            const { nodes, points: links } = chart.series[0];
            const iteration = _.max(_.map(links, 'iteration'));
            let forceOpacity = false;
            if (iteration) {
              forceOpacity = true;
            }
            nodes.forEach((node) => {
              const { graphic: chartNode, dataLabel } = node;
              const originalOpacity = dataLabel.attr('opacity');
              if (chartNode) {
                dataLabel.attr({
                  originalOpacity,
                });
                if (forceOpacity) {
                  dataLabel.attr({
                    opacity: 0.1,
                  });
                  chartNode.attr({
                    opacity: 0.1,
                  });
                }
                dataLabel.text.attr({
                  class: 'panningDisabled',
                });
                chartNode.on('mouseout', () => {
                  dataLabel.attr({
                    opacity: originalOpacity,
                  });
                });
                chartNode.on('mouseover', () => {
                  dataLabel.attr({
                    opacity: 1,
                  });
                });
              }
            });
            links.forEach((chartLine) => {
              const { fromNode, toNode } = chartLine;
              if (forceOpacity && chartLine.options.iteration !== iteration) {
                chartLine.graphic.attr({
                  opacity: 0.1,
                });
              } else {
                fromNode.dataLabel.attr({
                  opacity: 1,
                });
                fromNode.graphic.attr({
                  opacity: 1,
                });
                toNode.dataLabel.attr({
                  opacity: 1,
                });
                toNode.graphic.attr({
                  opacity: 1,
                });
              }
              chartLine.svgPadding.on('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                document.getElementById('networkgraph-links-tooltip').style.display = 'none';
                handleLinkClick(event, fromNode, toNode, chartLine);
              });
              chartLine.svgPadding.on('contextmenu', (event) => {
                event.preventDefault();
                event.stopPropagation();
                document.getElementById('networkgraph-links-tooltip').style.display = 'none';
                handleLinkContextmenu(event, fromNode, toNode, chartLine);
              });
            });
          },
        },
      },
      credits: { enabled: false },
      plotOptions: {
        networkgraph: {
          keys: ['from', 'to'],
          layoutAlgorithm: {
            enableSimulation: false,
            maxIterations: 100,
            integration: 'verlet',
          },
        },
        series: {
          dataLabels: {
            enabled: true,
            linkFormat: '',
            style: {
              transition: 'opacity 400ms',
              strokeWidth: '1',
            },
          },
        },
      },
      title: { text: 'Titre Network Graph' },
      tooltip: false,
      series: [{
        link: {
          width: 1,
        },
        draggable: true,
      }],
    },
  };

  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const handleToggleFilterValue = (event, itemsType) => {
    const {
      target: { value, checked },
    } = event;
    let localFilterValue;
    if (itemsType === 'links') {
      localFilterValue = localLinksFilterValue;
    } else {
      localFilterValue = localNodesFilterValue;
    }
    let finalFilterValue;
    if (checked) {
      finalFilterValue = [...localFilterValue, value];
    } else {
      finalFilterValue = _.without(localFilterValue, value);
    }
    if (itemsType === 'links') {
      setLocalLinksFilterValue(finalFilterValue);
    } else {
      setLocalNodesFilterValue(finalFilterValue);
    }
  };

  const handleChangeFilterValue = (event, itemsType) => {
    const {
      target: { value },
    } = event;
    const finalFilterValue = typeof value === 'string' ? value.split(',') : value;
    if (itemsType === 'links') {
      setLocalLinksFilterValue(finalFilterValue);
    } else {
      setLocalNodesFilterValue(finalFilterValue);
    }
  };

  let mainBoxProps = {};
  let filtersBoxSxProps = {
    borderLeft: '1px solid #dadada',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    height: '100%',
    pl: 1,
    width: '250px',
  };
  if (smallerThanLarge) {
    mainBoxProps = { component: 'span' };
    filtersBoxSxProps = {
      backgroundColor: 'background.default',
      bottom: '8px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      ml: 1,
      position: 'absolute',
      zIndex: 1,
    };
  }

  return (
    <Fragment>
      <Box
        sx={{
          height: 'inherit',
          width: '100%',
          ...(!smallerThanLarge && { display: 'flex' }),
        }}
        {...mainBoxProps}
      >
        {!smallerThanLarge && !_.isEmpty(iterations) && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: '1',
              top: '70px',
              left: '20px',
              backgroundColor: 'background.default',
              maxHeight: '600px',
              overflow: 'auto',
              p: 1,
            }}
          >
            <Box
              sx={{
                color: 'text',
                fontSize: '18px',
                mb: 1,
              }}
            >
              {t('dashboard.widget.exploration')}
            </Box>
            {_.map(iterations, (iterationItem) => (
              <Box
                sx={{
                  display: 'flex',
                  flexShrink: '0',
                  mb: 0.5,
                }}
                key={iterationItem.iteration}
              >
                <Box
                  onMouseOver={() => onMouseOverExplorationIteration(iterationItem.iteration)}
                  onMouseOut={() => onMouseOutHoverItem()}
                  sx={{ display: 'flex', cursor: 'default' }}
                >
                  <Box
                    sx={{
                      backgroundColor: theme.palette.mode === 'dark' ? '#e8e8e8' : '#000000',
                      borderRadius: '12px',
                      width: '24px',
                      height: '24px',
                      textAlign: 'center',
                      color: theme.palette.mode === 'dark' ? '#000000' : '#e8e8e8',
                      lineHeight: '24px',
                      fontWeight: 'bold',
                      mr: '5px',
                    }}
                  >
                    {iterationItem.iteration}
                  </Box>
                  <Typography title={iterationItem.nodeId} noWrap sx={{ width: '125px' }}>
                    {iterationItem.nodeId}
                  </Typography>
                </Box>
                <Route
                  sx={{
                    cursor: 'default',
                  }}
                  onMouseOver={() => onMouseOverExplorationIteration(iterationItem.iteration, true)}
                  onMouseOut={() => onMouseOutHoverItem()}
                />
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            height: '100%',
            width: '100%',
            flexGrow: '1',
            position: 'relative',
          }}
        >
          <TransformWrapper
            panning={{ excluded: ['highcharts-point', 'panningDisabled'] }}
            onZoomStop={({ state: { scale } }) => {
              setCurrentScale(scale);
              dispatch(unsetContextMenu());
            }}
          >
            {({ resetTransform }) => (
              <Fragment>
                {currentScale > 1 && (
                  <Button
                    color="secondary"
                    sx={{
                      zIndex: 1,
                      position: 'absolute',
                      top: '8px',
                      right: '8px',
                    }}
                    onClick={() => {
                      resetTransform();
                      setCurrentScale(1);
                    }}
                  >
                    {t('dashboard.reset_zoom')}
                  </Button>
                )}
                <TransformComponent>
                  <HighchartsReact
                    ref={chartRef}
                    {...finalConfig}
                    allowChartUpdate={false}
                    immutable={false}
                  />
                </TransformComponent>
              </Fragment>
            )}
          </TransformWrapper>
        </Box>
        {!isPreview && (
          <Box
            sx={filtersBoxSxProps}
          >
            {/* SOIT un SELECT de filtres en bas du graphe en mobile, SOIT une colonne à droite en desktop */}
            {smallerThanLarge ? (
              <Fragment>
                {linksFilterItems && (
                  <Select
                    onChange={(event) => handleChangeFilterValue(event, 'links')}
                    startAdornment={(
                      <InputAdornment position="start">
                        {t('dashboard.links_types')}
                      </InputAdornment>
                    )}
                    multiple
                    value={localLinksFilterValue}
                    sx={{
                      backgroundColor: 'background.default',
                      maxWidth: '400px',
                    }}
                    variant="outlined"
                    renderValue={
                      (selected) => selected.reduce((acc, v, index) => {
                        if (index > 0) acc.push(', ');
                        const item = _.find(linksFilterItems, { value: v });
                        acc.push(<span key={item.value} style={{ color: item?.color }}>{item.name}</span>);
                        return acc;
                      }, [])
                    }
                  >
                    {linksFilterItems.map((linksFilterItem) => (
                      <MenuItem key={linksFilterItem.name} value={linksFilterItem.value}>
                        <Checkbox
                          checked={localLinksFilterValue.indexOf(linksFilterItem.value) > -1}
                          checkedIcon={(
                            linksFilterValue.indexOf(linksFilterItem.value) > -1 ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlined />
                            )
                          )}
                          sx={{
                            '&.Mui-checked': {
                              color: linksFilterItem.color || theme.palette.primary,
                            },
                          }}
                        />
                        <ListItemText primary={linksFilterItem.name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <Select
                  onChange={(event) => handleChangeFilterValue(event, 'nodes')}
                  startAdornment={(
                    <InputAdornment position="start">
                      {t('dashboard.nodes_types')}
                    </InputAdornment>
                  )}
                  multiple
                  value={localNodesFilterValue}
                  sx={{
                    backgroundColor: 'background.default',
                    maxWidth: '400px',
                  }}
                  variant="outlined"
                  renderValue={
                    (selected) => selected.reduce((acc, v, index) => {
                      if (index > 0) acc.push(', ');
                      const item = _.find(nodesFilterItems, { value: v });
                      acc.push(<span key={item.value} style={{ color: item?.color }}>{item.name}</span>);
                      return acc;
                    }, [])
                  }
                >
                  {nodesFilterItems.map((nodesFilterItem) => (
                    <MenuItem key={nodesFilterItem.name} value={nodesFilterItem.value}>
                      <Checkbox
                        checkedIcon={(
                          nodesFilterValue.indexOf(nodesFilterItem.value) > -1 ? <CheckBoxIcon /> : <CheckBoxOutlined />
                        )}
                        sx={{
                          '&.Mui-checked': {
                            color: nodesFilterItem.color || theme.palette.primary,
                          },
                        }}
                        checked={localNodesFilterValue.indexOf(nodesFilterItem.value) > -1}
                      />
                      <ListItemText primary={nodesFilterItem.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Fragment>
            ) : (
              <Fragment>
                {linksFilterItems && (
                  <Fragment>
                    <Box
                      sx={{
                        fontSize: '18px',
                        mb: 0.5,
                      }}
                    >
                      {t('dashboard.links_types')}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        mb: 3,
                      }}
                    >
                      {linksFilterItems.map((linksFilterItem) => (
                        <FormControlLabel
                          key={linksFilterItem.name}
                          value={linksFilterItem.value}
                          onMouseOver={linksFilterItem.nbLinks > 0 ? (
                            () => onMouseOverCheckboxItem(linksFilterItem.value, 'links')
                          ) : _.noop}
                          onMouseOut={linksFilterItem.nbLinks > 0 ? (
                            () => onMouseOutHoverItem()
                          ) : _.noop}
                          onClick={(event) => handleToggleFilterValue(event, 'links')}
                          control={(
                            <Checkbox
                              checked={localLinksFilterValue.indexOf(linksFilterItem.value) > -1}
                              checkedIcon={(
                                linksFilterValue.indexOf(linksFilterItem.value) > -1 ? (
                                  <CheckBoxIcon />
                                ) : (
                                  <CheckBoxOutlined />
                                )
                              )}
                              sx={{
                                color: linksFilterItem.nbLinks ? linksFilterItem.color : 'inherit',
                                '&.Mui-checked': {
                                  color: linksFilterItem.color,
                                },
                              }}
                            />
                          )}
                          label={(
                            <Typography noWrap>
                              {`${linksFilterItem.name} ${linksFilterItem.nbLinks > 0 ? (
                                `(${linksFilterItem.nbLinks})`
                              ) : ''}`}
                            </Typography>
                          )}
                        />
                      ))}
                    </Box>
                  </Fragment>
                )}
                <Box
                  sx={{
                    fontSize: '18px',
                    mb: 0.5,
                  }}
                >
                  {t('dashboard.nodes_types')}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    flexBasis: linksFilterItems ? '250px' : 'unset',
                    flexShrink: '0',
                  }}
                >
                  {nodesFilterItems.map((nodesFilterItem) => (
                    <FormControlLabel
                      key={nodesFilterItem.name}
                      value={nodesFilterItem.value}
                      onMouseOver={nodesFilterItem.nbNodes > 0 ? (
                        () => onMouseOverCheckboxItem(nodesFilterItem.value, 'nodes')
                      ) : _.noop}
                      onMouseOut={nodesFilterItem.nbNodes > 0 ? (
                        () => onMouseOutHoverItem()
                      ) : _.noop}
                      onClick={(event) => handleToggleFilterValue(event, 'nodes')}
                      control={(
                        <Checkbox
                          checked={localNodesFilterValue.indexOf(nodesFilterItem.value) > -1}
                          checkedIcon={(
                            nodesFilterValue.indexOf(nodesFilterItem.value) > -1 ? (
                              <CheckCircle />
                            ) : (
                              <CheckCircleOutline />
                            )
                          )}
                          sx={{
                            '&.Mui-checked': {
                              color: nodesFilterItem.color || theme.palette.primary,
                            },
                          }}
                        />
                      )}
                      label={(
                        <Typography noWrap>
                          {`${nodesFilterItem.name} ${nodesFilterItem.nbNodes > 0 ? (
                            `(${nodesFilterItem.nbNodes})`
                          ) : ''}`}
                        </Typography>
                      )}
                    />
                  ))}
                </Box>
              </Fragment>
            )}
            <Button
              color="secondary"
              sx={{ ml: smallerThanLarge ? 0 : 1, mt: smallerThanLarge ? 0 : 1 }}
              onClick={() => handleRefreshChart(localNodesFilterValue, localLinksFilterValue)}
            >
              {t('dashboard.filter_chart_items')}
            </Button>
          </Box>
        )}
      </Box>
      {!smallerThanLarge && withExploration && (
        <Box
          sx={{
            textAlign: 'center',
            fontSize: '16px',
            color: theme.palette.mode === 'dark' ? '#f2f2f2' : '#777777',
            fontStyle: 'italic',
          }}
        >
          {t('dashboard.widget.shift_click_to_explore')}
        </Box>
      )}
    </Fragment>
  );
};

NetworkGraph.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  handleLinkClick: PropTypes.func,
  handleLinkContextmenu: PropTypes.func,
  handleRefreshChart: PropTypes.func,
  isPreview: PropTypes.bool,
  iterations: PropTypes.arrayOf(PropTypes.shape({
    iteration: PropTypes.number,
    nodeId: PropTypes.string,
  })),
  linksFilterValue: PropTypes.arrayOf(PropTypes.string),
  linksFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  nodesFilterValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  nodesFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  withExploration: PropTypes.bool,
};

export default NetworkGraph;
