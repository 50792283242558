import React, { useRef, useEffect, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import * as Yup from 'yup';
import {
  CheckboxWithLabel,
  TextField,
} from 'formik-mui';
import {
  Button,
  Box,
  FormControl,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { folderPropType, userPropType } from 'generic/core/anr/proptypes';

const DemandSourceDialog = ({
  folders,
  handleClose,
  handleSubmit,
  loading = false,
  open = false,
  user,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  const initialValues = {
    address: '',
    categories_ids: [],
    comment: '',
    folders_ids: [],
    partners_ids: [],
  };
  const addressInput = useRef();

  useEffect(() => {
    if (addressInput.current) {
      addressInput.current.focus();
    }
  }, [addressInput]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        address: Yup.string().required(t('form.errors.mandatory')),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
        >
          <Dialog
            scroll="paper"
            open={open}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle>
              {t('anr.demand_source')}
            </DialogTitle>
            <DialogContent
              dividers
            >
              { /* <pre>{JSON.stringify(form.values, 0, 2)}</pre> */}
              <Field
                autoFocus
                component={TextField}
                fullWidth
                inputRef={addressInput}
                label={t('anr.address')}
                name="address"
              />
              <Field
                component={TextField}
                fullWidth
                label={t('anr.comment')}
                multiline
                minRows={3}
                name="comment"
              />
              {!_.isEmpty(folders) && (
                <FormControl
                  component="fieldset"
                  className="formFieldset"
                  fullWidth
                >
                  <InputLabel>
                    {t('anr.folders_for_source')}
                  </InputLabel>
                  <Box
                    sx={{
                      ml: 1,
                      columnCount: smallerThanMedium ? 1 : 3,
                    }}
                  >
                    {_.map(
                      folders,
                      (folder) => (
                        <Field
                          color="primary"
                          component={CheckboxWithLabel}
                          key={folder.id}
                          Label={{
                            label: (
                              <Fragment>
                                {folder.title}
                                {folder.is_favorite && ' *'}
                              </Fragment>
                            ),
                            sx: { width: '100%' },
                          }}
                          name="folders_ids"
                          type="checkbox"
                          value={folder.id}
                        />
                      ),
                    )}
                  </Box>
                </FormControl>
              )}
              {!_.isEmpty(user.partners) && (
                <FormControl
                  className="formFieldset"
                  component="fieldset"
                  fullWidth
                >
                  <InputLabel>
                    {t('anr.partners_for_source')}
                  </InputLabel>
                  <Box
                    sx={{
                      ml: 1,
                      columnCount: smallerThanMedium ? 1 : 3,
                    }}
                  >
                    {_.map(
                      user.partners,
                      (partner) => (
                        <Field
                          color="primary"
                          component={CheckboxWithLabel}
                          key={partner.id}
                          Label={{
                            label: partner.name,
                            sx: { width: '100%' },
                          }}
                          name="partners_ids"
                          type="checkbox"
                          value={partner.id}
                        />
                      ),
                    )}
                  </Box>
                </FormControl>
              )}
              {!_.isEmpty(user.folders_categories) && (
                <FormControl
                  className="formFieldset"
                  component="fieldset"
                  fullWidth
                >
                  <InputLabel>
                    {t('anr.categories_for_source')}
                  </InputLabel>
                  <Box
                    sx={{
                      ml: 1,
                      columnCount: smallerThanMedium ? 1 : 3,
                    }}
                  >
                    {_.map(
                      user.folders_categories,
                      (category) => (
                        <Field
                          color="primary"
                          component={CheckboxWithLabel}
                          key={category.id}
                          Label={{
                            label: category.name,
                            sx: { width: '100%' },
                          }}
                          name="categories_ids"
                          type="checkbox"
                          value={category.id}
                        />
                      ),
                    )}
                  </Box>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.send')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

DemandSourceDialog.propTypes = {
  folders: PropTypes.arrayOf(folderPropType).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  user: userPropType.isRequired,
};

export default DemandSourceDialog;
