import {
  put,
  call,
  takeEvery,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';

import {
  types,
  uploadFileError,
  uploadFileToCreateDocumentError,
  uploadFileToCreateDocumentSuccess,
} from 'generic/core/files/actions';
import {
  activateCollectionSuccess,
  editCollectionSuccess,
} from 'generic/core/collections/actions';

import { fetchCollection } from 'generic/api/collection';
import { uploadFile } from 'generic/api/uploadFile';

import { snackActions } from 'generic/utils/snackbar';

function* workUploadFile({ params, file, uploadActions }) {
  let message;
  try {
    yield put(uploadActions.loading);
    const results = yield call(uploadFile, { fileUpload: true, uriParams: { ...params }, bodyItems: file });
    message = _.get(results, 'status');
    if (message === 'KO') {
      message = _.get(results, 'error.message');
      throw new Error(message);
    }
    const uploadActionSuccess = {
      ...uploadActions.success,
      results,
    };
    yield put(uploadActionSuccess);
  } catch (error) {
    yield put(uploadActions.error);
    yield put(uploadFileError(error));
    console.error(error);
    snackActions.error(message || i18next.t('file_upload.error_uploading_file'));
  }
}

function* watchUploadFile() {
  yield takeEvery(types.UPLOAD_FILE, workUploadFile);
}

function* workUploadFileToCreateDocument({ params, file, name }) {
  let message;
  try {
    const results = yield call(uploadFile, { fileUpload: true, uriParams: { ...params }, bodyItems: file });
    message = _.get(results, 'status');
    if (message === 'KO') {
      message = _.get(results, 'error.message');
      throw new Error(message);
    }
    yield put(uploadFileToCreateDocumentSuccess(name));
    if (!_.isNil(params.corpusId)) {
      const activeCollection = yield call(fetchCollection, params.corpusId);
      yield put(activateCollectionSuccess(activeCollection[0]));
      yield put(editCollectionSuccess(activeCollection[0]));
    }
    snackActions.success(i18next.t('file_upload.uploading_file_success', { file: name }));
  } catch (error) {
    yield put(uploadFileToCreateDocumentError(name));
    console.error(error);
    snackActions.error(message || i18next.t('file_upload.error_uploading_file'));
  }
}

function* watchUploadFileToCreateDocument() {
  yield takeEvery(types.UPLOAD_FILE_TO_CREATE_DOCUMENT, workUploadFileToCreateDocument);
}

export default {
  watchUploadFile,
  watchUploadFileToCreateDocument,
};
