import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Drawer,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { Close } from '@mui/icons-material';
import CopyToClipboard from 'generic/components/ui/CopyToClipboard';

const Summarize = ({
  handleCloseSummarize,
  building = false,
  hasDrawer = false,
  summary = '',
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const content = (
    <Box sx={{ m: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">
          {t('summarize.summary')}
        </Typography>
        {hasDrawer && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <TooltipButton
              tag="iconButton"
              onClick={handleCloseSummarize}
              size="small"
              edge="end"
              title={t('ui.close')}
            >
              <Close fontSize="small" />
            </TooltipButton>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 3 }}>
        {building && (
          <Skeleton variant="rectangular" height="calc(100vh - 104px)" />
        )}
        {!building && (
          <Box
            sx={{
              overflow: 'auto',
              height: 'calc(100vh - 104px)',
              position: 'relative',
              bgcolor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.06)',
              borderRadius: 2,
              p: 4,
            }}
          >
            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
              <CopyToClipboard textToCopy={summary} />
            </Box>
            {summary && (
              summary
            )}
            {!summary && (
              <Alert severity="info">{t('summarize.no_summary')}</Alert>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );

  return hasDrawer ? (
    <Drawer
      anchor="right"
      variant="temporary"
      open
      onClose={handleCloseSummarize}
      PaperProps={{ sx: { width: { xs: '80%', md: '60%', lg: '40%' } } }}
    >
      {content}
    </Drawer>
  ) : (
    content
  );
};

Summarize.propTypes = {
  summary: PropTypes.string,
  handleCloseSummarize: PropTypes.func.isRequired,
  building: PropTypes.bool,
  hasDrawer: PropTypes.bool,
};

export default Summarize;
