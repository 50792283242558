import {
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';
import {
  types,
  setConfirmDialogLoading,
  unsetConfirmDialogLoading,
  unsetConfirmDialogData,
} from 'generic/core/confirmDialog/actions';

function* confirmDialogSubmit({ dialogData }) {
  try {
    yield put(setConfirmDialogLoading());
    yield put(dialogData.action);
    const result = yield take(dialogData.waitForActions);
    const response = _.get(result, 'response', null);
    if (!(response instanceof Error)) {
      // clean state and dispose dialog
      yield put(unsetConfirmDialogData());
    }
  } finally {
    yield put(unsetConfirmDialogLoading());
  }
}

function* watchConfirmDialogSubmit() {
  yield takeLatest(types.CONFIRM_DIALOG_SUBMIT, confirmDialogSubmit);
}

export default {
  watchConfirmDialogSubmit,
};
