import { formatDistance } from 'date-fns';
import { useEffect, useState } from 'react';
import _ from 'lodash';

import { CONSTANTS } from 'generic/core/constants';

/**
 * Custom hook to calculate and update the duration between two dates in real-time.
 *
 * @param {Date|string} start - The start date of the interval.
 * @param {Date|string} [end] - The end date of the interval. If not provided, the current date and time will be used.
 * @returns {string} - The formatted duration string in 'short' format.
 */
const useLiveDuration = (start, end = new Date()) => {
  const lang = CONSTANTS.LANG_MAPPING[_.get(window, 'QWAM.localeId', 1)];
  const init = formatDistance(start, end, { addSuffix: true });

  const [duration, setDuration] = useState(init);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const ret = formatDistance(start, end);
      setDuration(ret);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [start, end, lang]);

  return duration;
};

export default useLiveDuration;
