import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closeEditTranscriptionAnnotationDialog } from 'generic/core/ged/actions';
import EditTranscriptionAnnotationDialog from 'generic/components/ui/EditTranscriptionAnnotationDialog';

const EditTranscriptionAnnotationDialogContainer = ({ patchDocumentInComplete }) => {
  const loading = useSelector((state) => state.ged.loading);
  const open = useSelector((state) => state.ged.dialogEditTranscriptionAnnotation.open);
  const start = useSelector((state) => state.ged.dialogEditTranscriptionAnnotation.start);
  const annotation = useSelector((state) => state.ged.dialogEditTranscriptionAnnotation.annotation);
  const dispatch = useDispatch();

  const handleSubmit = (newAnnotation) => {
    const params = {
      transcription_comments_detection: 'Text_comments_json',
      transcription_comment_start: start,
      transcription_comment: newAnnotation,
    };
    patchDocumentInComplete(params);
  };
  const handleClose = () => {
    dispatch(closeEditTranscriptionAnnotationDialog());
  };

  if (!open) {
    return null;
  }
  return (
    <EditTranscriptionAnnotationDialog
      handleSubmit={handleSubmit}
      loading={loading}
      handleClose={handleClose}
      start={start}
      annotation={annotation}
    />
  );
};

EditTranscriptionAnnotationDialogContainer.propTypes = {
  patchDocumentInComplete: PropTypes.func.isRequired,
};

export default EditTranscriptionAnnotationDialogContainer;
