import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  EmailTextField,
  ForgottenPasswordButton,
  FormContainer,
  FormFieldsContainer,
  LoadingButtonWithIndicator,
  PasswordTextField,
} from 'generic/components/forms/FormLoginCommonComponents';

const FormLogin = ({ loading, logonError, toggleResetPassword }) => {
  const { t } = useTranslation();

  const usernameInput = useRef(null);

  return (
    <FormContainer
      title={t('logon.log_in')}
    >
      <FormFieldsContainer>
        <Field
          autoComplete="username"
          autoFocus
          component={EmailTextField}
          name="login"
          label={t('logon.login')}
          placeholder={t('logon.login_placeholder')}
          innerRef={usernameInput}
          required
        />

        <Field
          autoComplete="current-password"
          component={PasswordTextField}
          name="password"
          label={t('logon.password')}
          placeholder={t('logon.password_placeholder')}
          required
        />

        {logonError && (
        <Typography color="error" variant="subtitle2" sx={(theme) => ({ color: theme.palette.text.error })}>
          {t('logon.fail_auth')}
        </Typography>
        )}

        <ForgottenPasswordButton
          onClick={() => {
            toggleResetPassword();
            usernameInput.current.focus();
          }}
        >
          {t('logon.forgotten_password')}
        </ForgottenPasswordButton>

        <LoadingButtonWithIndicator
          disabled={loading}
          loading={loading}
          type="submit"
        >
          {t('logon.to_login')}
        </LoadingButtonWithIndicator>
      </FormFieldsContainer>
    </FormContainer>
  );
};

FormLogin.propTypes = {
  loading: PropTypes.bool.isRequired,
  logonError: PropTypes.bool.isRequired,
  toggleResetPassword: PropTypes.func.isRequired,
};

export default FormLogin;
