/* eslint-disable no-param-reassign */
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import Highmaps from 'highcharts/highmaps';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ClickAwayListener,
  darken,
  Divider,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWidgets, setDashboardLoading, unsetContextMenu } from 'generic/core/dashboard/actions';
import { fetchResults, refineAddFacetsValues } from 'generic/core/search/actions';
import { useTheme } from '@emotion/react';
import CopyToClipboard from 'generic/components/ui/CopyToClipboard';

Highcharts.wrap(Highcharts.Chart.prototype, 'firstRender', function renderLink(proceed) {
  proceed.call(this);

  const chart = this;
  const {
    container,
    plotLeft,
    plotTop,
    plotWidth,
    plotHeight,
    inverted,
    pointer,
  } = this;

  // Note:
  // - Safari 5, IE8: mousedown, contextmenu, click
  // - Firefox 5: mousedown contextmenu
  // eslint-disable-next-line func-names
  container.oncontextmenu = function (e) {
    const { hoverPoint } = chart;
    const { chartPosition } = pointer;

    this.rightClick = true;

    e = pointer.normalize(e);

    e.cancelBubble = true; // IE specific
    e.returnValue = false; // IE 8 specific
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.preventDefault) {
      e.preventDefault();
    }

    if (!pointer.hasDragged) {
      if (hoverPoint && pointer.inClass(e.target, 'highcharts-tracker')) {
        const { plotX, plotY } = hoverPoint;
        // plotY = hoverPoint.plotY;

        // add page position info
        Highcharts.extend(hoverPoint, {
          pageX: chartPosition.left + plotLeft
            + (inverted ? plotWidth - plotY : plotX),
          pageY: chartPosition.top + plotTop
            + (inverted ? plotHeight - plotX : plotY),
        });

        // the series click event
        Highcharts.fireEvent(
          hoverPoint.series,
          'contextmenu',
          Highcharts.extend(e, {
            point: hoverPoint,
          }),
        );

        // the point click event
        hoverPoint.firePointEvent('contextmenu', e);
      }
    }
  };
});

// eslint-disable-next-line func-names
Highmaps.wrap(Highmaps.Chart.prototype, 'firstRender', function renderLink(proceed) {
  proceed.call(this);

  const chart = this;
  const {
    container,
    plotLeft,
    plotTop,
    plotWidth,
    plotHeight,
    inverted,
    pointer,
  } = this;

  // Note:
  // - Safari 5, IE8: mousedown, contextmenu, click
  // - Firefox 5: mousedown contextmenu
  // eslint-disable-next-line func-names
  container.oncontextmenu = function (e) {
    const { hoverPoint } = chart;
    const { chartPosition } = pointer;

    this.rightClick = true;

    e = pointer.normalize(e);

    e.cancelBubble = true; // IE specific
    e.returnValue = false; // IE 8 specific
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.preventDefault) {
      e.preventDefault();
    }

    if (!pointer.hasDragged) {
      if (hoverPoint && pointer.inClass(e.target, 'highcharts-tracker')) {
        const { plotX, plotY } = hoverPoint;
        // plotY = hoverPoint.plotY;

        // add page position info
        Highcharts.extend(hoverPoint, {
          pageX: chartPosition.left + plotLeft
            + (inverted ? plotWidth - plotY : plotX),
          pageY: chartPosition.top + plotTop
            + (inverted ? plotHeight - plotX : plotY),
        });

        // the series click event
        Highcharts.fireEvent(
          hoverPoint.series,
          'contextmenu',
          Highcharts.extend(e, {
            point: hoverPoint,
          }),
        );

        // the point click event
        hoverPoint.firePointEvent('contextmenu', e);
      }
    }
  };
});

const ContextMenu = forwardRef(({ relationsLinksFilterValue = null }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const containerRef = useRef(null);

  const contextMenu = useSelector((state) => state.dashboard.contextMenu);

  const {
    base: activeBaseId,
    tranche: activeBaseDefaultTranche,
    tranches: activeBaseTranches,
  } = useSelector((state) => state.config.activeBase);

  const [dimensionsMenu, setDimensionsMenu] = useState({ width: 0, height: 0 });

  let tranche = activeBaseDefaultTranche;
  const localStorageTranche = +localStorage.getItem('rowsPerPage');
  if (!_.isEmpty(activeBaseTranches)
    && activeBaseTranches.includes(localStorageTranche)) {
    tranche = localStorageTranche;
  }

  const handleUnsetContextMenu = () => {
    if (!_.isEmpty(contextMenu)) {
      dispatch(unsetContextMenu());
    }
  };

  useEffect(() => {
    handleUnsetContextMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      const height = containerRef.current.offsetHeight;
      setDimensionsMenu({ width, height });
    } else {
      setDimensionsMenu({ width: 0, height: 0 });
    }

    const scrollableElement = ref.current;
    // Ajouter l'écouteur d'événements pour le redimensionnement de la fenêtre
    window.addEventListener('resize', handleUnsetContextMenu);
    window.addEventListener('scroll', handleUnsetContextMenu);
    scrollableElement.addEventListener('scroll', handleUnsetContextMenu);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleUnsetContextMenu);
      window.removeEventListener('scroll', handleUnsetContextMenu);
      scrollableElement.removeEventListener('scroll', handleUnsetContextMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenu]);

  const handleClose = () => {
    dispatch(unsetContextMenu());
  };

  const handleFilter = (quickResults) => {
    if (contextMenu?.pointOptions?.iteration) {
      if (contextMenu?.pointOptions?.relatedLinks) {
        relationsLinksFilterValue.current = [...new Set(
          _.concat(
            relationsLinksFilterValue.current,
            _.map(contextMenu?.pointOptions?.relatedLinks, 'type'),
          ),
        )];
      } else if (!relationsLinksFilterValue.current.includes(contextMenu?.pointOptions?.type)) {
        relationsLinksFilterValue.current.push(contextMenu?.pointOptions?.type);
      }
      let additionnalFetchParams = {
        refreshForm: true,
        clearSelection: true,
        clearResults: true,
      };
      if (quickResults) {
        additionnalFetchParams = {
          quickResultsScope: true,
        };
      } else {
        dispatch(setDashboardLoading());
      }
      dispatch(fetchResults({
        bodyItems: {
          premier: 1,
          dernier: tranche,
          base: activeBaseId,
          champs: contextMenu.champs,
        },
        ...additionnalFetchParams,
      }));
    } else {
      dispatch(refineAddFacetsValues(contextMenu.facetsValues, (quickResults)));
    }
    handleClose();
  };

  const handleExclude = () => {
    _.map(contextMenu.facetsValues, (facetValue) => {
      facetValue.exclude = true;
      return facetValue;
    });

    dispatch(refineAddFacetsValues(contextMenu.facetsValues));
    handleClose();
  };

  const handleExploration = () => {
    const keepAllWidgetsData = true;
    dispatch(fetchWidgets(
      `QES_Relation_Source_Text.verbatim:"${_.trim(contextMenu.name, ' "')}"`,
      [
        {
          dashboard_widget: contextMenu.widgetId,
          // eslint-disable-next-line max-len
          facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
          relations: contextMenu.joinedTypesValue,
          aggregates: [contextMenu.joinedTypesValue],
          facetmax: 200,
          facetmax2: 5,
          mindoccount: 1,
          additionalQuery: contextMenu.additionalQuery,
          type: 'relations',
        },
      ],
      false,
      keepAllWidgetsData,
      contextMenu.name,
    ));
    handleClose();
  };

  if (_.isEmpty(contextMenu)) {
    return null;
  }

  const positionMenu = {};
  if (contextMenu.x + dimensionsMenu.width >= document.documentElement.clientWidth) {
    positionMenu.right = document.documentElement.clientWidth - contextMenu.x;
  } else {
    positionMenu.left = contextMenu.x;
  }
  if (contextMenu.y + dimensionsMenu.height >= document.documentElement.clientHeight) {
    positionMenu.top = contextMenu.y - dimensionsMenu.height;
  } else {
    positionMenu.top = contextMenu.y;
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        backgroundColor: 'background.default',
        boxShadow: 2,
        borderRadius: '10px',
        zIndex: '100',
        position: 'fixed',
        overflow: 'hidden',
        ...positionMenu,
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              backgroundColor: darken(theme.palette.background.paper, 0.15),
              py: 0.5,
              px: 1,
            }}
          >
            <Tooltip title={contextMenu.filterTitle} placement="bottom">
              <Typography
                sx={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontSize: '16px',
                  transform: 'skewX(-10deg)',
                }}
              >
                {contextMenu.filterTitle}
              </Typography>
            </Tooltip>
            <CopyToClipboard textToCopy={contextMenu.filterTitle} />
          </Box>

          <Divider />
          <MenuList sx={{ padding: '0px' }}>
            <MenuItem onClick={() => handleFilter(false)} sx={{ padding: '4px 8px' }}>
              {t('dashboard.context_menu.filter')}
            </MenuItem>
            <MenuItem onClick={() => handleFilter(true)} sx={{ padding: '4px 8px' }}>
              {t('dashboard.context_menu.quick_results')}
            </MenuItem>
            {!contextMenu.iteration && (
              <MenuItem onClick={handleExclude} sx={{ padding: '4px 8px' }}>
                {t('dashboard.context_menu.exclude')}
              </MenuItem>
            )}
            {contextMenu.hasExploration && (
              <MenuItem onClick={handleExploration} sx={{ padding: '4px 8px' }}>
                {t('dashboard.context_menu.exploration')}
              </MenuItem>
            )}
          </MenuList>
        </Box>
      </ClickAwayListener>
    </Box>
  );
});

ContextMenu.propTypes = {
  // eslint-disable-next-line react/require-default-props
  relationsLinksFilterValue: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ContextMenu;
