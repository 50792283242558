import React, {
  useRef,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Divider,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { format } from 'generic/utils/dateUtils';
import CenteredMessage from 'generic/components/ui/CenteredMessage';
import DocumentsListSkeleton from 'generic/components/skeletons/DocumentsListSkeleton';
import ResultItem from 'generic/components/pages/anr/ResultItem';

const Results = ({
  articles = [],
  checkedItems = {},
  handleOpenArticle,
  handleToggleCheckbox,
  handleSourceFilter,
  handleFilterFolder,
  handleFetchDuplicates,
  loading = false,
  openedArticleID = null,
  reponseGoToIndex = null,
  shouldDisplayResults,
  view = '',
}) => {
  const { t } = useTranslation();
  const scrollTo = useRef();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [goToId, setGoToId] = useState(null);
  const [visibleDuplicates, setVisibleDuplicates] = useState({});

  const handleVisibleDuplicates = (article) => {
    setVisibleDuplicates({ ...visibleDuplicates, [article.id]: !visibleDuplicates[article.id] });
    if (_.isEmpty(article.duplicates)) {
      handleFetchDuplicates(article);
    }
  };

  useEffect(() => {
    if (reponseGoToIndex) {
      // On set l'ID de l'article vers lequel on veut scroller en fonction de
      // la requête (accès direct à un article, dernière info validée...)
      setGoToId(reponseGoToIndex);
    }
  }, [reponseGoToIndex]);

  useEffect(() => {
    if (openedArticleID) {
      // On set l'ID de l'article vers lequel on veut scroller en fonction de
      // l'article ouvert
      setGoToId(openedArticleID);
    }
  }, [openedArticleID]);

  useEffect(() => {
    if (scrollTo.current) {
      // Si l'ID de ref a changé et "matche" un article dans la liste, c'est qu'on
      // veut scroller vers lui, donc on y va
      scrollTo.current.scrollIntoView({ behavior: goToId ? 'smooth' : 'auto', block: 'start' });
    }
  }, [goToId]);

  useEffect(() => {
    if (loading && !scrollTo.current) {
      // Si loading vient de changer, on scroll tout en haut de la liste
      if (!smallerThanLarge) {
        document.getElementById('results').scrollTop = 0;
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [loading, smallerThanLarge]);

  const showImagesInSkeleton = view !== 'quotes' && !smallerThanMedium;
  return (
    <Box
      sx={{
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{ overflow: 'auto' }}
        id="results"
      >
        {loading && (
          <DocumentsListSkeleton
            nbDocs={20}
            withHeader
            withImages={showImagesInSkeleton}
            withLastLine={false}
          />
        )}
        {shouldDisplayResults && !loading && !_.isEmpty(articles) ? (
          <List dense>
            {_.map(articles, (article, index) => (
              <Box
                key={article.id}
              >
                {(
                  index === 0
                  || (
                    format(new Date(articles[index - 1].display_date * 1000), 'dd/MM/yyyy')
                    !== format(new Date(article.display_date * 1000), 'dd/MM/yyyy')
                  )
                ) && (
                  <ListItem>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        mr: 3,
                        my: 0.5,
                      }}
                    >
                      <Box
                        sx={{ mr: 2 }}
                      >
                        <Typography>{_.capitalize(format(new Date(article.display_date * 1000), 'full'))}</Typography>
                      </Box>
                      <Divider className="flexGrow1" />
                    </Box>
                  </ListItem>
                )}
                <ListItem
                  ref={article.id === goToId ? scrollTo : _.noop}
                  sx={{
                    display: 'block',
                    wordBreak: 'break-word',
                    p: 0,
                    paddingBottom: 1,
                  }}
                >
                  <ResultItem
                    item={article}
                    view={view}
                    smallerThanMedium={smallerThanMedium}
                    handleOpenArticle={handleOpenArticle}
                    handleSourceFilter={() => handleSourceFilter(article.source)}
                    handleFilterFolder={handleFilterFolder}
                    checkboxChecked={checkedItems[article.id] !== undefined}
                    handleToggleCheckbox={() => handleToggleCheckbox(article)}
                    duplicatesIsVisible={visibleDuplicates[article.id]}
                    handleVisibleDuplicates={handleVisibleDuplicates}
                  />
                </ListItem>
              </Box>
            ))}
          </List>
        ) : (
          !loading && (
            <CenteredMessage>
              {t('results.no_results')}
            </CenteredMessage>
          )
        )}
      </Box>
    </Box>
  );
};

Results.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()),
  checkedItems: PropTypes.shape(),
  handleOpenArticle: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
  handleSourceFilter: PropTypes.func.isRequired,
  handleFilterFolder: PropTypes.func.isRequired,
  handleFetchDuplicates: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  openedArticleID: PropTypes.string,
  reponseGoToIndex: PropTypes.string,
  shouldDisplayResults: PropTypes.bool.isRequired,
  view: PropTypes.string,
};

export default Results;
