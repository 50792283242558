import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import CriteriaContainer from 'generic/containers/CriteriaContainer';
import DashboardResultsContainer from 'generic/containers/DashboardResultsContainer';
import SearchHeaderSkeleton from 'generic/components/skeletons/SearchHeaderSkeleton';
import CriteriaButtonsContainer from 'generic/containers/CriteriaButtonsContainer';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { FileDownload } from '@mui/icons-material';

const DashboardHeader = ({ loading = false, hasReport = false }) => (
  <Box
    className="stickyHeader withBackgroundSpecial"
    sx={{ zIndex: '1200' }}
  >
    <Box sx={{ display: loading ? 'block' : 'none' }}>
      <SearchHeaderSkeleton />
    </Box>
    <Box sx={{ display: loading ? 'none' : 'flex', flexWrap: 'wrap' }}>
      <Box sx={{ flexGrow: '1', display: 'flex', flexWrap: 'wrap' }}>
        <CriteriaButtonsContainer />
        <CriteriaContainer />
      </Box>
      <Box
        sx={{
          flexShrink: '0',
          mt: 1,
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          columnGap: '5px',
          flexGrow: '1',
          justifyContent: 'right',
        }}
      >
        {hasReport && (
          <TooltipButton
            color="primary"
            component={NavLink}
            to="/report"
            size="small"
            sx={{
              minWidth: '40px',
              height: '30px',
            }}
          >
            <FileDownload fontSize="small" />
          </TooltipButton>
        )}
        <DashboardResultsContainer />
      </Box>
    </Box>
  </Box>
);

DashboardHeader.propTypes = {
  loading: PropTypes.bool,
  hasReport: PropTypes.bool,
};

export default DashboardHeader;
