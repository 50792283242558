import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
  List,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { facetPropType } from 'generic/core/qes/proptypes';
import FacetValue from 'generic/components/ui/FacetValue';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { MAX_FACET_DISPLAYED_WHEN_SHRINKED } = QES_CONSTANTS;

const Facet = ({ facet, handleSearch }) => {
  const [checkedFacets, setCheckedFacets] = useState({});
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [toggleShowAllItems, setToggleShowAllItems] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setCheckedFacets({});
  }, [facet]);

  // on s'appuie sur la première valeur pour savoir si le format permet le rafinage ou non.
  const formatFacet = _.get(facet, 'facetValues[0].formatFacet');
  const isRefineVisible = !['date', 'annee', 'date_annee', 'date_range', 'multibase'].includes(formatFacet);

  const handleToggleCheckbox = useCallback(
    (facetValue) => {
      const { id } = facetValue;
      const alteredCheckedFacets = { ...checkedFacets };
      if (alteredCheckedFacets[id]) {
        delete alteredCheckedFacets[id];
      } else {
        alteredCheckedFacets[id] = facetValue;
      }
      setCheckedFacets(alteredCheckedFacets);
    },
    [checkedFacets],
  );

  const handleSearchDecorator = useCallback(
    () => (facetValue) => handleSearch(facet, facetValue),
    [facet, handleSearch],
  );

  let facetValues = _.cloneDeep(facet.facetValues);
  if (!toggleShowAllItems) {
    facetValues = facetValues.slice(0, MAX_FACET_DISPLAYED_WHEN_SHRINKED);
  }
  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{ '&.Mui-expanded:before': { opacity: 1 } }}
      expanded={isAccordionExpanded}
      onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
      disabled={!facet.facetValues.length > 0}
    >
      <AccordionSummary
        sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
          zIndex: 1,
        }}
        className="stickyHeader"
        expandIcon={<KeyboardArrowDown sx={{ fontSize: '0.9rem' }} />}
      >
        {facet.libelle}
      </AccordionSummary>
      <AccordionDetails>
        {isAccordionExpanded && (
          <Fragment>
            <List
              dense
              disablePadding
              sx={{
                paddingRight: 2,
                '& ul': { padding: 0 },
              }}
            >
              {facetValues.map((facetValue) => (
                <FacetValue
                  key={facetValue.id}
                  checked={checkedFacets[facetValue.id] !== undefined}
                  facetValue={facetValue}
                  handleSearch={handleSearchDecorator()}
                  handleToggleCheckbox={handleToggleCheckbox}
                  hideCheckbox={!isRefineVisible}
                />
              ))}
            </List>
            {facet.facetValues.length > MAX_FACET_DISPLAYED_WHEN_SHRINKED && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 0.5 }}>
                <Button
                  onClick={() => setToggleShowAllItems(!toggleShowAllItems)}
                  size="extraSmall"
                  sx={{ textTransform: 'none' }}
                  variant="text"
                >
                  {toggleShowAllItems ? t('results.facets.show_less') : t('results.facets.show_more')}
                </Button>
              </Box>
            )}
            {isRefineVisible && (
              <Button
                onClick={() => handleSearch(facet, checkedFacets)}
                disabled={_.isEmpty(checkedFacets)}
                fullWidth
                size="small"
                variant="contained"
              >
                {t('results.facets.refine')}
              </Button>
            )}
          </Fragment>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

Facet.propTypes = {
  facet: facetPropType.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

const Memoized = React.memo(Facet);
export default Memoized;
