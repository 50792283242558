import _ from 'lodash';
import { types } from 'generic/core/files/actions';

const filesReducer = (
  state = {
    filesToUpload: [],
  },
  action,
) => {
  switch (action.type) {
    case types.UPLOAD_FILE_TO_CREATE_DOCUMENT: {
      const filesToUploadCloned = _.cloneDeep(state.filesToUpload);
      const fileToUpload = _.find(filesToUploadCloned, { name: action.name });
      fileToUpload.loading = true;
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.UPLOAD_FILE_TO_CREATE_DOCUMENT_SUCCESS: {
      const filesToUploadCloned = _.cloneDeep(state.filesToUpload);
      const fileToUpload = _.find(filesToUploadCloned, { name: action.name });
      fileToUpload.loading = false;
      fileToUpload.success = true;
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.UPLOAD_FILE_TO_CREATE_DOCUMENT_ERROR: {
      const filesToUploadCloned = _.cloneDeep(state.filesToUpload);
      const fileToUpload = _.find(filesToUploadCloned, { name: action.name });
      fileToUpload.loading = false;
      fileToUpload.success = false;
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.ADD_FILE_TO_UPLOAD: {
      const filesToUploadCloned = _.cloneDeep(state.filesToUpload);

      _.forEach(action.files, (value) => {
        if (!_.find(filesToUploadCloned, { name: value.name })) {
          filesToUploadCloned.push({
            openPreview: false,
            name: value.name,
            size: value.size,
            type: value.type,
            path: value.path,
            preview: URL.createObjectURL(value),
            loading: false,
          });
        }
      });
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.REMOVE_FILE_TO_UPLOAD: {
      const filesToUploadCloned = [];
      _.forEach(state.filesToUpload, (item) => {
        if (item.name !== action.file.name) {
          filesToUploadCloned.push(item);
        }
      });
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.CLEAN_FILES_TO_UPLOAD: {
      return {
        ...state,
        filesToUpload: [],
      };
    }
    case types.OPEN_FILE_PREVIEW: {
      const filesToUploadUpdated = state.filesToUpload.map((file) => ({
        ...file,
        openPreview: file.name === action.fileName ? !file.openPreview : false,
      }));

      return {
        ...state,
        filesToUpload: filesToUploadUpdated,
      };
    }
    default:
      return state;
  }
};

export default filesReducer;
