import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { differenceInSeconds } from 'date-fns';

import {
  Box,
  FormControlLabel,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  useMediaQuery,
  LinearProgress,
} from '@mui/material';
import {
  Sync,
} from '@mui/icons-material';

import MUIDataTable from 'mui-datatables';
import TooltipButton from 'generic/components/ui/TooltipButton';

import useLiveDuration from 'generic/core/hooks/useLiveDuration';
import { format } from 'generic/utils/dateUtils';
import { traitementsQtaPropType } from 'generic/core/qes/proptypes';

const customBodyRenderDate = (value) => (value ? format(value) : null);

const percentageRenderer = (value, tableMeta) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress
        variant="determinate"
        value={value}
        color={tableMeta.rowData[6] === 'finished' ? 'success' : 'info'}
      />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

// eslint-disable-next-line react/prop-types
const RenderLiveDuration = memo(({ rowData }) => {
  const [,, start, end, , , etat] = rowData;
  const endOrNow = (etat === 'running') ? new Date() : end;
  const duration = useLiveDuration(start, endOrNow);

  return (
    <span style={{
      display: 'inline-block',
      width: '100px',
    }}
    >
      {duration}
    </span>
  );
}, (oldprops, newprops) => oldprops.etat === newprops.etat);

const CollectionsMonitoring = ({
  traitementsQta = [],
  loading = false,
  setStatusFilter,
  statusFilter = 'all',
  handleForceRefresh,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const handleToggleButtonChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const renderActions = (traitement) => {
    const ONE_DAY_IN_SECONDES = 86400;
    const canRefreshFromQta = differenceInSeconds(new Date(), new Date(traitement.date_debut)) > ONE_DAY_IN_SECONDES;
    const buttonProps = {
      tag: 'fab',
      color: 'primary',
      size: 'extraSmall',
      disabled: (canRefreshFromQta || traitement.etat !== 'running'),
    };

    return (
      <Stack spacing={0.5} direction="row">
        <TooltipButton
          {...buttonProps}
          title={t('collections.corpus.monitoring_qta.force_refresh')}
          onClick={() => handleForceRefresh(traitement.traitement_qta)}
        >
          <Sync />
        </TooltipButton>
      </Stack>
    );
  };

  const data = traitementsQta.map((obj) => [
    obj.avancement_pourcentage,
    obj.collection_id,
    obj.date_debut,
    obj.date_fin,
    obj.duree,
    obj.fichier,
    obj.etat,
    obj.type,
    obj.traitement_qta,
    obj.nom,
    obj.request_id,
    obj.traitement_qta,
    obj.utilisateur_id,
    obj.avancement_nombre,
  ]);

  const columns = [
    {
      name: 'avancement_pourcentage',
      label: t('collections.corpus.monitoring_qta.columns.progression_percentile'),
      options: {
        filter: false,
        customBodyRender: percentageRenderer,
      },

    },
    {
      name: 'collection_id',
      label: t('collections.corpus.monitoring_qta.columns.collection_id'),
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'date_debut',
      label: t('collections.corpus.monitoring_qta.columns.start_date'),
      options: { customBodyRender: customBodyRenderDate },
    },
    {
      name: 'date_fin',
      label: t('collections.corpus.monitoring_qta.columns.end_date'),
      options: { customBodyRender: customBodyRenderDate },
    },
    {
      name: 'duree',
      label: t('collections.corpus.monitoring_qta.columns.duration'),
      options: {
        filter: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRender: (_, { rowData }) => (
          (!rowData[3] && rowData[6] !== 'running') // 3:end 6:etat
            ? '--'
            : <RenderLiveDuration rowData={rowData} />),
      },
    },
    { name: 'fichier', label: t('collections.corpus.monitoring_qta.columns.file') },
    { name: 'etat', label: t('collections.corpus.monitoring_qta.columns.status') },
    {
      name: 'type',
      label: t('collections.corpus.monitoring_qta.columns.type'),
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: '',
      label: t('actions.actions'),
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (index) => renderActions(traitementsQta[index]),
      },
    },
    {
      name: 'nom',
      label: 'nom',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'request_id',
      label: 'request_id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'traitement_qta',
      label: 'traitement_qta',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'utilisateur_id',
      label: 'utilisateur_id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'avancement_nombre',
      label: 'avancement_nombre',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];

  const textLabels = t('datatable.textLabels', { returnObjects: true });
  const options = {
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    fixedHeader: true,
    selectableRows: 'none',
    textLabels: {
      ...textLabels,
      body: {
        ...textLabels.body,
        noMatch: loading ? t('datatable.loading') : textLabels.body.noMatch,
      },
    },
    downloadOptions: { filename: 'export.csv' },
    onChangePage: () => {
      const table = document.querySelector('#datatable .MuiTable-root');
      table.scrollIntoView(true);
    },
    sortOrder: {
      name: 'date_of_creation',
      direction: 'desc',
    },
    responsive: responsiveTableType,
    setTableProps: () => ({ size: 'small' }),
  };

  return (
    <Box
      width="100%"
      m={smallerThanLarge ? 0 : 2}
      id="datatable"
    >
      <MUIDataTable
        title={(
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" mr={2} lineHeight="38px">
              {t('collections.corpus.monitoring_qta.title')}
            </Typography>
            <FormControlLabel
              control={(
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  onChange={(event) => handleToggleButtonChange(event)}
                  value={statusFilter}
                  size="small"
                >
                  <ToggleButton value="running">
                    {t('collections.corpus.monitoring_qta.filters.running')}
                  </ToggleButton>
                  <ToggleButton value="finished">
                    {t('collections.corpus.monitoring_qta.filters.finished')}
                  </ToggleButton>
                  <ToggleButton value="interrupted">
                    {t('collections.corpus.monitoring_qta.filters.interrupted')}
                  </ToggleButton>
                  <ToggleButton value="all">
                    {t('collections.corpus.monitoring_qta.filters.all')}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Stack>
        )}
        data={data}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

CollectionsMonitoring.propTypes = {
  traitementsQta: traitementsQtaPropType,
  loading: PropTypes.bool,
  setStatusFilter: PropTypes.func.isRequired,
  statusFilter: PropTypes.string,
  handleForceRefresh: PropTypes.func.isRequired,
};

export default CollectionsMonitoring;
