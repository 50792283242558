import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsPatterFill from 'highcharts/modules/pattern-fill';

HighchartsHeatmap(Highcharts);
HighchartsNoData(Highcharts);
HighchartsPatterFill(Highcharts);

function getPointCategoryName(point, dimension) {
  const { series } = point;
  const isY = dimension === 'y';
  const axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x']];
}

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    chart: {
      type: 'heatmap',
      dataLabels: {
        enabled: true,
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
    },
    plotOptions: {
      heatmap: {
        borderWidth: 1,
        nullColor: {
          pattern: {
            path: {
              d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
              strokeWidth: 2,
              fill: '#102045',
            },
            width: 10,
            height: 10,
            color: '#907000',
            opacity: 0.5,
          },
        },
      },
    },
    colorAxis: {
      min: 0,
      minColor: '#FFFFFF',
      maxColor: Highcharts.getOptions().colors[0],
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 30,
      symbolHeight: 280,
    },
    tooltip: {
      formatter: function tooltipFormatter() {
        return `
        <b>${getPointCategoryName(this.point, 'x')}</b>
        &rarr; ${getPointCategoryName(this.point, 'y')} : ${this.point.value}
        `;
      },
    },
    yAxis: {
      title: null,
      reversed: true,
    },
  },
};

const Heatmap = ({ highchartsOptions }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
    />
  );
};

Heatmap.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default Heatmap;
