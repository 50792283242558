import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';

import { qesdocumentPropType } from 'generic/core/qes/proptypes';
import { overrideRessource } from 'generic/utils/utils';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const DocumentForWidgetList = ({
  qesdocument = {},
  handleDocumentClick,
}) => {
  // OBLIGÉ DE FAIRE UN OBJECT INTERMÉDIAIRE DÉGUEUX POUR TENTER D'AVOIR UN DOCUMENT
  // TYPE QES COMME DANS LES AUTRES ENDPOINTS
  const {
    title, linkout, date, source,
  } = useDocumentFields({
    base: +qesdocument.BASE_ID,
    qesdocument: { ...qesdocument },
  });

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab' && linkout) {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleDocumentClick,
    };
  }
  return (
    <Box sx={{ display: 'block' }}>
      <Typography
        variant="h6"
        component="span"
        sx={{
          fontSize: '0.8rem',
          lineHeight: '1',
        }}
      >
        <Box
          {...titleLinkProps}
          color="text.primary"
          sx={{ cursor: 'pointer' }}
        >
          {title}
        </Box>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexGrow: '1',
          lineHeight: '1rem',
          wordBreak: 'break-word',
        }}
      >
        {date && (
          <Fragment>
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              sx={{ fontSize: '0.8rem' }}
            >
              {date}
            </Typography>
            <Box sx={{ mx: 1, py: '3px' }}>
              <Divider
                orientation="vertical"
              />
            </Box>
          </Fragment>
        )}
        {source && (
          <Typography
            component="span"
            variant="body2"
            color="text.neutral"
            sx={{ fontSize: '0.8rem' }}
          >
            {source}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

DocumentForWidgetList.propTypes = {
  qesdocument: qesdocumentPropType,
  handleDocumentClick: PropTypes.func.isRequired,
};

export default DocumentForWidgetList;
