import React, { lazy, useRef } from 'react';
import {
  Switch,
  Redirect,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'i18n';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from '@mui/material';
import {
  Clear,
} from '@mui/icons-material';
import { SnackbarUtilsConfigurator } from 'generic/utils/snackbar';
import configureStore, { history } from 'generic/core/store';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';

import AlertContainer from 'generic/containers/AlertContainer';
import AlertsContainer from 'generic/containers/AlertsContainer';
import AnRRoutesWrapper from 'generic/containers/anr/RoutesWrapper';
import CartsContainer from 'generic/containers/CartsContainer';
import CartContainer from 'generic/containers/CartContainer';
import ConfigWrapper from 'generic/containers/ConfigWrapper';
import ConfirmDialogContainer from 'generic/containers/ConfirmDialogContainer';
import LogoutContainer from 'generic/containers/auth/LogoutContainer';
import MailingListsContainer from 'generic/containers/MailingListsContainer';
import NetworkGraphWrapper from 'generic/components/dashboard-items/highchart-extensions/NetworkGraphWrapper';
import NewslettersContainer from 'generic/containers/NewslettersContainer';
import NewsletterContainer from 'generic/containers/NewsletterContainer';
import ProfileContainer from 'generic/containers/ProfileContainer';
import ProtectedRoutes from 'generic/components/routes/ProtectedRoutes';
import PublicRoutes from 'generic/components/routes/PublicRoutes';
import SchedulesContainer from 'generic/containers/SchedulesContainer';
import ScheduleContainer from 'generic/containers/ScheduleContainer';
import UsersContainer from 'generic/containers/UsersContainer';
import UsersMailingListContainer from 'generic/containers/UsersMailingListContainer';

import { CONSTANTS } from 'generic/core/constants';
import { overrideRessource } from 'generic/utils/utils';
import CollectionsContainer from 'generic/containers/CollectionsContainer';
import CollectionsMonitoringContainer from 'generic/containers/CollectionsMonitoringContainer';

const AuthenticationLanding = overrideRessource('components/pages/AuthenticationLanding');

const ResultsHOC = lazy(() => import('generic/containers/ResultsHOC'));
const GEDContainerHOC = lazy(() => import('generic/components/pages/GEDContainerHOC'));

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsOfflineExporting(Highcharts);

const store = configureStore();
const { MAIN_PAGE } = CONSTANTS;

const snackbarClearButton = (ref) => (key) => (
  <Button variant="text" onClick={() => { ref.current.closeSnackbar(key); }}>
    <Clear />
  </Button>
);

function App() {
  const notistackRef = useRef();
  const { t } = useTranslation();

  /*
    stabilityCheck="never" sur le Provider Redux permet d'éviter le genre de warning suivant :
    "Selector unknown returned a different result when called with the same parameters.
    This can lead to unnecessary rerenders."
    Si jamais on utilise trop de useSelector qui renvoient à chaque fois des nouvelles références
    (vers des objets ou des tableaux) et que ça provoque des problèmes de perf,
    il faudra peut-être s'intéresser à reselect pour memoizer des useSelector
    afin d'éviter des re-renders inutiles.
    Cf. https://react-redux.js.org/api/hooks#using-memoizing-selectors
  */
  return (
    <Provider store={store} stabilityCheck="never">
      <ConfigWrapper>
        <NetworkGraphWrapper />
        <ConfirmDialogContainer />
        <SnackbarProvider
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          ref={notistackRef}
          action={snackbarClearButton(notistackRef)}
        >
          <SnackbarUtilsConfigurator />
          <ConnectedRouter history={history}>
            <Switch>
              { /* protégé */}
              <ProtectedRoutes
                path="/search/:resultsPath/:baseId?/:dashboardId?"
                keepFormConfig
                exact={false}
                title={t('route.results')}
                component={ResultsHOC}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/ged/:baseId?/:id?"
                keepFormConfig
                component={GEDContainerHOC}
                title={t('route.article')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/anr"
                exact={false}
                component={AnRRoutesWrapper}
                title={t('route.anr')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/monitoring"
                component={CollectionsMonitoringContainer}
                title={t('route.imports_monitoring')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/collections"
                component={CollectionsContainer}
                title={t('route.collections')}
              />
              <ProtectedRoutes
                path="/carts/:id"
                component={CartContainer}
                title={t('route.carts')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/carts"
                component={CartsContainer}
                title={t('route.carts')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/newsletters/:id"
                component={NewsletterContainer}
                title={t('route.newsletters')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/newsletters"
                component={NewslettersContainer}
                title={t('route.newsletters')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/users-mailing-list"
                component={UsersMailingListContainer}
                title={t('route.users_mailing_list')}
              />
              <ProtectedRoutes
                path="/alerts/:id"
                component={AlertContainer}
                title={t('route.alerts')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/alerts"
                component={AlertsContainer}
                title={t('route.alerts')}
                needsAnActiveCollection
              />
              <ProtectedRoutes
                path="/mailing-lists/"
                component={MailingListsContainer}
                title={t('route.mailing_lists')}
              />
              <ProtectedRoutes
                path="/users"
                component={UsersContainer}
                title={t('route.users')}
              />
              <ProtectedRoutes
                path="/profile"
                component={ProfileContainer}
                title={t('route.profile')}
              />
              <ProtectedRoutes
                path="/schedules/:id"
                component={ScheduleContainer}
                title={t('route.schedule_show')}
              />
              <ProtectedRoutes
                path="/schedules"
                component={SchedulesContainer}
                title={t('route.schedules')}
              />
              <ProtectedRoutes
                path="/logout"
                component={LogoutContainer}
              />

              { /* non accessible si loggué */}
              <PublicRoutes
                exact={false}
                path="/login"
                component={AuthenticationLanding}
                title={t('route.app_name')}
              />

              { /* Default */}
              <Redirect
                to={MAIN_PAGE}
              />
            </Switch>
          </ConnectedRouter>
        </SnackbarProvider>
      </ConfigWrapper>
    </Provider>
  );
}

export default App;
