import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  Box,
  Drawer,
  Typography,
  Divider,
  Tabs,
  Tab,
  Card,
  CardMedia,
} from '@mui/material';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';

import ResultSingleActions from 'generic/components/ui/ResultSingleAction';
import CommentsContainer from 'generic/containers/CommentsContainer';
import MoreLikeThis from 'generic/components/ui/MoreLikeThis';
import MediaWithTranscription from 'generic/components/ui/MediaWithTranscription';

const { ISOLATE_PDF, ISOLATE_TRANSCRIPTION } = QES_CONSTANTS;

const Document = overrideRessource('components/documents/Document');
const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const ResultsComplete = ({
  activeBaseId,
  activeUserId,
  handleClose,
  handleToggleAnnotatedTextDisplay,
  isAnnotatedTextDisplayed,
  handleToggleTranslatedTextDisplay,
  isTranslatedTextDisplayed,
  patchDocumentInComplete,
  titleFieldName,
  variant,
  canAddToCart = false,
  canAddToNewsletter = false,
  canEditInGED = false,
  canFastEditInGED = false,
  canDeleteDocument = false,
  canSendToFriend = false,
  canSummarize = false,
  canComment = false,
  disableTagsRefine = false,
  withMoreLikeThis = true,
  showButtons = false,
  resultsComplete = { documents: [] },
  openFastGed = _.noop,
}) => {
  const { t } = useTranslation();
  const shouldComputeTranscription = ISOLATE_TRANSCRIPTION;
  const {
    attachedFileURL,
    frames,
    isVideo,
    textTranslated,
    transcription,
    isPdf,
  } = useDocumentFields(resultsComplete.documents[0], shouldComputeTranscription);
  let defaultTabValue = 'document';
  if (ISOLATE_TRANSCRIPTION && !_.isEmpty(transcription)) {
    defaultTabValue = 'transcription';
  }
  const [currentTab, setCurrentTab] = useState(defaultTabValue);
  const handleCurrentTab = useCallback(
    (event, newValue) => { setCurrentTab(newValue); },
    [setCurrentTab],
  );

  const shouldDisplayTabs = withMoreLikeThis
  || canComment
  || (ISOLATE_TRANSCRIPTION && !_.isEmpty(transcription))
  || (ISOLATE_PDF && isPdf);
  return (
    <Drawer
      anchor="right"
      open
      variant={variant}
      onClose={handleClose}
      sx={variant === 'permanent' ? { flex: '1 1 auto', display: 'flex', overflow: 'hidden' } : {}}
      PaperProps={{
        sx: {
          width: { xs: '85%', md: '70%', lg: '60%' },
          position: variant === 'permanent' ? 'relative' : 'fixed',
        },
      }}
    >
      {!_.isEmpty(resultsComplete.documents) && (
        <List
          dense
          sx={{
            ml: 0,
            pt: 0,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            flexGrow: '1',
          }}
        >
          {
            resultsComplete.documents.map(
              (document, index) => {
                const title = _.get(
                  document.qesdocument,
                  `${titleFieldName}[0]`,
                  [`[${t('document.title_undefined')}]`],
                );
                return (
                  <ListItem
                    key={document.documentitem}
                    sx={{
                      pt: 0,
                      pb: 0,
                      px: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      alignSelf: 'flex-start',
                      overflow: 'auto',
                      flexGrow: '1',
                    }}
                  >
                    {index > 0 && (
                      <Divider sx={{ mx: 2 }} />
                    )}
                    <Box
                      className="stickyHeader"
                      sx={{
                        px: 2,
                        py: 1,
                        zIndex: '1',
                        width: '100%',
                      }}
                    >
                      <Typography variant="h5">
                        {title}
                      </Typography>
                      {showButtons && (
                        <Box sx={{ py: 0.5 }}>
                          <ResultSingleActions
                            canAddToCart={canAddToCart}
                            canAddToNewsletter={canAddToNewsletter}
                            canEditInGED={canEditInGED}
                            canFastEditInGed={canFastEditInGED}
                            canDeleteDocument={canDeleteDocument}
                            canSendToFriend={canSendToFriend}
                            canSummarize={canSummarize}
                            document={document}
                            handleOpenFastGed={openFastGed}
                            visible
                            isCompleteDisplay
                          />
                        </Box>
                      )}
                    </Box>
                    {shouldDisplayTabs && (
                      <Tabs
                        allowScrollButtonsMobile
                        onChange={handleCurrentTab}
                        scrollButtons="auto"
                        sx={{ mb: 2, mx: 2 }}
                        value={currentTab}
                        variant="scrollable"
                      >
                        {ISOLATE_TRANSCRIPTION && !_.isEmpty(transcription) && (
                          <Tab
                            label={t('results.transcription')}
                            sx={{ textTransform: 'none' }}
                            value="transcription"
                          />
                        )}
                        <Tab label={t('results.document_one')} sx={{ textTransform: 'none' }} value="document" />
                        {ISOLATE_PDF && isPdf && (
                          <Tab
                            label={t('results.pdf')}
                            value="pdf"
                          />
                        )}
                        {withMoreLikeThis && (
                          <Tab
                            label={t('results.morelikethis.show_see_also')}
                            sx={{ textTransform: 'none' }}
                            value="moreLikeThis"
                          />
                        )}
                        {canComment && (
                          <Tab
                            label={(document.commentaires.length > 0)
                              ? t('results.comments.show_comments', { count: document.commentaires.length })
                              : t('results.comments.comment')}
                            sx={{ textTransform: 'none' }}
                            value="comments"
                          />
                        )}
                      </Tabs>
                    )}
                    {ISOLATE_TRANSCRIPTION && !_.isEmpty(transcription) && (
                      <Box sx={{
                        display: currentTab === 'transcription' ? 'flex' : 'none',
                        pl: 2,
                        flexDirection: 'column',
                        flexGrow: '1',
                        width: '100%',
                        overflow: 'auto',
                        wordBreak: 'break-word',
                      }}
                      >
                        <MediaWithTranscription
                          activeBaseId={activeBaseId}
                          activeUserId={activeUserId}
                          displayTranslatedSentences={!_.isEmpty(textTranslated) && isTranslatedTextDisplayed}
                          documentId={document.idext}
                          frames={frames}
                          mediaType={isVideo ? 'video' : 'audio'}
                          mediaUrl={attachedFileURL}
                          patchDocumentInComplete={patchDocumentInComplete}
                          transcription={transcription}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: currentTab === 'document' ? 'flex' : 'none',
                        flexDirection: 'column',
                        width: '100%',
                        flexGrow: 1,
                        pl: 2,
                      }}
                    >
                      <Document
                        isAnnotatedTextDisplayed={isAnnotatedTextDisplayed}
                        isCompleteDisplay
                        disableTagsRefine={disableTagsRefine}
                        document={document}
                        displayTitle={false}
                        patchDocumentInComplete={patchDocumentInComplete}
                        handleToggleAnnotatedTextDisplay={handleToggleAnnotatedTextDisplay}
                        isTranslatedTextDisplayed={isTranslatedTextDisplayed}
                        handleToggleTranslatedTextDisplay={handleToggleTranslatedTextDisplay}
                        activeBaseId={activeBaseId}
                        activeUserId={activeUserId}
                      />
                    </Box>
                    {ISOLATE_PDF && isPdf && (
                      <Box
                        sx={{
                          display: currentTab === 'pdf' ? 'flex' : 'none',
                          flexDirection: 'column',
                          width: '100%',
                          flexGrow: 1,
                          pl: 2,
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                          <Card
                            sx={{
                              width: '90%',
                              mt: 2,
                              mb: 0,
                              flexShrink: 0,
                            }}
                          >
                            <CardMedia
                              sx={{
                                '&.MuiCardMedia-root': {
                                  minHeight: '500px',
                                  height: '100%',
                                },
                              }}
                              component="iframe"
                              controls
                              src={attachedFileURL}
                            />
                          </Card>
                        </Box>
                      </Box>
                    )}
                    {withMoreLikeThis && (
                      <Box
                        sx={{
                          display: currentTab === 'moreLikeThis' ? 'flex' : 'none',
                          flexDirection: 'column',
                          width: '100%',
                          px: 2,
                        }}
                      >
                        <MoreLikeThis
                          qesdocuments={document.morelikethis}
                          loading={document.loadingMoreLikeThis}
                          moreLikeThisIsVisible
                          showSeeAlsoLabel={false}
                        />
                      </Box>
                    )}
                    {canComment && (
                      <Box
                        sx={{
                          display: currentTab === 'comments' ? 'flex' : 'none',
                          flexDirection: 'column',
                          width: '100%',
                          p: 2,
                        }}
                      >
                        <CommentsContainer
                          document={document}
                          commentIsVisible
                          autoFocusInput={false}
                        />
                      </Box>
                    )}
                  </ListItem>
                );
              },
            )
          }
        </List>
      )}
    </Drawer>
  );
};

ResultsComplete.propTypes = {
  activeBaseId: PropTypes.number.isRequired,
  activeUserId: PropTypes.number.isRequired,
  canAddToCart: PropTypes.bool,
  canAddToNewsletter: PropTypes.bool,
  canComment: PropTypes.bool,
  canDeleteDocument: PropTypes.bool,
  canEditInGED: PropTypes.bool,
  canFastEditInGED: PropTypes.bool,
  canSendToFriend: PropTypes.bool,
  canSummarize: PropTypes.bool,
  disableTagsRefine: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleToggleAnnotatedTextDisplay: PropTypes.func.isRequired,
  handleToggleTranslatedTextDisplay: PropTypes.func.isRequired,
  isAnnotatedTextDisplayed: PropTypes.bool.isRequired,
  isTranslatedTextDisplayed: PropTypes.bool.isRequired,
  openFastGed: PropTypes.func,
  patchDocumentInComplete: PropTypes.func.isRequired,
  resultsComplete: PropTypes.shape(),
  showButtons: PropTypes.bool,
  titleFieldName: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['temporary', 'permanent']).isRequired,
  withMoreLikeThis: PropTypes.bool,
};

export default ResultsComplete;
