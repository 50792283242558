import React from 'react';
import PropTypes from 'prop-types';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

const RichTextEditor = ({ data, onChange, ...restProps }) => {
  const handleEditorChange = (event, editor) => {
    const updatedHtml = editor.getData();
    onChange(updatedHtml);
  };

  const defaultConfig = {
    // Configuration par défaut de l'éditeur
  };

  const editorConfig = {
    ...defaultConfig,
    ...restProps,
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onChange={handleEditorChange}
      config={editorConfig}
      {...restProps}
    />
  );
};

RichTextEditor.propTypes = {
  data: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RichTextEditor;
