import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import Highmaps from 'highcharts/highmaps';
import worldMap from '@highcharts/map-collection/custom/world.geo.json';
import proj4 from 'proj4';
import THEME_CONFIG from 'generic/core/theme';
import { useTheme } from '@mui/material';

if (typeof window !== 'undefined') {
  window.proj4 = window.proj4 || proj4;
}

HighchartsNoData(Highmaps);
HighchartsExporting(Highmaps);
HighchartsExportData(Highmaps);
HighchartsOfflineExporting(Highmaps);

const Map = ({ highchartsOptions }) => {
  const theme = useTheme();
  Highmaps.setOptions(theme.palette.mode === 'dark' ? THEME_CONFIG.DARK.HIGHCHARTS : THEME_CONFIG.LIGHT.HIGHCHARTS);

  const defaultOptions = {
    highcharts: Highmaps,
    constructorType: 'mapChart',
    options: {
      chart: {
        map: worldMap,
        exporting: {
          enabled: true,
        },
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          alignTo: 'spacingBox',
          align: 'right',
        },
      },
      colorAxis: {
        min: 0,
        type: 'linear',
        minColor: Highcharts.getOptions().colors.at(0),
        maxColor: Highcharts.getOptions().colors.at(-1),
        stops: Highcharts.getOptions().colors.map((c, i, arr) => (
          [(i / arr.length), c]
        )),
        labels: {
          style: {
            color: 'text',
          },
        },
      },
      credits: { enabled: false },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        map: {
          nullColor: theme.palette.mode === 'dark' ? '#555555' : '#f7f7f7',
        },
        exporting: {
          enabled: true,
        },
        series: {
          borderColor: theme.palette.mode === 'dark' ? '#aaaaaa' : '#cccccc',
          keys: ['name', 'value'],
          joinBy: ['iso-a2', 'name'],
          states: {
            hover: { color: '#BADA55' },
          },
          tooltip: {
            headerFormat: '',
          },
        },
      },
    },
  };

  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
    />
  );
};

Map.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default Map;
