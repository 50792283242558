import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleWare from 'redux-saga';

import actions from 'generic/core/actions/reducer';
import alerts from 'generic/core/alerts/reducer';
import anr from 'generic/core/anr/reducer';
import auth from 'generic/core/auth/reducer';
import carts from 'generic/core/carts/reducer';
import collections from 'generic/core/collections/reducer';
import comments from 'generic/core/comment/reducer';
import config from 'generic/core/config/reducer';
import confirmDialog from 'generic/core/confirmDialog/reducer';
import dashboard from 'generic/core/dashboard/reducer';
import exportReducer from 'generic/core/export/reducer';
import files from 'generic/core/files/reducer';
import ged from 'generic/core/ged/reducer';
import mailingLists from 'generic/core/mailingLists/reducer';
import newsletters from 'generic/core/newsletters/reducer';
import rootSagas from 'generic/core/rootSagas';
import runSaga from 'generic/core/configureSaga';
import schedules from 'generic/core/schedules/reducer';
import search from 'generic/core/search/reducer';
import selection from 'generic/core/selection/reducer';
import sendToFriend from 'generic/core/sendToFriend/reducer';
import summarize from 'generic/core/summarize/reducer';
import users from 'generic/core/users/reducer';
import ux from 'generic/core/ux/reducer';
import { CUSTOM_PATH } from 'generic/core/constants';

const sagaMiddleware = createSagaMiddleWare();

export const history = createBrowserHistory();

const reducers = {
  actions,
  alerts,
  anr,
  auth,
  carts,
  collections,
  comments,
  config,
  confirmDialog,
  dashboard,
  exportReducer,
  files,
  ged,
  mailingLists,
  newsletters,
  schedules,
  search,
  selection,
  sendToFriend,
  summarize,
  users,
  ux,
};

if (CUSTOM_PATH) {
  const isDev = (process.env.NODE_ENV === 'development');
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: customReducer } = require(`custom/${CUSTOM_PATH}/core/redux/reducer`);
    if (isDev) {
      console.info(`CUSTOM PATH LOADED : custom/${CUSTOM_PATH}/core/redux/reducer`);
    }
    if (customReducer) {
      reducers[CUSTOM_PATH] = customReducer;
    }
  } catch (err) {
    _.noop();
  }
}

const rootReducer = (_history) => combineReducers({
  router: connectRouter(_history),
  ...reducers,
});

const middlewares = [routerMiddleware(history), sagaMiddleware];

export default function configureAppStore(preloadedState = {}) {
  const store = configureStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: { warnAfter: 50 },
    }).concat(middlewares),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production' ? { trace: true, traceLimit: 25 } : false,
  });

  runSaga(sagaMiddleware, rootSagas);

  return store;
}
