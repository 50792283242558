import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      series: {
        animation: false,
        cursor: 'pointer',
      },
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: { type: 'category', gridLineWidth: 0 },
    chart: { type: 'bar' },
  },
};

const Bar = ({ highchartsOptions }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
    />
  );
};

Bar.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default Bar;
