import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Comments from 'generic/components/ui/Comments';
import { deleteComment, createComment, updateCommentAlert } from 'generic/core/comment/actions';

const CommentsContainer = ({
  document = {},
  commentIsVisible = false,
  autoFocusInput = true,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.config.user);
  const deleteLoading = useSelector((state) => state.comments.deleteLoading);
  const submitLoading = useSelector((state) => state.comments.submitLoading);
  const alertChecked = document.commentAlert;
  const { hasComment } = document;

  const initialValues = {
    comment: '',
    user_tagged: '',
  };

  const params = {
    base: document.base,
    article: document.idext,
  };

  const handleSubmit = (values) => {
    params.comment = values.comment;
    params.user_tagged = values.user_tagged;
    dispatch(createComment(params));
  };

  const handleDeleteComment = (comment) => {
    params.comment = comment.full_comment;
    dispatch(deleteComment(params));
  };

  const handleToggleCheckbox = (event) => {
    params.alert = event.target.checked;
    dispatch(updateCommentAlert(params));
  };

  if (!commentIsVisible) {
    return null;
  }
  return (
    <Comments
      user={user}
      comments={document.commentaires}
      alertChecked={alertChecked}
      hasComment={hasComment}
      handleToggleCheckbox={handleToggleCheckbox}
      deleteLoading={deleteLoading}
      submitLoading={submitLoading}
      initialValues={initialValues}
      autoFocusInput={autoFocusInput}
      handleSubmit={handleSubmit}
      handleDeleteComment={handleDeleteComment}
    />
  );
};

CommentsContainer.propTypes = {
  document: PropTypes.shape(),
  commentIsVisible: PropTypes.bool,
  autoFocusInput: PropTypes.bool,
};

export default CommentsContainer;
