import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  collect, types,
} from 'generic/core/anr/actions';
import { toggleCheckboxAll } from 'generic/core/selection/actions';
import BulkArticlesActions from 'generic/components/ui/anr/BulkArticlesActions';
import { openDialogAddToCart, openDialogAddToNewsletter, openDialogSendToFriend } from 'generic/core/actions/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { checkIfItemHasQuery } from 'generic/utils/anrUtils';

const BulkArticlesActionsContainer = ({ shrinkMenu = false }) => {
  const activeItem = useSelector((state) => state.anr.activeItem);
  const loading = useSelector((state) => state.anr.resultsLoading);
  const articles = useSelector((state) => state.anr.articles);
  const checkedItems = useSelector((state) => state.selection.checkedItems);
  const collectLoading = useSelector((state) => state.anr.collectLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasQuery = checkIfItemHasQuery(activeItem);

  const handleToggleCheckboxAll = (event) => {
    const items = articles.reduce((accumulateur, article) => {
      accumulateur[article.id] = article;
      return accumulateur;
    }, {});

    dispatch(toggleCheckboxAll({
      checked: event.target.checked,
      items,
    }));
  };

  const handleCollectCheckedItems = () => {
    dispatch(collect());
  };

  const handleOpenDeleteArticlesDialog = () => {
    dispatch(setConfirmDialogData({
      title: t('anr.actions.delete'),
      textButton: 'form.delete',
      message: (
        <Fragment>
          {t('anr.actions.delete_sure')}
          &nbsp;
          <b>{_.values(checkedItems).length}</b>
          &nbsp;
          {t('anr.item', { count: _.values(checkedItems).length })}
          &nbsp;?
        </Fragment>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_ARTICLES_SUCCESS, types.DELETE_ARTICLES_ERROR],
      action: {
        type: types.DELETE_ARTICLES,
        articles: _.values(checkedItems),
      },
    }));
  };

  const hasAllArticlesInListChecked = !_.isEmpty(articles) && _.every(articles, (article) => (
    Object.prototype.hasOwnProperty.call(checkedItems, article.id)
  ));
  const hasSomeArticlesInListChecked = !_.isEmpty(articles) && _.some(articles, (article) => (
    Object.prototype.hasOwnProperty.call(checkedItems, article.id)
  ));
  const hasUnvalidatedChecked = !_.isEmpty(_.filter(_.values(checkedItems), (item) => isEmpty(item.validation)));
  return (
    <BulkArticlesActions
      collectLoading={collectLoading}
      handleOpenAddToCartDialog={() => dispatch(openDialogAddToCart())}
      handleOpenAddToNewsletterDialog={() => dispatch(openDialogAddToNewsletter())}
      handleOpenSendToFriend={() => dispatch(openDialogSendToFriend())}
      handleCollectCheckedItems={handleCollectCheckedItems}
      handleOpenDeleteArticlesDialog={handleOpenDeleteArticlesDialog}
      handleToggleCheckboxAll={handleToggleCheckboxAll}
      hasAllArticlesInListChecked={hasAllArticlesInListChecked}
      hasCheckedItems={!_.isEmpty(checkedItems)}
      hasSomeArticlesInListChecked={hasSomeArticlesInListChecked}
      hasUnvalidatedChecked={hasUnvalidatedChecked}
      loading={loading && hasQuery}
      shrinkMenu={shrinkMenu}
    />
  );
};

BulkArticlesActionsContainer.propTypes = {
  shrinkMenu: PropTypes.bool,
};

export default BulkArticlesActionsContainer;
