import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      series: {
        animation: false,
      },
    },
    chart: { type: 'spline', zoomType: 'x' },
    credits: { enabled: false },
    title: { text: 'Titre Spline' },
    legend: {
      enabled: true,
    },
    xAxis: {
      crosshair: true,
      title: {
        text: 'Date',
      },
      type: 'datetime',
    },
  },
};

const Spline = ({ highchartsOptions }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
    />
  );
};

Spline.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default Spline;
