import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { summarize } from 'generic/core/summarize/actions';
import { clearSelection, clearSingleItem } from 'generic/core/selection/actions';
import { closeSidenavSummarize } from 'generic/core/actions/actions';
import Summarize from 'generic/components/ui/Summarize';

const SummarizeContainer = ({
  scope,
  hasDrawer = false,
  options = {
    cartId: 1000,
    idDocs: [],
  },
}) => {
  const open = useSelector((state) => state.actions.sidenavSummarizeOpened);
  const building = useSelector((state) => state.summarize.building);
  const summary = useSelector((state) => state.summarize.summary);
  const dispatch = useDispatch();

  const handleCloseSummarize = () => {
    if (scope === 'cart') {
      dispatch(clearSelection());
    } else if (scope === 'results') {
      dispatch(clearSingleItem());
    }
    dispatch(closeSidenavSummarize());
  };

  useEffect(() => {
    if (open && !building) {
      dispatch(summarize(scope, options));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Summarize
      handleCloseSummarize={handleCloseSummarize}
      building={building}
      hasDrawer={hasDrawer}
      summary={summary}
    />
  );
};

SummarizeContainer.propTypes = {
  scope: PropTypes.oneOf(['results', 'anr', 'cart']).isRequired,
  hasDrawer: PropTypes.bool,
  options: PropTypes.shape({
    cartId: PropTypes.number.isRequired,
    idDocs: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
};

export default SummarizeContainer;
