import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import { alertPropType } from 'generic/core/qes/proptypes';
import {
  Field,
  Form,
  Formik,
} from 'formik';
import { Select, TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import PageBackAndTitle from 'generic/components/ui/PageBackAndTitle';
import FormSearchAdvanced from 'generic/components/forms/FormSearchAdvanced';
import QES_CONSTANTS from 'generic/core/qes/constants';
import AutocompleteAjax from 'generic/components/forms/AutocompleteAjax';
import { doAutocompleteEmail } from 'generic/api/users';

const { ALERT } = QES_CONSTANTS;

const Alert = ({
  alert,
  handleSave,
  handleClickSearch,
  handleClickActivateDeactivate,
  saveAlertLoading = false,
  formInitialValues = {},
  fieldsAdvanced = [],
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const handleCurrentTab = useCallback(
    (event, newValue) => { setCurrentTab(newValue); },
    [setCurrentTab],
  );

  const launchSearch = useCallback(
    () => handleClickSearch(alert.veille),
    [handleClickSearch, alert.veille],
  );

  const bases = [];

  const initialValues = {
    veille: alert.veille,
    libelle: alert.libelle,
    date_creation: new Date(alert.date_creation),
    type_veille: alert.type_veille,
    mode_veille: alert.mode_veille,
    format_veille: alert.format_veille,
    email: alert.email ? _.split(alert.email, ',') : [],
    peremption_veille: alert.peremption_veille,
    mode: alert.peremption_veille === 1000 ? 1 : 2,
  };

  const typeOptions = Object.values(ALERT.TYPES).map((id) => ({ code: id, libelle: t(`alerts.type_values.${id}`) }));

  const modeOptions = alert?.mode_veilles
    ? alert.mode_veilles.map((id) => ({ code: id, libelle: t(`alerts.mode_values.${id}`) }))
    : [];

  const formatOptions = alert?.format_veilles
    ? alert.format_veilles.map((id) => ({ code: id, libelle: t(`alerts.format_values.${id}`) }))
    : [];

  const handleGetOptionLabel = (userObjectOrEmail) => {
    if (typeof userObjectOrEmail === 'string') return userObjectOrEmail;
    const lastname = _.get(userObjectOrEmail, 'nom', '');
    const firstname = _.get(userObjectOrEmail, 'prenom', '');
    return _.compact([firstname, lastname]).join(' ');
  };

  const handleToggleStatus = () => {
    let nextStatus;
    if (ALERT.STATUSES.ACTIVE.includes(alert.etat_veille)) {
      nextStatus = ALERT.STATUSES.DEFAULT_INACTIVE;
    } else {
      nextStatus = ALERT.STATUSES.DEFAULT_ACTIVE;
    }

    handleClickActivateDeactivate(alert.veille, nextStatus);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
      className="desktopOnlyOverflow"
    >
      <Box
        className="stickyHeader"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          zIndex: '1200',
        }}
      >
        <PageBackAndTitle
          backLink="/alerts"
          backBtnTitle={t('carts.back_to_list')}
          title={alert.libelle}
        />
        <Tabs
          allowScrollButtonsMobile
          onChange={handleCurrentTab}
          scrollButtons="auto"
          sx={{ px: 1 }}
          value={currentTab}
          variant="scrollable"
        >
          <Tab label={t('alerts.details')} />
          <Tab label={t('alerts.criteria')} />
        </Tabs>
      </Box>
      <Box sx={{ display: 'flex', flexGrow: '1' }} className="desktopOnlyOverflow">
        <Box
          className="desktopOnlyOverflow"
          sx={{
            display: currentTab === 0 ? 'flex' : 'none',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              handleSave(values);
              setSubmitting(false);
            }}
            enableReinitialize
            validateOnMount
          >
            {(form) => (
              <Form
                className={clsx('displayFlex', 'flexGrow1', 'flexDirectionColumn', 'desktopOnlyOverflow')}
              >
                <Box
                  className="desktopOnlyOverflow"
                  sx={{
                    flexGrow: '1',
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      mt: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: 2,
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Field
                        component={TextField}
                        label={t('alerts.name')}
                        autoFocus
                        name="libelle"
                        color="primary"
                      />
                    </Box>
                    <Box sx={{ width: 210 }}>
                      <Field
                        component={DatePicker}
                        inputFormat="dd/MM/yyyy"
                        label={t('alerts.date_of_creation')}
                        name="date_creation"
                        disabled
                        color="primary"
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      mt: 1,
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{ width: 210 }}
                    >
                      <Field
                        component={Select}
                        label={t('alerts.type')}
                        name="type_veille"
                        // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                        // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                        // override donc juste le onClose pour qu'il ne fasse rien.
                        onClose={_.noop}
                      >
                        {
                          _.map(typeOptions, (option) => (
                            <MenuItem key={option.code} value={option.code}>
                              {option.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                    </Box>

                    <Box width={210}>
                      <Field
                        component={TextField}
                        label={t('alerts.status')}
                        name="etat_veille"
                        disabled
                        color="primary"
                        defaultValue={t(`alerts.statuses.${alert.etat_veille}`)}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      mt: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      className="formFieldset"
                      sx={{ p: 2, pt: 1 }}
                      disabled={form.values.type_veille !== ALERT.TYPES.ALERT}
                    >
                      <FormLabel component="legend">
                        {t('alerts.type_values.1')}
                      </FormLabel>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{ width: 210 }}
                        >
                          <Field
                            component={Select}
                            label={t('alerts.periodicity')}
                            name="mode_veille"
                            // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                            // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                            // override donc juste le onClose pour qu'il ne fasse rien.
                            onClose={_.noop}
                            disabled={form.values.type_veille !== ALERT.TYPES.ALERT}
                          >
                            {_.map(modeOptions, (option) => (
                              <MenuItem key={option.code} value={option.code}>{option.libelle}</MenuItem>
                            ))}
                          </Field>
                        </Box>
                        <Box
                          sx={{ width: 400 }}
                        >
                          <Field
                            component={Select}
                            label={t('alerts.format_reception')}
                            name="format_veille"
                            // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                            // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                            // override donc juste le onClose pour qu'il ne fasse rien.
                            onClose={_.noop}
                            disabled={form.values.type_veille !== ALERT.TYPES.ALERT}
                          >
                            {_.map(formatOptions, (option) => (
                              <MenuItem key={option.code} value={option.code}>{option.libelle}</MenuItem>
                            ))}
                          </Field>
                        </Box>
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Field
                          component={AutocompleteAjax}
                          name="email"
                          multiple
                          freeSolo
                          highlight={false}
                          doAutocompleteOverride={doAutocompleteEmail}
                          getOptionLabel={handleGetOptionLabel}
                          fullWidth
                          textFieldProps={{
                            label: t('alerts.email'),
                          }}
                          isDisabled={form.values.type_veille !== ALERT.TYPES.ALERT}
                        />
                      </Box>
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  className="bottomBtnsContainer"
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ justifyContent: 'center' }}
                  >
                    <Button
                      color="primary"
                      onClick={handleToggleStatus}
                    >
                      {
                        ALERT.STATUSES.ACTIVE.includes(alert.etat_veille)
                          ? t('alerts.deactivate')
                          : t('alerts.activate')
                      }
                    </Button>
                    <Button
                      color="primary"
                      variant="text"
                      onClick={launchSearch}
                    >
                      {t('form.search')}
                    </Button>
                    <Button
                      color="primary"
                      variant="text"
                      type="reset"
                      onClick={form.resetForm}
                    >
                      {t('form.reset')}
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={saveAlertLoading || !form.isValid}
                    >
                      {t('form.save')}
                    </Button>
                  </Stack>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
        <Box
          className="desktopOnlyOverflow"
          sx={{
            display: currentTab === 1 ? 'flex' : 'none',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Box
            className="desktopOnlyOverflow"
            sx={{
              flexGrow: '1',
              p: 2,
            }}
          >
            <FormSearchAdvanced
              activeBaseId={alert.base}
              activeBaseLabel={t('config.default_base_label')}
              bases={bases}
              loading={false}
              initialValues={formInitialValues}
              fieldsAdvanced={fieldsAdvanced}
              isVisible
              fullPage
              formDisabled
            />
          </Box>
          <Box
            className="bottomBtnsContainer"
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: 'center' }}
            >
              <Button
                color="primary"
                variant="text"
                onClick={launchSearch}
              >
                {t('form.search')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Alert.propTypes = {
  alert: alertPropType.isRequired,
  saveAlertLoading: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
  handleClickSearch: PropTypes.func.isRequired,
  handleClickActivateDeactivate: PropTypes.func.isRequired,
  formInitialValues: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
  fieldsAdvanced: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      libelle: PropTypes.string,
    }),
  ),
};

export default Alert;
