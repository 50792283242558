import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import {
  Add,
  Clear,
  Delete,
  Description,
  Edit,
  Folder,
  MoreVert,
  Search,
} from '@mui/icons-material';

import DropdownMenu from 'generic/components/ui/DropdownMenu';
import Corpus from 'generic/components/pages/Corpus';

const Collections = ({
  collections,
  idUser,
  inputSearch,
  hasCollectionOwner,
  hasCorpus,
  lastestActivedCorpora,
  totalCollectionsLength,
  handleActivateCollection,
  handleChangeInputSearch,
  handleOpenCollectionDialog,
  handleOpenDeleteCollectionConfirmDialog,
  handleOpenDialogUploadFiles,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const collectionsIsNotEmpty = (!_.isEmpty(collections)
  && (_.some(collections, { utilisateur: idUser })
    || _.some(collections, (collection) => _.some(collection.corpora, (corpus) => !_.isEmpty(corpus))))
  ) || !_.isEmpty(inputSearch);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2.5,
        width: '100%',
        p: 3,
        backgroundColor: 'background.special',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1, fontWeight: '500' }}>
          {t('route.home')}
        </Typography>
        {collectionsIsNotEmpty && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            {/* Champ de recherche */}
            <TextField
              placeholder={t('collections.search')}
              value={inputSearch}
              onChange={(event) => handleChangeInputSearch(event.target.value)}
              sx={{ margin: 0, maxWidth: '700px', flexBasis: '700px' }}
              InputProps={{
                sx: { backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#000000', height: '38px' },
                startAdornment: <Search />,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography color="text.neutral" sx={{ mr: 1 }}>
                      {`${_.flatMap(collections, 'corpora').length}/${totalCollectionsLength}`}
                    </Typography>
                    <IconButton disabled={_.isEmpty(inputSearch)} onClick={() => handleChangeInputSearch('')}>
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* Bouton pour créer une nouvelle collection ou un nouveau corpus */}
            <DropdownMenu
              btnTag="button"
              btnProps={{
                sx: { height: '38px' },
                startIcon: <Add fontSize="small" />,
                size: 'medium',
              }}
              btnChildren={t('ui.new')}
            >
              <MenuItem onClick={() => handleOpenCollectionDialog('collection', 'create')}>
                <Folder fontSize="small" color="primary" sx={{ mr: 1 }} />
                {t('collections.collection.create')}
              </MenuItem>
              <Tooltip title={(!hasCollectionOwner) ? t('collections.cannot_add_corpus') : ''} placement="right">
                <Box>
                  <MenuItem
                    disabled={!hasCollectionOwner}
                    onClick={() => handleOpenCollectionDialog('corpus', 'create')}
                  >
                    <Description fontSize="small" color="primary" sx={{ mr: 1 }} />
                    {t('collections.corpus.create')}
                  </MenuItem>
                </Box>
              </Tooltip>
              <Tooltip title={(!hasCorpus) ? t('collections.cannot_add_documents') : ''} placement="right">
                <Box>
                  <MenuItem
                    disabled={!hasCorpus}
                    onClick={() => handleOpenDialogUploadFiles()}
                  >
                    <Add fontSize="small" color="primary" sx={{ mr: 1 }} />
                    {t('collections.documents.add')}
                  </MenuItem>
                </Box>
              </Tooltip>
            </DropdownMenu>
          </Box>
        )}
      </Box>
      {collectionsIsNotEmpty
        ? (
          <Fragment>
            {/* Dernier corpus activé */}
            {!_.isEmpty(lastestActivedCorpora) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }} color="neutral">
                  {t('collections.lastest_activated')}
                </Typography>
                <Grid container spacing={3}>
                  {_.map(
                    lastestActivedCorpora,
                    (corpus) => (
                      <Grid key={corpus.collection} item sm={12} md={6} lg={3}>
                        <Corpus
                          corpus={corpus}
                          isOwner={corpus.utilisateur === idUser}
                          variant="light"
                          handleActivateCollection={handleActivateCollection}
                          handleOpenCollectionDialog={handleOpenCollectionDialog}
                          handleOpenDeleteCollectionConfirmDialog={handleOpenDeleteCollectionConfirmDialog}
                          handleOpenDialogUploadFiles={handleOpenDialogUploadFiles}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              </Box>
            )}
            {/* Toutes les collections */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                pb: 3,
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: '500' }} color="neutral">
                {t('collections.my_collections')}
              </Typography>
              {_.isEmpty(collections) ? (
                <Box sx={{ textAlign: 'center', mt: 4 }}>{t('collections.no_search_results')}</Box>
              ) : (
                <Grid container spacing={3}>
                  {_.map(
                    collections,
                    (collection) => {
                      let cannotAddMessage = '';
                      let cannotDeleteMessage = '';
                      if (collection.utilisateur !== idUser) {
                        cannotAddMessage = t('collections.cannot_add_not_owner');
                        cannotDeleteMessage = t('collections.cannot_delete_not_owner');
                      } else if (!_.isEmpty(collection.corpora)) {
                        cannotDeleteMessage = t('collections.cannot_delete_has_corpus');
                      }
                      return (!_.isEmpty(collection.corpora) || collection.utilisateur === idUser) && (
                        <Grid key={collection.collection} item sm={12} md={6} lg={3}>
                          <Card
                            sx={{
                              height: 300,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                              p: 2,
                            }}
                          >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                <Folder sx={{
                                  fontSize: '18px',
                                }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    overflow: 'hidden',
                                  }}
                                >
                                  {collection.libelle}
                                </Typography>
                              </Box>
                              <DropdownMenu
                                btnChildren={<MoreVert fontSize="small" />}
                                btnProps={{
                                  sx: { padding: '0px' },
                                }}
                              >
                                <MenuItem
                                  onClick={() => handleOpenCollectionDialog(
                                    'collection',
                                    'edit',
                                    {
                                      id: collection.collection,
                                      owner: collection.utilisateur_createur,
                                      visibility: collection.visibilite_collection,
                                      collaborators: collection.collaborators,
                                      libelle: collection.libelle,
                                      description: collection.description,
                                    },
                                  )}
                                >
                                  <Edit fontSize="small" color="primary" sx={{ mr: 1 }} />
                                  {collection.utilisateur !== idUser
                                    ? t('collections.collection.params') : t('collections.collection.edit')}
                                </MenuItem>
                                <Tooltip
                                  title={cannotAddMessage}
                                  placement="right"
                                >
                                  <Box>
                                    <MenuItem
                                      disabled={collection.utilisateur !== idUser}
                                      onClick={() => handleOpenCollectionDialog(
                                        'corpus',
                                        'create',
                                        { collection_parente: collection.collection },
                                      )}
                                    >
                                      <Description fontSize="small" color="primary" sx={{ mr: 1 }} />
                                      {t('collections.corpus.add')}
                                    </MenuItem>
                                  </Box>
                                </Tooltip>
                                <Tooltip
                                  title={cannotDeleteMessage}
                                  placement="right"
                                >
                                  <Box>
                                    <MenuItem
                                      disabled={collection.utilisateur !== idUser || !_.isEmpty(collection.corpora)}
                                      sx={{ color: 'error.main' }}
                                      onClick={
                                        () => handleOpenDeleteCollectionConfirmDialog(
                                          'collection',
                                          [collection.collection],
                                        )
                                      }
                                    >
                                      <Delete fontSize="small" color="error" sx={{ mr: 1 }} />
                                      {t('collections.collection.delete')}
                                    </MenuItem>
                                  </Box>
                                </Tooltip>
                              </DropdownMenu>
                            </Box>
                            {_.isEmpty(collection.corpora) ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: '10px',
                                  height: '100%',
                                  fontSize: '14px',
                                  color: 'primary.main',
                                  border: '2px dashed',
                                  borderColor: 'border.main',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleOpenCollectionDialog(
                                  'corpus',
                                  'create',
                                  {
                                    collection_parente: collection.collection,
                                    owner: collection.utilisateur_createur,
                                  },
                                )}
                              >
                                <Add fontSize="large" />
                                {t('collections.corpus.add')}
                              </Box>
                            ) : (
                              <Box sx={{ overflow: 'auto' }}>
                                {_.map(
                                  _.sortBy(collection.corpora, (corpus) => corpus.libelle.toLowerCase()),
                                  (corpus) => (
                                    <Corpus
                                      corpus={corpus}
                                      key={corpus.collection}
                                      isOwner={corpus.utilisateur === idUser}
                                      handleActivateCollection={handleActivateCollection}
                                      handleOpenCollectionDialog={handleOpenCollectionDialog}
                                      handleOpenDeleteCollectionConfirmDialog={handleOpenDeleteCollectionConfirmDialog}
                                      handleOpenDialogUploadFiles={handleOpenDialogUploadFiles}
                                    />
                                  ),
                                )}
                              </Box>
                            )}
                          </Card>
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              )}
            </Box>
          </Fragment>
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'text.neutral',
            }}
          >
            <Folder fontSize="medium" />
            {t('collections.no_results')}
            <Button
              color="primary"
              startIcon={<Add />}
              sx={{ mt: 2 }}
              onClick={() => handleOpenCollectionDialog('collection', 'create')}
            >
              {t('collections.collection.create')}
            </Button>
          </Box>
        )}
    </Box>
  );
};

Collections.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    collection: PropTypes.number,
    collection_parente: PropTypes.number,
    description: PropTypes.string,
    image: PropTypes.string,
    libelle: PropTypes.string,
    utilisateur: PropTypes.number,
  })).isRequired,
  hasCollectionOwner: PropTypes.bool.isRequired,
  hasCorpus: PropTypes.bool.isRequired,
  idUser: PropTypes.number.isRequired,
  inputSearch: PropTypes.string.isRequired,
  lastestActivedCorpora: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    collection: PropTypes.number,
    collection_parente: PropTypes.number,
    collection_parente_name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    libelle: PropTypes.string,
    utilisateur: PropTypes.number,
  })).isRequired,
  totalCollectionsLength: PropTypes.number.isRequired,
  handleActivateCollection: PropTypes.func.isRequired,
  handleChangeInputSearch: PropTypes.func.isRequired,
  handleOpenCollectionDialog: PropTypes.func.isRequired,
  handleOpenDeleteCollectionConfirmDialog: PropTypes.func.isRequired,
  handleOpenDialogUploadFiles: PropTypes.func.isRequired,
};

export default Collections;
