import React from 'react';
import {
  Box,
  Skeleton,
} from '@mui/material';

const ResultsCompleteSkeleton = () => (
  <Box
    sx={{
      width: '100%',
      mt: 0.5,
      mx: 2,
      overflow: 'hidden',
    }}
  >
    <Skeleton variant="text" width="100%" height={32} />
    <Skeleton variant="text" width="75%" height={32} />
    <Box
      sx={{
        display: 'flex',
        lineHeight: '1.5rem',
        gap: 0.5,
        mt: 1,
      }}
    >
      <Skeleton variant="circular" width={28} height={28} />
      <Skeleton variant="circular" width={28} height={28} />
      <Skeleton variant="circular" width={28} height={28} />
      <Skeleton variant="circular" width={28} height={28} />
      <Skeleton variant="circular" width={28} height={28} />
      <Skeleton variant="circular" width={28} height={28} />
    </Box>
    <Box sx={{ display: 'flex', mt: 1.5 }}>
      <Box sx={{ flexGrow: '1', lineHeight: '1.5rem' }}>
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="100%" height={26} />
        <Skeleton variant="text" width="75%" height={26} />
      </Box>
    </Box>
  </Box>
);

export default ResultsCompleteSkeleton;
