import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardMedia,
  FormControlLabel,
  FormGroup,
  Link,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AccountBalance,
  AccountCircle,
  Audiotrack,
  Label,
  Place,
  Videocam,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ExpandableTagList from 'generic/components/ui/ExpandableTagList';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';
import { documentPropType } from 'generic/core/qes/proptypes';
import JsxJoinerWithSeparator from 'generic/components/ui/JsxJoinerWithSeparator';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);
const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const Document = ({
  disableTagsRefine = false,
  displayTitle = true,
  document,
  handleGetDocumentComplete = null,
  handleToggleAnnotatedTextDisplay = null,
  isAnnotatedTextDisplayed = false,
  isCompleteDisplay = false,
  shrinkDocumentDisplay = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const {
    attachedFileURL,
    date,
    htmlWithAnnotations,
    linkout,
    pdf,
    sentences,
    snippet,
    source,
    status,
    text,
    thumb,
    title,
    isAudio,
    isVideo,
  } = useDocumentFields(document, isCompleteDisplay);

  const { qesdocument } = document;

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }
  return (
    <Box sx={{ display: 'block', width: '100%', wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontSize: '1.2rem',
            margin: '6px 0',
            display: 'block',
          }}
        >
          <Box
            {...titleLinkProps}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {title}
          </Box>
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ flexGrow: '1' }}>
          {!smallerThanMedium && thumb && (
            <Box sx={{ mr: 1, mb: '2px', float: 'left' }}>
              <ImgLoader
                src={thumb}
                fallbackComponent={(
                  <ImageNotFound
                    height={60}
                    width={100}
                  />
                )}
                height={60}
                width={100}
                loading="lazy"
              />
            </Box>
          )}
          <Box sx={{ lineHeight: '1.3rem' }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                mb: 0.5,
              }}
            >
              <JsxJoinerWithSeparator items={[
                date && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {date}
                  </Typography>
                ),
                !shrinkDocumentDisplay && status > 0 && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t(`ged.document.status.${status}`)}
                  </Typography>
                ),
                pdf && (
                  <Link
                    href={pdf}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      {pdf.split('.').pop().toUpperCase()}
                    </Typography>
                  </Link>
                ),
                source && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {source}
                  </Typography>
                ),
                !shrinkDocumentDisplay && CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete' && linkout && (
                  <Link
                    href={linkout}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      {t('actions.open_in_new_tab')}
                    </Typography>
                  </Link>
                ),
                !isCompleteDisplay && (isAudio || isVideo) && (
                  <Fragment>
                    {isVideo && <Videocam fontSize="small" />}
                    {isAudio && <Audiotrack fontSize="small" />}
                  </Fragment>
                ),
              ]}
              />
            </Box>
          </Box>
          {sentences && (
            sentences.map((sentence) => (
              <Box
                sx={{
                  p: 1,
                  mt: 0.5,
                  mb: 1,
                  borderLeft: '5px solid #d3d3d3',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '22px',
                }}
              >
                {sentence}
              </Box>
            ))
          )}
          {!isCompleteDisplay && (
            <Box component="span" sx={{ lineHeight: '1.3rem' }}>
              {snippet}
            </Box>
          )}
          {!shrinkDocumentDisplay && (
            <Box sx={{ mt: 0.5 }}>
              <ExpandableTagList
                disableRefine={disableTagsRefine}
                qesdocument={qesdocument}
                tags={[
                  { fieldName: 'QES_Company', icon: AccountBalance },
                  { fieldName: 'QES_Person', icon: AccountCircle },
                  { fieldName: 'QES_Location', icon: Place },
                  { fieldName: 'QES_ConceptCategorized', icon: Label },
                ]}
              />
            </Box>
          )}
          {isCompleteDisplay && (
            <Fragment>
              {isVideo && (
                <Card sx={{ maxWidth: '500px', mt: 2, mb: 2 }}>
                  <CardMedia component="video" controls src={attachedFileURL} />
                </Card>
              )}
              {isAudio && (
                <CardMedia
                  sx={{
                    maxWidth: '500px',
                    mt: 2,
                    mb: 2,
                    flexShrink: 0,
                  }}
                  component="audio"
                  controls
                  src={attachedFileURL}
                />
              )}
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  pr: 2,
                  whiteSpace: 'pre-wrap',
                  lineHeight: '30px',
                  clear: 'both',
                }}
              >
                {htmlWithAnnotations && (
                  <FormGroup>
                    <FormControlLabel
                      checked={isAnnotatedTextDisplayed}
                      control={<Switch onChange={handleToggleAnnotatedTextDisplay} />}
                      label={t('results.switch_annotated_text')}
                    />
                  </FormGroup>
                )}
                {htmlWithAnnotations && isAnnotatedTextDisplayed ? (
                  <div
                    className="html-with-tags"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: htmlWithAnnotations }}
                  />
                ) : (
                  text
                )}
              </Box>
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Document.propTypes = {
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  document: documentPropType.isRequired,
  handleGetDocumentComplete: PropTypes.func,
  handleToggleAnnotatedTextDisplay: PropTypes.func,
  isAnnotatedTextDisplayed: PropTypes.bool,
  isCompleteDisplay: PropTypes.bool,
  shrinkDocumentDisplay: PropTypes.bool,
};

export default Document;
