import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import NetworkProfile from 'generic/components/ui/NetworkProfile';
import TextTruncator from 'generic/components/ui/TextTruncator';
import { format } from 'generic/utils/dateUtils';
import _ from 'lodash';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import { articlePropType } from 'generic/core/anr/proptypes';

const ArticleViewBlock = ({
  article,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderColor: 'text.neutral',
        p: 1,
        maxHeight: '280px',
        overflow: 'auto',
        flexShrink: '0',
      }}
    >
      <Box sx={{ float: 'left', mt: '4px' }}>
        {!_.isEmpty(article.images) && !article.is_from_dark_web && (
          <Box sx={{ mr: 1, float: 'left' }}>
            <ImgLoader
              src={article.images[0]}
              fallbackComponent={(
                <ImageNotFound
                  height={114}
                  width={150}
                />
              )}
              alt={t('results.article_image')}
              height={114}
              width={150}
            />
          </Box>
        )}
        {article.atfromurl && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link
              underline="none"
              href={article.atfromurl}
              target="_blank"
              rel="noopener"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <NetworkProfile
                atfrom={article.atfrom}
                followersCount={article.followers_count}
                quoteFrom={article.quote_from}
                quoteType={article.quote_type}
                iconFontSize="large"
              />
            </Link>
            <Divider
              component="div"
              flexItem
              sx={{ mx: 1 }}
              orientation="vertical"
            />
          </Box>
        )}
      </Box>
      <Typography sx={{ fontSize: '1.2rem' }}>{article.title}</Typography>
      <Box
        sx={{
          display: 'flex',
          wordBreak: 'break-word',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          component="span"
          color="text.neutral"
          sx={{ fontSize: '0.9rem' }}
        >
          {format(new Date(article.display_date * 1000), 'P HH:mm')}
        </Typography>
        {article.source && (
          <Fragment>
            <Box sx={{ mx: 1, py: '3px' }}>
              <Divider
                orientation="vertical"
              />
            </Box>
            <Typography
              component="span"
              color="text.neutral"
              sx={{ fontSize: '0.9rem' }}
            >
              {article.source}
            </Typography>
          </Fragment>
        )}
      </Box>
      {article.snippet && (
        <Typography sx={{ fontSize: '0.9rem' }} component="span">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: article.snippet }} />
        </Typography>
      )}
      <Typography color="text.neutral" sx={{ fontSize: '0.9rem' }} component="span">
        <TextTruncator text={article.description} />
      </Typography>
      {article.quote_url && (
        <Link
          underline="none"
          href={article.quote_url}
          target="_blank"
          rel="noopener"
        >
          {t('anr.open_quote_link')}
        </Link>
      )}
    </Box>
  );
};

ArticleViewBlock.propTypes = {
  article: articlePropType.isRequired,
};

export default ArticleViewBlock;
