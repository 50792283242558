export const types = {
  CLEANUP_DOCUMENT: '@GED/CLEANUP_DOCUMENT',
  CLEAR_UPLOAD_GED_FILE: '@GED/CLEAR_UPLOAD_GED_FILE',
  CLOSE_EDIT_TRANSCRIPTION_ANNOTATION_DIALOG: '@GED/CLOSE_EDIT_TRANSCRIPTION_ANNOTATION_DIALOG',
  CLOSE_PATCH_SPEAKER_DIALOG: '@GED/CLOSE_PATCH_SPEAKER_DIALOG',
  DELETE_DOCUMENTS: '@GED/DELETE_DOCUMENTS',
  DELETE_DOCUMENTS_ERROR: '@GED/DELETE_DOCUMENTS_ERROR',
  DELETE_DOCUMENTS_SUCCESS: '@GED/DELETE_DOCUMENTS_SUCCESS',
  FAST_GED_CLOSE: '@FAST_GED/FAST_GED_CLOSE',
  FAST_GED_OPEN: '@FAST_GED/FAST_GED_OPEN',
  FETCH_DOCUMENT: '@GED/FETCH_DOCUMENT',
  FETCH_DOCUMENT_ERROR: '@GED/FETCH_DOCUMENT_ERROR',
  FETCH_DOCUMENT_SUCCESS: '@GED/FETCH_DOCUMENT_SUCCESS',
  OPEN_EDIT_TRANSCRIPTION_ANNOTATION_DIALOG: '@GED/OPEN_EDIT_TRANSCRIPTION_ANNOTATION_DIALOG',
  OPEN_PATCH_SPEAKER_DIALOG: '@GED/OPEN_PATCH_SPEAKER_DIALOG',
  SAVE_DOCUMENT: '@GED/SAVE_DOCUMENT',
  SAVE_DOCUMENT_ERROR: '@GED/SAVE_DOCUMENT_ERROR',
  SAVE_DOCUMENT_SUCCESS: '@GED/SAVE_DOCUMENT_SUCCESS',
  SET_MULTIPLE: '@GED/SET_MULTIPLE',
  TOGGLE_NEW_MULTIPLE_FIELD: '@GED/TOGGLE_NEW_MULTIPLE_FIELD',
  UNLOCK_DOCUMENT: '@GED/UNLOCK_DOCUMENT',
  UNSET_DOCUMENT: '@GED/UNSET_DOCUMENT',
  UPDATE_STATUS_DOCUMENTS: '@GED/UPDATE_STATUS_DOCUMENTS',
  UPDATE_STATUS_DOCUMENTS_ERROR: '@GED/UPDATE_STATUS_DOCUMENTS_ERROR',
  UPDATE_STATUS_DOCUMENTS_SUCCESS: '@GED/UPDATE_STATUS_DOCUMENTS_SUCCESS',
  UPLOAD_GED_FILE: '@GED/UPLOAD_GED_FILE',
  UPLOAD_GED_FILE_ERROR: '@GED/UPLOAD_GED_FILE_ERROR',
  UPLOAD_GED_FILE_SUCCESS: '@GED/UPLOAD_GED_FILE_SUCCESS',
};

export const cleanupDocument = (id) => ({
  type: types.CLEANUP_DOCUMENT,
  id,
});

export const fetchDocument = ({ id, base, variant }) => ({
  type: types.FETCH_DOCUMENT,
  id,
  base,
  variant,
});

export const fetchDocumentSuccess = (results) => ({
  type: types.FETCH_DOCUMENT_SUCCESS,
  results,
});

export const fetchDocumentError = (response) => ({
  type: types.FETCH_DOCUMENT_ERROR,
  response,
});

export const unsetDocument = () => ({
  type: types.UNSET_DOCUMENT,
});

export const clearUploadGEDFile = (fieldName) => ({
  type: types.CLEAR_UPLOAD_GED_FILE,
  fieldName,
});

export const toggleNewMultipleField = (fieldName) => ({
  type: types.TOGGLE_NEW_MULTIPLE_FIELD,
  fieldName,
});

export const openPatchSpeakerDialog = (speakerId, sentenceStart, existingSpeakers) => ({
  type: types.OPEN_PATCH_SPEAKER_DIALOG,
  speakerId,
  sentenceStart,
  existingSpeakers,
});

export const closePatchSpeakerDialog = () => ({
  type: types.CLOSE_PATCH_SPEAKER_DIALOG,
});

export const openEditTranscriptionAnnotationDialog = (start, annotation) => ({
  type: types.OPEN_EDIT_TRANSCRIPTION_ANNOTATION_DIALOG,
  start,
  annotation,
});

export const closeEditTranscriptionAnnotationDialog = () => ({
  type: types.CLOSE_EDIT_TRANSCRIPTION_ANNOTATION_DIALOG,
});

export const saveDocument = (params, afterSave) => ({
  type: types.SAVE_DOCUMENT,
  params,
  afterSave,
});

export const saveDocumentSuccess = (results) => ({
  type: types.SAVE_DOCUMENT_SUCCESS,
  results,
});

export const saveDocumentError = (response) => ({
  type: types.SAVE_DOCUMENT_ERROR,
  response,
});

export const setMultiple = (multiple) => ({
  type: types.SET_MULTIPLE,
  multiple,
});

export const deleteDocuments = (refresh) => ({
  type: types.DELETE_DOCUMENTS,
  refresh,
});

export const deleteDocumentsSuccess = (results) => ({
  type: types.DELETE_DOCUMENTS_SUCCESS,
  results,
});

export const deleteDocumentsError = (response) => ({
  type: types.DELETE_DOCUMENTS_ERROR,
  response,
});

export const updateStatusDocuments = (status, refresh) => ({
  type: types.UPDATE_STATUS_DOCUMENTS,
  status,
  refresh,
});

export const updateStatusDocumentsSuccess = (results) => ({
  type: types.UPDATE_STATUS_DOCUMENTS_SUCCESS,
  results,
});

export const updateStatusDocumentsError = (response) => ({
  type: types.UPDATE_STATUS_DOCUMENTS_ERROR,
  response,
});

export const unlockDocument = (id) => ({
  type: types.UNLOCK_DOCUMENT,
  id,
});

export const fastGedOpen = (id, base, scope) => ({
  type: types.FAST_GED_OPEN,
  id,
  base,
  scope,
});

export const fastGedClose = () => ({
  type: types.FAST_GED_CLOSE,
});
