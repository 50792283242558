import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import { toggleTranscriptionSentenceCheckbox } from 'generic/core/ux/actions';

const SentenceCheckboxContainer = ({ sentenceStart }) => {
  const isChecked = useSelector((state) => state.ux.checkedSentences.includes(sentenceStart));
  const dispatch = useDispatch();

  const handleToggleCheckbox = () => {
    dispatch(toggleTranscriptionSentenceCheckbox(sentenceStart));
  };

  return (
    <Checkbox
      sx={{ m: 0, p: 0 }}
      checked={isChecked}
      onClick={handleToggleCheckbox}
    />
  );
};

SentenceCheckboxContainer.propTypes = {
  sentenceStart: PropTypes.number.isRequired,
};

export default SentenceCheckboxContainer;
