import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import GEDContainer from 'generic/containers/GEDContainer';
import { useDispatch, useSelector } from 'react-redux';
import { unsetBackPageLocation } from 'generic/core/ux/actions';
import { refreshResults } from 'generic/core/search/actions';

const GEDContainerHOC = () => {
  const dispatch = useDispatch();

  const { id: idUrl, baseId } = useParams();
  const id = idUrl ? decodeURIComponent(idUrl) : undefined;

  const mouvement = useSelector((state) => state.search.results.mouvement);
  const multiple = useSelector((state) => state.ged.multiple);
  const backPageLocation = useSelector((state) => state.ux.backPageLocation);

  useEffect(() => () => {
    dispatch(unsetBackPageLocation());
  }, [dispatch]);

  const keepSelection = true;
  return (
    <GEDContainer
      {...{
        id,
        base: +baseId,
        multiple,
        backPageLocation,
        afterSave: () => { if (mouvement) dispatch(refreshResults(keepSelection)); },
      }}
    />
  );
};

export default GEDContainerHOC;
