import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
} from '@mui/material';
import { facetValuePropType } from 'generic/core/qes/proptypes';

const FacetValue = ({
  facetValue,
  checked = false,
  handleSearch,
  handleToggleCheckbox,
  hideCheckbox = false,
}) => (
  <ListItem
    key={facetValue.id}
    disablePadding
  >
    {!hideCheckbox && (
      <Checkbox
        onClick={() => handleToggleCheckbox(facetValue)}
        checked={checked}
      />
    )}
    <ListItemText
      primaryTypographyProps={{ noWrap: true }}
      title={facetValue.libelle}
      onClick={() => handleSearch({ [facetValue.id]: facetValue })}
      sx={{ cursor: 'pointer' }}
    >
      {facetValue.libelle}
    </ListItemText>
    <Typography>{facetValue.frequence}</Typography>
  </ListItem>
);

FacetValue.propTypes = {
  facetValue: facetValuePropType.isRequired,
  hideCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
};

export default React.memo(FacetValue);
