import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  LinearProgress,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import {
  Add,
  Delete,
  Description,
  Image,
  MonitorHeart,
  MoreVert,
} from '@mui/icons-material';

import { traitementsQtaPropType, utilisateurLightPropType } from 'generic/core/qes/proptypes';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { CONSTANTS } from 'generic/core/constants';

const Corpus = ({
  corpus,
  isOwner,
  variant = 'full',
  handleActivateCollection,
  handleOpenCollectionDialog,
  handleOpenDeleteCollectionConfirmDialog,
  handleOpenDialogUploadFiles,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { UPLOAD_FILE_SETTINGS, FILE_UPLOAD_FORMATS, API_ENTRY_POINT } = QES_CONSTANTS;

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  let cardBorderColor = 'transparent';
  if (corpus.active) {
    cardBorderColor = 'primary.main';
  } else if (variant === 'full') {
    cardBorderColor = 'divider';
  }

  let imgSrc = '';
  if (corpus.image) {
    imgSrc = `${API_ENTRY_POINT}/file?type=11&file=${corpus.image}&logon=${logon}&key=${key}`;
  }

  let numberDocuments = t('collections.no_document');
  if (corpus.nb_docs >= 0) {
    numberDocuments = t('collections.corpus.number_document', { count: corpus.nb_docs });
  }

  const traitementsEnCours = corpus.traitementsQta.filter((traitement) => traitement.etat === 'running');
  const displayMonitoring = UPLOAD_FILE_SETTINGS.UPLOAD_FILE_TYPE === FILE_UPLOAD_FORMATS.qtaDepositThenProcess;

  return (
    <Card
      sx={{
        border: corpus.active || variant === 'light' ? '2px solid' : '1px solid',
        borderColor: cardBorderColor,
        ...(variant === 'light' && {
          backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#000000',
        }),
        ...(variant === 'full' && {
          mb: 1,
        }),
      }}
    >
      <CardActionArea
        onClick={() => handleActivateCollection(corpus.collection, CONSTANTS.MAIN_PAGE)}
        sx={{
          ...(variant === 'light' && {
            backgroundColor: 'inherit',
          }),
        }}
      >
        <CardContent
          sx={{
            p: 1,
            ...(variant === 'light' && {
              p: 1.5,
            }),
          }}
        >
          {variant === 'light' && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  width: 'calc(60% - 8px)',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  fontSize: '14px',
                }}
              >
                {corpus.libelle}
              </Typography>
              <Chip
                size="small"
                sx={{ width: '40%' }}
                label={(
                  <span style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  }}
                  >
                    {corpus.collection_parente_name}
                  </span>
                )}
              />
            </Box>
          )}
          {variant === 'full' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Avatar
                src={imgSrc}
                alt=""
                sx={{
                  width: 38,
                  height: 38,
                  borderRadius: '6px',
                }}
              >
                <Image sx={{ fontSize: 38 }} />
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                      }}
                    >
                      {corpus.libelle}
                    </Typography>
                    <Typography fontSize="small">
                      {numberDocuments}
                      {!isEmpty(traitementsEnCours) && ` - ${t('collections.corpus.processing')}`}
                    </Typography>
                    { !isEmpty(traitementsEnCours) && (
                      <LinearProgress color="inherit" />
                    )}
                  </Box>
                  <DropdownMenu
                    btnChildren={<MoreVert fontSize="small" />}
                    btnProps={{
                      sx: { padding: '0px' },
                      component: 'div',
                    }}
                  >
                    <MenuItem onClick={() => handleOpenDialogUploadFiles(corpus.collection)}>
                      <Add fontSize="small" color="primary" sx={{ mr: 1 }} />
                      {t('collections.documents.add')}
                    </MenuItem>
                    {
                      (displayMonitoring)
                      && (
                        <MenuItem onClick={() => handleActivateCollection(corpus.collection, '/monitoring')}>
                          <MonitorHeart fontSize="small" color="primary" sx={{ mr: 1 }} />
                          {t('collections.corpus.monitoring')}
                        </MenuItem>
                      )
                    }
                    <MenuItem
                      onClick={() => handleOpenCollectionDialog(
                        'corpus',
                        'edit',
                        {
                          id: corpus.collection,
                          collection_parente: corpus.collection_parente,
                          owner: corpus.utilisateur_createur,
                          visibility: corpus.visibilite_collection,
                          collaborators: corpus.collaborators,
                          libelle: corpus.libelle,
                          description: corpus.description,
                          image: corpus.image,
                        },
                      )}
                    >
                      <Description fontSize="small" color="primary" sx={{ mr: 1 }} />
                      {isOwner ? t('collections.corpus.edit') : t('collections.corpus.params')}
                    </MenuItem>
                    <Tooltip
                      title={!isOwner ? t('collections.cannot_delete_not_owner') : ''}
                      placement="right"
                    >
                      <Box>
                        <MenuItem
                          disabled={!isOwner}
                          sx={{ color: 'error.main' }}
                          onClick={() => handleOpenDeleteCollectionConfirmDialog('corpus', [corpus.collection])}
                        >
                          <Delete fontSize="small" color="error" sx={{ mr: 1 }} />
                          {t('collections.corpus.delete')}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </DropdownMenu>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

Corpus.propTypes = {
  corpus: PropTypes.shape({
    active: PropTypes.bool,
    collaborators: PropTypes.arrayOf(utilisateurLightPropType),
    collection: PropTypes.number,
    collection_parente: PropTypes.number,
    collection_parente_name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    libelle: PropTypes.string,
    nb_docs: PropTypes.number,
    utilisateur_createur: utilisateurLightPropType.isRequired,
    visibilite_collection: PropTypes.number,
    traitementsQta: traitementsQtaPropType,
  }).isRequired,
  isOwner: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['full', 'light']),
  handleActivateCollection: PropTypes.func.isRequired,
  handleOpenCollectionDialog: PropTypes.func.isRequired,
  handleOpenDeleteCollectionConfirmDialog: PropTypes.func.isRequired,
  handleOpenDialogUploadFiles: PropTypes.func.isRequired,
};

export default Corpus;
