import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Stack,
} from '@mui/material';
import { Switch, TextField } from 'formik-mui';
import { LoadingButton } from '@mui/lab';

const FormDashboard = ({
  canEditVisibility,
  dialogTitle,
  handleClose,
  handleSubmit,
  initialValues,
  loading = false,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        libelle: Yup.string().required(t('form.errors.mandatory')),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
        >
          <Dialog
            disableRestoreFocus
            PaperProps={{
              sx: { minHeight: '60%' },
            }}
            fullWidth
            scroll="paper"
            maxWidth="lg"
            open
          >
            <DialogTitle>
              {dialogTitle}
            </DialogTitle>
            <DialogContent
              dividers
            >
              <Box sx={{ flexGrow: 1 }}>
                <Field
                  component={TextField}
                  autoComplete="off"
                  fullWidth
                  sx={{ mb: 2 }}
                  name="libelle"
                  label={t('dashboard.dashboard_title')}
                  required
                  autoFocus
                />
              </Box>

              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  label={t('dashboard.visibility')}
                  labelPlacement="start"
                  control={(
                    <Field
                      disabled={!canEditVisibility}
                      component={Switch}
                      type="checkbox"
                      name="est_public"
                      color="primary"
                    />
                  )}
                />
                <FormHelperText>
                  {t(`ui.${form.values.est_public ? 'shared' : 'mine'}`)}
                </FormHelperText>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.save')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

FormDashboard.propTypes = {
  canEditVisibility: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    libelle: PropTypes.string,
    est_public: PropTypes.bool,
    dashboard: PropTypes.number,
    base: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
};

export default FormDashboard;
