import React from 'react';
import { Box } from '@mui/material';

const SchedulePrograming = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} className="desktopOnlyOverflow">
    <Box sx={{ flexGrow: 1 }}>
      TODO
    </Box>
  </Box>
);

SchedulePrograming.propTypes = {
};

export default SchedulePrograming;
