import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
  Box,
  Checkbox,
  Chip,
  List, ListItem,
  Stack,
  Typography,
  TextField,
  IconButton,
  ButtonGroup,
  Autocomplete,
} from '@mui/material';
import {
  Close,
  DeleteOutlined,
  Edit,
  Save,
  SaveOutlined,
  SortByAlphaOutlined,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import CenteredMessage from 'generic/components/ui/CenteredMessage';
import DropdownWithCreate from 'generic/components/ui/DropdownWithCreate';
import SortableItem from 'generic/components/ui/SortableItem';
import RichTextEditor from 'generic/components/ui/RichTextEditor';
import DocumentForNewsletter from 'generic/components/documents/DocumentForNewsletter';
import { cartOrNewsletterDocumentPropType } from 'generic/core/qes/proptypes';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { CONSTANTS } from 'generic/core/constants';

const { CK_EDITOR_CONFIG } = CONSTANTS;

const OrdreRubTag = ({
  order,
  onSave,
  topic = null,
  readOnly = false,
  groupNames = [],
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { order, topic },
    onSubmit: (values) => {
      onSave(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="row" spacing={0} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <TextField
          id="order"
          name="order"
          label={t('newsletters.order')}
          className="forcedClassicFieldsetField"
          value={formik.values.order}
          onChange={formik.handleChange}
          sx={{ flex: 1, mr: 1 }}
          InputProps={{ readOnly }}
        />
        <Autocomplete
          id="topic"
          name="topic"
          freeSolo
          multiple={false}
          disableClearable
          options={groupNames}
          value={formik.values.topic}
          onChange={formik.handleChange}
          sx={{ flex: 2 }}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              className="forcedClassicFieldsetField"
              {...params}
              label={t('newsletters.topic')}
              onChange={formik.handleChange}
            />
          )}
          getOptionLabel={(label) => label}
        />
        <IconButton
          aria-label="delete"
          size="small"
          onClick={formik.handleSubmit}
          disabled={readOnly || !formik.dirty}
        >
          <SaveOutlined fontSize="inherit" />
        </IconButton>
      </Stack>
    </form>
  );
};

OrdreRubTag.propTypes = {
  order: PropTypes.number.isRequired,
  topic: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  groupNames: PropTypes.arrayOf(PropTypes.string),
};

const NewsletterDocuments = ({
  addGroupToFilters,
  allArticlesChecked = false,
  checkedItems = {},
  filterGroup = new Set(),
  groupNames = [],
  handleApplyGroupToSelection,
  handleDocumentMoved,
  handleGetDocumentComplete,
  handleOpenDeleteDocumentConfirmDialog,
  handleRegroupAndReorder,
  handleSaveOrderAndTopic,
  handleSaveDescEdition,
  handleToggleCheckbox,
  handleToggleCheckboxAll,
  hasOrdering = false,
  hasDocsEdition = false,
  isEmpty = true,
  panierDocs = [],
  removesGroupFromFilters,
}) => {
  const { t } = useTranslation();

  // GESTION DE L'EDITION DES ARTICLES
  const [currentOpenEdition, setCurrentOpenEdition] = useState(null);
  const [currentOpenEditionDesc, setCurrentOpenEditionDesc] = useState(null);
  const handleOpenEdition = (panierDocument) => {
    // TODO: calculer le rendu document soit sur le front soit sur le back
    // upgrade vers react-18 et tout les problèmes qui vont avec
    // OU
    // inclusion de ReactDOMServer qui rajoute un gros overhead (plusieurs Mo) sur la taille du package final pour rien
    setCurrentOpenEditionDesc(panierDocument.renduDocument);
    setCurrentOpenEdition(panierDocument);
  };
  const handleCloseEdition = () => {
    setCurrentOpenEdition(null);
    setCurrentOpenEditionDesc(null);
  };

  // GESTION DU DRAG'N'DROP
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  if (isEmpty) return (<CenteredMessage>{t('newsletters.no_document')}</CenteredMessage>);

  const actionButtonsStyle = {
    color: 'secondary',
    size: 'small',
  };

  const renderItem = (panierDocument) => (
    Array.from(filterGroup).includes(panierDocument.intitule) && (
      <ListItem
        sx={{
          alignItems: 'flex-end',
          m: 0,
          p: 0,
          pt: 1,
          mb: 1,
          gap: 1,
          flexWrap: 'wrap',
        }}
        key={panierDocument.idext}
      >
        <Box
          sx={{
            width: '300px',
            display: 'flex',
            flex: '0 0 auto',
            alignItems: 'center',
          }}
        >
          <Checkbox
            onClick={() => handleToggleCheckbox(panierDocument)}
            checked={checkedItems[panierDocument.idext] !== undefined}
            sx={{ margin: 0 }}
          />
          <OrdreRubTag
            order={panierDocument.ordre}
            topic={panierDocument.intitule}
            onSave={({ order, topic }) => handleSaveOrderAndTopic(panierDocument, order, topic)}
            readOnly={!hasOrdering}
            groupNames={groupNames}
          />
          {
            hasDocsEdition && (
              currentOpenEdition === panierDocument
                ? (
                  <Box>
                    <Save onClick={() => handleSaveDescEdition(panierDocument, currentOpenEditionDesc)} />
                    <Close onClick={handleCloseEdition} />
                  </Box>
                ) : (
                  <Edit onClick={() => handleOpenEdition(panierDocument)}>{t('newsletters.doc_edit')}</Edit>
                )
            )
          }
        </Box>

        <Box
          sx={{
            flex: '1 1 auto',
            width: '75%',
            pr: 2,
            pl: 1,
          }}
        >
          {
            currentOpenEdition === panierDocument
              ? (
                <RichTextEditor
                  data={document.renduDocument}
                  config={CK_EDITOR_CONFIG}
                  onChange={setCurrentOpenEditionDesc}
                />
              )
              : (
                <DocumentForNewsletter
                  panierDocument={panierDocument}
                  handleGetDocumentComplete={
                    () => handleGetDocumentComplete(panierDocument.idext, panierDocument.base)
                  }
                />
              )
          }
        </Box>
      </ListItem>
    )
  );

  const renderSortableItem = (panierDocument) => (
    Array.from(filterGroup).includes(panierDocument.intitule) && (
      <SortableItem
        key={panierDocument.panier_document}
        id={panierDocument.panier_document}
      >
        {renderItem(panierDocument)}
      </SortableItem>
    )
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        className="stickyHeader"
        sx={{
          zIndex: '1400',
          pb: 2,
          pl: hasOrdering ? 3 : 0,
        }}
      >
        <Box
          sx={{
            m: 2,
            flex: '0 1 auto',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '8px',
          }}
        >
          <Checkbox
            checked={allArticlesChecked}
            onChange={handleToggleCheckboxAll}
            sx={{ margin: 0 }}
          />

          <ButtonGroup
            sx={{ marginLeft: 1 }}
            variant="contained"
            disableElevation
          >
            <TooltipButton
              {...actionButtonsStyle}
              onClick={handleOpenDeleteDocumentConfirmDialog}
              title={t('carts.delete_cart_documents', { count: _.keys(checkedItems).length })}
              disabled={_.isEmpty(checkedItems)}
            >
              <DeleteOutlined />
            </TooltipButton>
            {hasOrdering && (
              <DropdownWithCreate
                {...actionButtonsStyle}
                values={groupNames}
                onClick={handleApplyGroupToSelection}
                onCreate={handleApplyGroupToSelection}
                inputLabel={t('newsletters.edit.create_topic')}
                title={t('newsletters.edit.manage_topics')}
                btnTitle={t('newsletters.edit.topics')}
                disabled={_.isEmpty(checkedItems)}
              />
            )}
          </ButtonGroup>

          {hasOrdering && (
            <TooltipButton
              variant="contained"
              onClick={handleRegroupAndReorder}
              color="secondary"
              size="small"
              disabled={!hasOrdering}
              title={t('newsletters.edit.reorganizeNaturalOrder')}
            >
              <SortByAlphaOutlined />
            </TooltipButton>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flex: '0 1 auto',
            flexWrap: 'wrap',
            ml: 3,
          }}
        >
          <Typography sx={{ mr: 1 }}>{t('newsletters.topics')}</Typography>
          {
            _.map(groupNames, (name) => {
              let handler = () => addGroupToFilters(name);
              let variant = 'outlined';
              if (filterGroup.has(name)) {
                handler = () => removesGroupFromFilters(name);
                variant = 'filled';
              }

              return (
                <Chip
                  key={name}
                  label={name || `[${t('newsletters.topic_without_label')}]`}
                  sx={{ mr: 1 }}
                  color="secondary"
                  size="extraSmall"
                  onClick={handler}
                  variant={variant}
                />
              );
            })
          }
        </Box>
      </Box>

      <Box
        sx={{
          flexFlow: 'row',
          overflowY: 'auto',
          flex: '1 1 auto',
          px: 2,
        }}
      >
        <List dense>
          {
            hasOrdering
              ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDocumentMoved}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <SortableContext
                    items={_.map(panierDocs, 'panier_document')}
                    strategy={verticalListSortingStrategy}
                  >
                    {panierDocs.map(renderSortableItem)}
                  </SortableContext>
                </DndContext>
              ) : (
                panierDocs.map(renderItem)
              )
          }
        </List>
      </Box>
    </Box>
  );
};

NewsletterDocuments.propTypes = {
  checkedItems: PropTypes.shape({
    id: PropTypes.string,
    item: PropTypes.shape({
      title: PropTypes.string,
      documentitem: PropTypes.shape(),
      panierdocument: cartOrNewsletterDocumentPropType,
    }),
  }),
  allArticlesChecked: PropTypes.bool,

  filterGroup: PropTypes.instanceOf(Set),
  groupNames: PropTypes.arrayOf(PropTypes.string),
  panierDocs: PropTypes.arrayOf(cartOrNewsletterDocumentPropType),

  addGroupToFilters: PropTypes.func.isRequired,
  handleApplyGroupToSelection: PropTypes.func.isRequired,
  handleDocumentMoved: PropTypes.func.isRequired,
  handleGetDocumentComplete: PropTypes.func.isRequired,
  handleOpenDeleteDocumentConfirmDialog: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleRegroupAndReorder: PropTypes.func.isRequired,
  handleSaveOrderAndTopic: PropTypes.func.isRequired,
  handleSaveDescEdition: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
  handleToggleCheckboxAll: PropTypes.func.isRequired,
  removesGroupFromFilters: PropTypes.func.isRequired,

  hasOrdering: PropTypes.bool,
  hasDocsEdition: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default NewsletterDocuments;
