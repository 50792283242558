import { useCallback } from 'react';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';
import mime from 'mime';

import QES_CONSTANTS from 'generic/core/qes/constants';

const { FILE_UPLOAD_FORMATS } = QES_CONSTANTS;

/**
 * Ensure that the array of extensions outputs a drpopzone compatible prop
 * @see dropzone {@link https://react-dropzone.js.org/#section-accepting-specific-file-types|section-accepting-specific-file-types}
 *
 * @param {string[]} extensions from api
 * @returns {object} an object which key is a mime-type and the values are an array of extensions
 */
const formatForDropzone = (extensions) => extensions.reduce((acc, ext) => {
  let extSynonym;
  // some extensions needs to be renamed to be matched
  switch (ext) {
    case '.tgz':
      extSynonym = '.tar.gz';
      break;
    default:
      extSynonym = ext;
  }

  const m = mime.getType(extSynonym);
  if (!Object.hasOwn(acc, m)) { acc[m] = []; }
  acc[m].push(ext);

  return acc;
}, {});

/**
 * Ensure that the array of extensions outputs a browser compatible format
 * @see browser input accept attribute {@link https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/accept|on MozDev}
 *
 * @param {string[]} extensions from api
 * @returns {string} a string containing each extension or mime-type separated by a comma
 */
const formatForBrowser = (extensions) => extensions.join(', ');

const ensureAcceptFormat = (format, extensions) => {
  const dotExtensions = extensions.map((ext) => {
    const startsWithDotOrContainsMimeTypeRegexp = /^\.|[/\\*]/;
    // eslint-disable-next-line no-bitwise
    return ~ext.search(startsWithDotOrContainsMimeTypeRegexp) ? ext.toLowerCase() : `.${ext.toLowerCase()}`;
  });

  if (format === 'dropzone') {
    return formatForDropzone(dotExtensions);
  }

  return formatForBrowser(dotExtensions);
};

/**
 * Permet de s'assurer d'avoir une valeur par défaut
 * @param {string[]} extensions le tableau issue des paramètre du back
 * @returns si la tableau d'extensions reçu est vide, on s'assure de restreindre avec les valeurs par défaut du front
 */
const ifEmptyReturnsFrontDefaults = (extensions) => (isEmpty(extensions) ? ['txt'] : extensions);

/**
 * Allows to retrieve accepted extensions for each functionnality
 * Values are retrieved on the backoffice
 * @param {('browser'|'dropzone')} format defines the output format, `browser` is intend to be used directly in input
 * accept attribute while `dropzone` is intend to be used by the component Dropzone
 *
 * @returns depending the output format.
 *
 * when `browser` a `string` (ex: `'.jpg, .png'`)
 *
 * when `dropzone` an `array[object]` (ex: `[{'image/jpg': 'jpg'}, {'image/png': 'png'}]`)
 *
 * @see dropzone {@link https://react-dropzone.js.org/#section-accepting-specific-file-types|section-accepting-specific-file-types}
 * @see browser input accept attribute {@link https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/accept|on MozDev}
 */
const useAuthorizedExtensions = (format = 'browser') => {
  const extAuthorized = useSelector((state) => get(state, 'config.settings.extensionsAutorisees'));

  /**
   * Permet récupérer les extensions autorisées générales de l'application.
   * @returns {string} les formats d'upload autorisées, il s'agit en général de valeurs séparées par des virgules.
   */
  const getParametre = useCallback(() => (
    ensureAcceptFormat(format, ifEmptyReturnsFrontDefaults(get(extAuthorized, 'parametre', [])))
  ), [extAuthorized, format]);

  /**
   * Permet de trouver, en général pour la GED, les extensions autorisées pour un champ de base_mise_page
   * @param {number} base id de base
   * @param {number} baseMisePage id de base mise page concerné, généralement un champ upload de la GED
   * @returns {string} les formats d'upload autorisées, il s'agit en général de valeurs séparées par des virgules
   */
  const getBaseMisePage = useCallback((base, baseMisePage) => ensureAcceptFormat(
    format,
    ifEmptyReturnsFrontDefaults(
      get(extAuthorized, `base_${base}_${baseMisePage}`, []),
    ),
  ), [extAuthorized, format]);

  /**
   * Permet de trouver les extensions autorisées pour un envoi de fichier sur le back
   * @param {FILE_UPLOAD_FORMATS} uploadType il s'agit du type d'upload, il permet de savoir où stocker le fichier
   * @param {number} base certains formats ont besoins de l'id de base et de baseMisePage
   * @param {number} baseMisePage certains formats ont besoins de l'id de base et de baseMisePage
   * @returns {string} les formats d'upload autorisées, il s'agit en général de valeurs séparées par des virgules
   */
  const getFileUpload = useCallback((uploadType, base = null, baseMisePage = null) => {
    // these types needs base and basemisepage
    const strangeBehaviorTypes = [
      FILE_UPLOAD_FORMATS.technicalDeposit,
    ];

    let res = [];
    if (strangeBehaviorTypes.includes(uploadType) && baseMisePage && base) {
      res = getBaseMisePage(base, baseMisePage);
    } else {
      const uploadCorrespondence = {
        [FILE_UPLOAD_FORMATS.csv]: 'file_upload_csv',
        [FILE_UPLOAD_FORMATS.refman]: 'file_upload_refman',
        [FILE_UPLOAD_FORMATS.cart]: 'file_upload_panier',
        [FILE_UPLOAD_FORMATS.newsletter]: 'file_upload_panier',
        [FILE_UPLOAD_FORMATS.trombi]: 'file_upload_trombi',
        [FILE_UPLOAD_FORMATS.plateform]: 'file_upload_plateforme',
        [FILE_UPLOAD_FORMATS.collection]: 'file_upload_collection',
        [FILE_UPLOAD_FORMATS.qtaDeposit]: 'file_upload_qta',
        [FILE_UPLOAD_FORMATS.qtaDepositThenProcess]: 'file_upload_qta',
      };

      res = ensureAcceptFormat(
        format,
        ifEmptyReturnsFrontDefaults(get(extAuthorized, uploadCorrespondence[uploadType], [])),
      );
    }

    return res;
  }, [extAuthorized, format, getBaseMisePage]);

  return {
    getParametre,
    getBaseMisePage,
    getFileUpload,
  };
};

/**
 * Renvois des méthodes utilitaires permettant la récupérations des extensions autorisées dans différents use-case.
 */
export default useAuthorizedExtensions;
