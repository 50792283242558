import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';

const CenteredMessage = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexGrow: '1',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    {children}
  </Box>
);

CenteredMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default CenteredMessage;
