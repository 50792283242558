import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  InputAdornment,
  MenuItem,
  Select,
} from '@mui/material';

const Sort = ({
  sorts,
  currentSort = '',
  handleChangeSort,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      id="sort-positioned-menu"
      sx={{ mr: 1 }}
      value={currentSort}
      onChange={handleChangeSort}
      variant="standard"
      startAdornment={
        <InputAdornment position="start">{t('results.sort')}</InputAdornment>
      }
    >
      {(
        sorts.map((sort) => (
          <MenuItem
            key={sort.code}
            value={sort.code}
          >
            {sort.libelle}
          </MenuItem>
        ))
      )}
    </Select>
  );
};

Sort.propTypes = {
  handleChangeSort: PropTypes.func.isRequired,
  sorts: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      libelle: PropTypes.string,
      actif: PropTypes.bool,
    }),
  ).isRequired,
  currentSort: PropTypes.string,
};

export default Sort;
