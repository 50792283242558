import React from 'react';
import {
  FormContainer,
  LoadingButtonWithIndicator,
} from 'generic/components/forms/FormLoginCommonComponents';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export default function FormResetPasswordSuccessPage() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <FormContainer
      title={t('logon.forgotten_password_success_title')}
      desc={t('logon.forgotten_password_success_desc')}
    >
      <LoadingButtonWithIndicator
        onClick={() => history.replace('/login')}
      >
        {t('logon.forgotten_password_success_button')}
      </LoadingButtonWithIndicator>
    </FormContainer>
  );
}
