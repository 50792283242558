import {
  get,
  post,
  put,
  del,
} from 'generic/api/apiGenerator';

import handleComplete from 'generic/utils/apiUtils';

/* Api des collections */
const fetchCollection = (id) => get({ path: `/collection/${id}` }).then(handleComplete);

const fetchCollections = ({ ...rest }) => get({ path: '/collection', ...rest }).then(handleComplete);

const activateCollection = (id) => put({ path: `/collection/${id}?active=true` }).then(handleComplete);

const createCollection = ({ ...rest }) => post({ path: '/collection', ...rest }).then(handleComplete);

const editCollection = ({ ...rest }) => put({ path: '/collection', ...rest }).then(handleComplete);

const deleteCollections = (ids) => del({ path: `/collection/${ids.join(',')}` }).then(handleComplete);

const forceRefreshMonitoring = (id) => get({ path: `/traitement-qta/${id}?refresh=1` }).then(handleComplete);

export {
  activateCollection,
  createCollection,
  deleteCollections,
  editCollection,
  fetchCollection,
  fetchCollections,
  forceRefreshMonitoring,
};
