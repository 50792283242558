import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { get } from 'lodash';
import * as Yup from 'yup';

import {
  resetPasswordConfirmation,
} from 'generic/core/auth/actions';

import { overrideRessource } from 'generic/utils/utils';
import { useTranslation } from 'react-i18next';

const FormResetPasswordConfirmation = overrideRessource('components/forms/FormResetPasswordConfirmation');

const FormResetPasswordConfirmationContainer = () => {
  const loading = useSelector((state) => state.auth.resetPasswordConfirmationLoading);
  const error = useSelector((state) => state.auth.resetPasswordConfirmationError);

  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { a } = queryString.parse(get(location, 'search', { a: '' }));

  const formikConfig = {
    initialValues: {
      a,
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().required(t('form.errors.mandatory')),
      newPasswordConfirmation: Yup.string().required(t('form.errors.mandatory'))
        .oneOf([Yup.ref('newPassword'), null], t('users.password_not_matching')),
    }),
    onSubmit: (values, actions) => {
      const callback = () => {
        actions.setSubmitting(false);
        actions.resetForm();
      };
      dispatch(resetPasswordConfirmation({ ...values, callback }));
    },
  };

  return (
    <Formik enableReinitialize {...formikConfig}>
      <Form>
        <FormResetPasswordConfirmation
          loading={loading}
          error={error}
        />
      </Form>
    </Formik>
  );
};

export default FormResetPasswordConfirmationContainer;
