import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const ImageNotFound = ({
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'divider',
        boxSizing: 'border-box',
        display: 'flex',
        fontSize: '10px',
        margin: 'auto',
        ...props,
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          userSelect: 'none',
        }}
      >
        {t('results.img_not_found')}
      </Box>
    </Box>
  );
};

ImageNotFound.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default ImageNotFound;
