import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  List,
  ListItem,
  Skeleton,
  Typography,
} from '@mui/material';
import DocumentMoreLikeThis from 'generic/components/documents/DocumentMoreLikeThis';
import { qesdocumentPropType } from 'generic/core/qes/proptypes';

const MoreLikeThis = ({
  qesdocuments = [],
  loading = false,
  moreLikeThisIsVisible = false,
  showSeeAlsoLabel = false,
}) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <Box sx={{ marginTop: 1 }}>
        <Skeleton variant="rounded" width="100%" height={226} />
      </Box>
    );
  }
  return (
    qesdocuments && moreLikeThisIsVisible && (
      <Box sx={{ marginTop: 1, borderLeft: '1px solid' }}>
        {showSeeAlsoLabel && (
          <Typography
            color="text.neutral"
            sx={{ pl: 2 }}
          >
            {t('results.morelikethis.see_also_label')}
          </Typography>
        )}
        <List dense sx={{ padding: '0' }}>
          {
            qesdocuments.map(
              (qdoc) => (
                <ListItem
                  key={qdoc.idext}
                  alignItems="flex-start"
                  dense
                >
                  <DocumentMoreLikeThis qesdocument={qdoc} />
                </ListItem>
              ),
            )
          }
        </List>
      </Box>
    )
  );
};

MoreLikeThis.propTypes = {
  qesdocuments: PropTypes.arrayOf(qesdocumentPropType),
  loading: PropTypes.bool,
  moreLikeThisIsVisible: PropTypes.bool,
  showSeeAlsoLabel: PropTypes.bool,
};

export default MoreLikeThis;
