import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCheckbox, clearSelection } from 'generic/core/selection/actions';
import Selection from 'generic/components/ui/Selection';
import { bulkActionsPropType } from 'generic/core/qes/proptypes';
import { getActiveSelectionItems } from 'generic/utils/qesUtils';

const SelectionContainer = ({
  bulkActionsProps,
}) => {
  const hasQuickResults = useSelector((state) => !_.isEmpty(state.search.quickResults));
  const checkedItems = useSelector((state) => getActiveSelectionItems(state));
  const dispatch = useDispatch();

  const handleToggleCheckbox = (id, title) => {
    dispatch(toggleCheckbox({
      id,
      item: { title },
      quickResultsScope: hasQuickResults,
    }));
  };

  const handleClearSelection = () => {
    dispatch(clearSelection({ quickResultsScope: hasQuickResults }));
  };

  return (
    <Selection
      bulkActionsProps={bulkActionsProps}
      checkedItems={checkedItems}
      handleToggleCheckbox={handleToggleCheckbox}
      handleClearSelection={handleClearSelection}
    />
  );
};

SelectionContainer.propTypes = {
  bulkActionsProps: PropTypes.shape(bulkActionsPropType).isRequired,
};

export default SelectionContainer;
