import React from 'react';
import { useSelector } from 'react-redux';
import { overrideRessource } from 'generic/utils/utils';
import { isEmpty, some } from 'lodash';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { UPLOAD_FILE_SETTINGS, FILE_UPLOAD_FORMATS } = QES_CONSTANTS;

const Header = overrideRessource('components/ui/Header');

const HeaderContainer = () => {
  const logged = useSelector((state) => state.auth.logged);
  const user = useSelector((state) => state.config.user);

  const hasCarts = useSelector((state) => some(state.config.bases, { panier: true }));
  const hasNewsletters = useSelector((state) => some(state.config.bases, { newsletter: true }));
  const hasWatches = useSelector((state) => some(state.config.bases, { veille: true }));
  const activeCollection = useSelector((state) => state.collections.activeCollection);
  const withCollections = useSelector((state) => state.config.settings?.withCollections);
  const hasDiffusionLists = useSelector((state) => state.config.settings?.withDiffusionLists_586);

  let hasCollections = false;
  let hasActiveCollection = true;
  if (withCollections) {
    hasCollections = true;
    if (isEmpty(activeCollection)) {
      hasActiveCollection = false;
    }
  }

  const hasMonitoring = hasActiveCollection
    && UPLOAD_FILE_SETTINGS.UPLOAD_FILE_TYPE === FILE_UPLOAD_FORMATS.qtaDepositThenProcess;

  if (!logged) {
    return '';
  }

  return (
    <Header
      user={user}
      hasActiveCollection={hasActiveCollection}
      hasCarts={hasCarts}
      hasCollections={hasCollections}
      hasNewsletters={hasNewsletters}
      hasWatches={hasWatches}
      hasDiffusionLists={hasDiffusionLists}
      hasMonitoring={hasMonitoring}
    />
  );
};

export default React.memo(HeaderContainer);
