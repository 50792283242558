import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  useMediaQuery,
  Pagination,
  Skeleton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ChatBubble,
  CreateNewFolder,
  Edit,
  Menu,
  PlayArrow,
  Redo,
  ViewSidebar,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import TooltipButton from 'generic/components/ui/TooltipButton';
import NbResultsStrip from 'generic/components/ui/NbResultsStrip';
import BulkArticlesActionsContainer from 'generic/containers/anr/BulkArticlesActionsContainer';
import FiltersContainer from 'generic/containers/anr/FiltersContainer';
import PaginationGoToPage from 'generic/components/ui/PaginationGoToPage';
import { prettyPrintNumber } from 'generic/utils/mathUtils';

const BodyHeader = ({
  filteredCount,
  handleGoToLastValidated,
  handlePageChangePagination,
  openLeftDrawer,
  shouldDisplayResults,
  totalCount,
  activeItem = {},
  articleViewIsOpenedRight = false,
  maxPage = 0,
  responseData = {},
  shouldDisplayResultsCount = false,
  showNoQuery = false,
  showTabs = false,
}) => {
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const containerIsShrinked = articleViewIsOpenedRight || smallerThanMedium;
  const { t } = useTranslation();

  let Icon = <PlayArrow color="primary" />;
  if (_.includes(['copy', 'new'], activeItem.view)) {
    Icon = <CreateNewFolder color="primary" fontSize="small" />;
  } else if (activeItem.view === 'settings') {
    Icon = <Edit color="primary" fontSize="small" />;
  }
  const articlesType = t(activeItem.view === 'quotes' ? 'anr.quotes' : 'anr.news', { count: filteredCount });

  let totalCountDisplay;
  if (filteredCount !== totalCount) {
    totalCountDisplay = `(${t('results.total')} ${prettyPrintNumber(totalCount)})`;
  }

  return (
    <Box
      className="stickyHeader"
      sx={{ zIndex: '2' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          minHeight: '48px',
          pb: 1,
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: '1000',
            minHeight: '48px',
            ml: '16px',
            width: '50%',
          }}
        >
          {smallerThanLarge && (
            <TooltipButton
              title={t('anr.show_folders')}
              tag="iconButton"
              onClick={openLeftDrawer}
            >
              <ViewSidebar />
            </TooltipButton>
          )}
          {!smallerThanLarge && Icon}
          <Typography
            noWrap
            variant="h6"
            title={activeItem.title}
            sx={{
              ml: 1,
              mr: 2,
            }}
          >
            {activeItem.title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: '1',
            flexShrink: '0',
          }}
        >
          {showTabs && (
            <Tabs
              value={activeItem.view}
              sx={{ minHeight: '40px', ml: 2 }}
            >
              <Tab
                sx={{ minHeight: '40px', minWidth: '50px', px: smallerThanMedium ? 1 : 2 }}
                component={Link}
                to={`/anr/folders/${activeItem.id}/news`}
                label={t('anr.news')}
                value="news"
                icon={smallerThanMedium ? null : <Menu fontSize="small" />}
                iconPosition="start"
              />
              <Tab
                sx={{ minHeight: '40px', minWidth: '50px', px: smallerThanMedium ? 1 : 2 }}
                component={Link}
                to={`/anr/folders/${activeItem.id}/quotes`}
                label={t('anr.quotes')}
                value="quotes"
                icon={smallerThanMedium ? null : <ChatBubble fontSize="small" />}
                iconPosition="start"
              />
            </Tabs>
          )}
          {shouldDisplayResults && _.isEmpty(responseData) && (
            <Skeleton
              variant="rounded"
              height="32px"
              width="140px"
              sx={{
                marginBottom: 0.5,
                borderRadius: '15px 0 0 15px',
              }}
            />
          )}
          {shouldDisplayResultsCount && (
            <NbResultsStrip>
              <Box sx={{ flexGrow: containerIsShrinked ? '1' : 0 }}>
                {`${prettyPrintNumber(filteredCount)} ${articlesType}`}
                {!_.isEmpty(totalCountDisplay) && (
                  <Typography
                    variant="string"
                    color="text.neutral"
                  >
                    &nbsp;
                    {totalCountDisplay}
                  </Typography>
                )}
              </Box>
            </NbResultsStrip>
          )}
          {showNoQuery && (
            <Box
              sx={{ color: 'error.main', mx: 2 }}
            >
              {t('anr.no_query')}
            </Box>
          )}
        </Box>
      </Box>
      {shouldDisplayResults && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: '0',
            flexWrap: 'wrap',
            gap: 1,
            px: 2,
            pb: 2,
          }}
        >
          {_.isEmpty(responseData) && (
            <Skeleton
              variant="rectangular"
              height={32}
              width="100%"
            />
          )}
          {!_.isEmpty(responseData) && (
            <Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                <BulkArticlesActionsContainer
                  shrinkMenu={containerIsShrinked}
                />
                <FiltersContainer />
              </Box>
              <Box sx={{ display: 'flex', flexShrink: '0' }}>
                <Pagination
                  count={maxPage}
                  page={(Math.ceil(responseData.offset / responseData.limit)) + 1}
                  onChange={handlePageChangePagination}
                  siblingCount={0}
                  boundaryCount={containerIsShrinked ? 0 : 1}
                  showFirstButton
                  showLastButton
                  size="small"
                  color="secondary"
                />
                {!containerIsShrinked && (
                  <PaginationGoToPage
                    maxPage={maxPage}
                    page={(Math.ceil(responseData.offset / responseData.limit)) + 1}
                    handleGoToPage={handlePageChangePagination}
                    size="small"
                  />
                )}
                <TooltipButton
                  tag="iconButton"
                  title={t('anr.go_to_last_validated')}
                  onClick={handleGoToLastValidated}
                  size="small"
                  color="inherit"
                  sx={{ height: '26px' }}
                >
                  <Redo fontSize="inherit" />
                </TooltipButton>
              </Box>
            </Fragment>
          )}
        </Box>
      )}
    </Box>
  );
};

BodyHeader.propTypes = {
  activeItem: PropTypes.shape({
    id: PropTypes.string,
    view: PropTypes.string,
    title: PropTypes.string,
  }),
  articleViewIsOpenedRight: PropTypes.bool,
  filteredCount: PropTypes.number.isRequired,
  handleGoToLastValidated: PropTypes.func.isRequired,
  handlePageChangePagination: PropTypes.func.isRequired,
  maxPage: PropTypes.number,
  openLeftDrawer: PropTypes.func.isRequired,
  responseData: PropTypes.shape({
    filtered_count: PropTypes.number,
    go_to_index: PropTypes.string,
    limit: PropTypes.number,
    offset: PropTypes.number,
    total_items: PropTypes.number,
  }),
  shouldDisplayResultsCount: PropTypes.bool,
  shouldDisplayResults: PropTypes.bool.isRequired,
  showNoQuery: PropTypes.bool,
  showTabs: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
};

export default BodyHeader;
