import { useSelector } from 'react-redux';

import _ from 'lodash';
import { format } from 'generic/utils/dateUtils';
import { t } from 'i18next';
import { toZonedTime } from 'date-fns-tz';

import {
  computeHtmlWithAnnotations,
  generateLinkToResource,
  getDocumentBaseId,
  getValueByBaseFieldsConfig,
  getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';
import { cleanMultipleLines } from 'generic/utils/utils';

export default (document, shouldComputeCompleteFields) => {
  const { qesdocument } = document;

  const docBaseId = getDocumentBaseId(document);
  const bases = useSelector((state) => state.config.bases);
  const resultsCompleteVisibleInResults = useSelector((state) => state.ux.resultsCompleteVisibleInResults);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');
  const pdfXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');
  const datePubXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champDate');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  const text = cleanMultipleLines(getValueOrFirstValueFromArray(qesdocument?.TXT_CLEAN || qesdocument?.TEXTE));

  const thumbXml = getValueOrFirstValueFromArray(qesdocument?.IMAGE || qesdocument?.THUMB);
  const linkout = getValueOrFirstValueFromArray(qesdocument?.URL || qesdocument?.QUOTE_URL);
  const source = getValueOrFirstValueFromArray(qesdocument?.SOURCE);
  const attachedFile = getValueOrFirstValueFromArray(qesdocument?.PDF || qesdocument?.Fichier_joint);
  const sentences = qesdocument?.Phrase;

  let attachedFileURL;
  if (attachedFile) {
    attachedFileURL = generateLinkToResource(docBaseId, attachedFile);
  }

  let thumb;
  if (thumbXml) {
    thumb = qesdocument?.IMAGE ? thumbXml : generateLinkToResource(docBaseId, thumbXml);
  }

  let pdf;
  if (pdfXml) {
    pdf = generateLinkToResource(docBaseId, pdfXml);
  }

  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'mpeg'];
  const audioExtensions = ['m4a', 'wav', 'mp3'];
  const isVideo = !_.isEmpty(attachedFile) && videoExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isAudio = !_.isEmpty(attachedFile) && audioExtensions.includes(attachedFile.split('.').pop().toLowerCase());

  const title = titleXml || `[${t('document.title_undefined')}]`;
  const status = +statusXml;
  const datePubZoned = toZonedTime(new Date(datePubXml), 'UTC');
  const date = format(datePubZoned);
  const snippet = _.truncate(text, {
    length: resultsCompleteVisibleInResults ? 150 : 500,
    separator: /,? +/,
  });

  // Process "heavy" fields only needed in the "complete" panel
  let htmlWithAnnotations = null;
  if (shouldComputeCompleteFields) {
    // Annotated html
    htmlWithAnnotations = computeHtmlWithAnnotations(getValueOrFirstValueFromArray(qesdocument?.QES_Html));
  }

  return {
    attachedFile,
    attachedFileURL,
    date,
    htmlWithAnnotations,
    id,
    isAudio,
    isVideo,
    linkout,
    pdf,
    sentences,
    snippet,
    source,
    status,
    text,
    thumb,
    title,
  };
};
