import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ArrowBack, Image,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { useTranslation } from 'react-i18next';

const PageBackAndTitle = ({
  backBtnIsVisible = true,
  backBtnTitle = null,
  backLink = null,
  handleGoBack = null,
  imgSrc = null,
  showImg = false,
  title = null,
}) => {
  const theme = useTheme();
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const backBtnProps = {
    color: 'secondary',
    size: 'extraSmall',
    title: backBtnTitle,
    variant: 'text',
  };
  if (backLink) {
    backBtnProps.component = Link;
    backBtnProps.to = backLink;
  } else {
    backBtnProps.onClick = handleGoBack;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 1,
      }}
    >
      {backBtnIsVisible && (
        <Fragment>
          {smallerThanSmall ? (
            <TooltipButton
              {...backBtnProps}
              sx={{
                flexShrink: '0',
                minWidth: '30px',
              }}
            >
              <ArrowBack />
            </TooltipButton>
          ) : (
            <TooltipButton
              {...backBtnProps}
              startIcon={<ArrowBack />}
              sx={{ flexShrink: '0' }}
            >
              {t('ui.back')}
            </TooltipButton>
          )}
          <Divider flexItem orientation="vertical" sx={{ ml: 1, mr: 2 }} />
        </Fragment>
      )}
      {showImg && (
        <Avatar
          src={imgSrc}
          alt=""
          sx={{ width: 28, height: 28, mr: '8px' }}
        >
          <Image />
        </Avatar>
      )}
      <Typography
        variant="h5"
        noWrap
      >
        {title}
      </Typography>
    </Box>
  );
};

PageBackAndTitle.propTypes = {
  backBtnIsVisible: PropTypes.bool,
  backBtnTitle: PropTypes.string,
  backLink: PropTypes.string,
  handleGoBack: PropTypes.func,
  imgSrc: PropTypes.string,
  showImg: PropTypes.bool,
  title: PropTypes.string,
};

export default PageBackAndTitle;
