import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import { Select, Switch, TextField } from 'formik-mui';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { ExpandMore } from '@mui/icons-material';
import DashboardPreviewChartsContainer from 'generic/containers/DashboardPreviewChartsContainer';

const FormDashboardWidget = ({
  initialValues,
  handleClose,
  handleSubmit,
  loading = false,
  isInDialog = false,
  formTitle = i18next.t('dashboard.add_new_widget'),
  creation = false,
  canCreateCooccurrencesWidget = false,
  canCreateRelationsWidget = false,
}) => {
  const { t } = useTranslation();
  const [widgetType, setWidgetType] = useState(initialValues.type);
  const [refreshingForm, setRefreshingForm] = useState(false);

  const filteredFields = useSelector((state) => {
    const baseFields = state?.config?.activeBase?.champs || [];
    const fields = baseFields.filter((champ) => {
      const notEmpty = !_.isEmpty(champ.interrogationDashboard);
      const b = (widgetType === 'spline') ? champ.type === 'date_intervalle' : champ.type !== 'date_intervalle';
      return notEmpty && b;
    });
    return fields;
  }, shallowEqual);

  const [displaySpline, displayList] = useSelector((state) => {
    const baseFields = state?.config?.activeBase?.champs || [];
    const withSpline = baseFields.some((field) => field.interrogationDashboard && field.type === 'date_intervalle');
    const withList = baseFields.some((field) => field.idListe);
    return [withSpline, withList];
  }, shallowEqual);

  let facetsLabel = t('dashboard.widget.form.facet');
  let multipleFieldFacet = false;
  if (widgetType === 'sankey' || widgetType === 'sunburst') {
    facetsLabel = t('dashboard.widget.form.facets');
    multipleFieldFacet = true;
  }

  const textfieldProps = {
    fullWidth: true,
    component: TextField,
    autoComplete: 'off',
    sx: {
      mt: 2,
      '& .MuiInputLabel-root': { width: '100%' },
    },
  };

  const listIfAny = (currentFacetValueSelected) => {
    const selectedFIeld = filteredFields.find((field) => field.interrogationDashboard === currentFacetValueSelected);
    if (selectedFIeld?.idListe && selectedFIeld.idListe !== 1000) {
      return selectedFIeld.idListe;
    }
    return '';
  };

  const widgetDefaultParams = QES_CONSTANTS.DEFAULT_WIDGETS_FORMS_PARAMS[widgetType];
  widgetDefaultParams.list = '';
  if (_.has(widgetDefaultParams, 'facets')) {
    if (multipleFieldFacet) {
      widgetDefaultParams.facets = [filteredFields[0].interrogationDashboard, filteredFields[1].interrogationDashboard];
    } else {
      widgetDefaultParams.facets = filteredFields[0].interrogationDashboard;

      if (filteredFields[0].idListe && filteredFields[0].idListe !== 1000) {
        widgetDefaultParams.list = filteredFields[0].idListe;
      }
      if (widgetType === 'map' && _.some(filteredFields, { code: 'F_42' })) {
        widgetDefaultParams.facets = _.find(filteredFields, { code: 'F_42' }).interrogationDashboard;
      }
    }
  }
  const yupSchema = {
    title: Yup.string().required(t('form.errors.mandatory')),
  };
  _.forOwn(widgetDefaultParams, (value, key) => {
    if (!['width', 'height'].includes(key)) {
      if (typeof value === 'number') {
        yupSchema[key] = Yup.number()
          .typeError(t('form.errors.must_be_integer'))
          .integer(t('form.errors.must_be_integer'))
          .min(0, `${t('form.errors.must_be_more_than')} 0`);
        if (key !== 'list') {
          yupSchema[key] = yupSchema[key].required(t('form.errors.mandatory'));
        }
      } else if (typeof value === 'string') {
        yupSchema[key] = Yup.string();
        if (!['aggregates', 'pivots', 'computeLink'].includes(key)) {
          yupSchema[key] = yupSchema[key].required(t('form.errors.mandatory'));
        }
      } else if (typeof value === 'boolean') {
        yupSchema[key] = Yup.boolean();
      }
      if (multipleFieldFacet && key === 'facets') {
        yupSchema[key] = Yup.array()
          .min(2, t('form.errors.must_contain_two_facets'));
      }
    }
  });

  const finalInitialValues = { ...widgetDefaultParams, ...initialValues };
  finalInitialValues.type = widgetType;

  let Container = Box;
  let containerProps = {};
  if (isInDialog) {
    Container = Dialog;
    containerProps = {
      PaperProps: {
        sx: { height: '80%', maxWidth: '1400px' },
      },
      fullWidth: true,
      scroll: 'paper',
      maxWidth: 'lg',
      open: true,
    };
  } else {
    containerProps = {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    };
  }
  const switchSx = {
    width: '100%',
    py: '4px',
    pl: '8px',
    mr: '0',
  };

  const refreshFacetField = () => {
    setRefreshingForm(true);
    setTimeout(() => {
      setRefreshingForm(false);
    }, 0);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={finalInitialValues}
      validationSchema={Yup.object().shape(yupSchema)}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (isInDialog && event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
          className={isInDialog ? '' : 'height100'}
        >
          <Container
            {...containerProps}
          >
            <DialogTitle>
              {formTitle}
            </DialogTitle>
            <DialogContent dividers sx={{ ...(creation && { display: 'flex', gap: 2 }) }}>
              <Box
                sx={{
                  ...(creation && {
                    width: '50%',
                    maxWidth: '550px',
                    overflowY: 'auto',
                    pr: 2,
                  }),
                }}
              >
                {creation && (
                  <Field
                    component={Select}
                    name="type"
                    label={t('dashboard.widget.form.type')}
                    sx={{
                      width: '100%',
                      mb: 1,
                    }}
                    onChange={(event) => {
                      setWidgetType(event.target.value);
                      refreshFacetField();
                    }}
                    onClose={_.noop}
                  >
                    {!_.isEmpty(filteredFields) && (
                      <MenuItem
                        value="pie"
                      >
                        {t('dashboard.widget.type.pie')}
                      </MenuItem>
                    )}
                    <MenuItem
                      value="documentlist"
                    >
                      {t('dashboard.widget.type.documentlist')}
                    </MenuItem>
                    {!_.isEmpty(filteredFields) && (
                      [
                        <MenuItem
                          key="wordcloud"
                          value="wordcloud"
                        >
                          {t('dashboard.widget.type.wordcloud')}
                        </MenuItem>,
                        <MenuItem
                          key="datatable"
                          value="datatable"
                        >
                          {t('dashboard.widget.type.datatable')}
                        </MenuItem>,
                        <MenuItem
                          key="treemap"
                          value="treemap"
                        >
                          {t('dashboard.widget.type.treemap')}
                        </MenuItem>,
                        <MenuItem
                          key="solidgauge"
                          value="solidgauge"
                        >
                          {t('dashboard.widget.type.solidgauge')}
                        </MenuItem>,
                        <MenuItem
                          key="bar"
                          value="bar"
                        >
                          {t('dashboard.widget.type.bar')}
                        </MenuItem>,
                        canCreateRelationsWidget && (
                          <MenuItem
                            key="relations"
                            value="relations"
                          >
                            {t('dashboard.widget.type.relations')}
                          </MenuItem>
                        ),
                        canCreateCooccurrencesWidget && (
                          <MenuItem
                            key="cooccurrences"
                            value="cooccurrences"
                          >
                            {t('dashboard.widget.type.cooccurrences')}
                          </MenuItem>
                        ),
                        <MenuItem
                          key="map"
                          value="map"
                        >
                          {t('dashboard.widget.type.map')}
                        </MenuItem>,
                        displaySpline && (
                          <MenuItem
                            key="spline"
                            value="spline"
                          >
                            {t('dashboard.widget.type.spline')}
                          </MenuItem>
                        ),
                        <MenuItem
                          key="sankey"
                          value="sankey"
                        >
                          {t('dashboard.widget.type.sankey')}
                        </MenuItem>,
                        <MenuItem
                          key="sunburst"
                          value="sunburst"
                        >
                          {t('dashboard.widget.type.sunburst')}
                        </MenuItem>,
                      ]
                    )}
                  </Field>
                )}
                <Box sx={{ flexGrow: '1' }}>
                  <Field
                    {...textfieldProps}
                    name="title"
                    label={t('dashboard.widget.form.title')}
                    required
                  />
                </Box>
                {_.has(widgetDefaultParams, 'facets') && !refreshingForm && (
                  <Box
                    sx={{
                      flexGrow: '1',
                      '& .MuiInputLabel-root': {
                        width: '100%',
                      },
                    }}
                  >
                    <Field
                      component={Select}
                      name="facets"
                      label={facetsLabel}
                      multiple={multipleFieldFacet}
                      sx={{
                        width: '100%',
                        '& .MuiInputLabel-root': { width: '100%' },
                      }}
                    >
                      {_.map(
                        filteredFields,
                        (field) => (
                          <MenuItem
                            key={field.interrogationDashboard}
                            value={field.interrogationDashboard}
                            disabled={
                              _.isArray(form.values.facets)
                              && ((form.values.type !== 'sankey' && form.values.facets.length >= 2)
                              || (form.values.type === 'sankey' && form.values.facets.length >= 3))
                              && !form.values.facets.includes(field.interrogationDashboard)
                            }
                            onClick={() => { form.setFieldValue('list', listIfAny(field.interrogationDashboard)); }}
                          >
                            {field.libelle}
                          </MenuItem>
                        ),
                      )}
                    </Field>
                  </Box>
                )}
                <Accordion
                  sx={{
                    mt: 2,
                    boxShadow: 'none',
                    '::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      minHeight: 'initial',
                      py: 1,
                      px: 1,
                      borderRadius: '5px',
                      border: '1px solid',
                      borderColor: 'divider',
                      backgroundColor: 'background.special',
                      '&.Mui-expanded': {
                        minHeight: 'initial',
                      },
                      '& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': {
                        margin: 0,
                      },
                    }}
                  >
                    {t('dashboard.widget.advanced_mode')}
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {_.has(widgetDefaultParams, 'slice') && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="slice"
                          label={t('dashboard.widget.form.slice')}
                          value={form.values.slice || ''}
                          required
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                      </Box>
                    )}
                    {_.has(widgetDefaultParams, 'additionalQuery') && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="additionalQuery"
                          label={t('dashboard.widget.form.additionalQuery')}
                          value={form.values.additionalQuery || ''}
                        />
                      </Box>
                    )}
                    {_.has(widgetDefaultParams, 'sort') && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="sort"
                          label={t('dashboard.widget.form.sort')}
                          value={form.values.sort || ''}
                          required
                        />
                      </Box>
                    )}
                    {_.has(widgetDefaultParams, 'facetmax') && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="facetmax"
                          label={t('dashboard.widget.form.facetmax')}
                          value={form.values.facetmax || ''}
                          required
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                      </Box>
                    )}
                    {_.has(widgetDefaultParams, 'facetmax2') && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="facetmax2"
                          label={t('dashboard.widget.form.facetmax2')}
                          value={form.values.facetmax2 || ''}
                          required
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                      </Box>
                    )}
                    {_.has(widgetDefaultParams, 'monoColors') && (
                      <FormControlLabel
                        sx={switchSx}
                        control={(
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="monoColors"
                            color="primary"
                          />
                        )}
                        label={t('dashboard.widget.form.monoColors')}
                      />
                    )}
                    {_.has(widgetDefaultParams, 'tones') && (
                      <FormControlLabel
                        sx={switchSx}
                        control={(
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="tones"
                            color="primary"
                          />
                        )}
                        label={t('dashboard.widget.form.tones')}
                      />
                    )}
                    {_.has(widgetDefaultParams, 'humanizeDocState') && (
                      <FormControlLabel
                        sx={switchSx}
                        control={(
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="humanizeDocState"
                            color="primary"
                          />
                        )}
                        label={t('dashboard.widget.form.humanizeDocState')}
                      />
                    )}
                    {_.has(widgetDefaultParams, 'totalunique') && (
                      <FormControlLabel
                        sx={switchSx}
                        control={(
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="totalunique"
                            color="primary"
                          />
                        )}
                        label={t('dashboard.widget.form.totalunique')}
                      />
                    )}
                    {_.has(widgetDefaultParams, 'humanizeLangs') && (
                      <FormControlLabel
                        sx={switchSx}
                        control={(
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="humanizeLangs"
                            color="primary"
                          />
                        )}
                        label={t('dashboard.widget.form.humanizeLangs')}
                      />
                    )}
                    {_.has(widgetDefaultParams, 'withExploration') && (
                      <FormControlLabel
                        sx={switchSx}
                        control={(
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="withExploration"
                            color="primary"
                          />
                        )}
                        label={t('dashboard.widget.form.withExploration')}
                      />
                    )}
                    {_.has(widgetDefaultParams, 'aggregates') && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="aggregates"
                          label={t('dashboard.widget.form.aggregates')}
                          value={form.values.aggregates || ''}
                          helperText={t('dashboard.widget.form.aggregates_helper')}
                        />
                      </Box>
                    )}
                    {_.has(widgetDefaultParams, 'computeLink') && (
                      <Field
                        component={Select}
                        name="computeLink"
                        label={t('dashboard.widget.form.computeLink')}
                        sx={{
                          width: '100%',
                          '& .MuiInputLabel-root': { width: '100%' },
                        }}
                        onClose={_.noop}
                        value={form.values.computeLink || ''}
                        displayEmpty
                      >
                        <MenuItem
                          value=""
                        >
                          {t('dashboard.widget.form.noComputeLinkMethod')}
                        </MenuItem>
                        <MenuItem
                          value="goToMitre"
                        >
                          {t('dashboard.widget.form.computeLinkWithGoToMitre')}
                        </MenuItem>
                        <MenuItem
                          value="goToAlienVault"
                        >
                          {t('dashboard.widget.form.computeLinkWithGoToAlienVault')}
                        </MenuItem>
                      </Field>
                    )}
                    {_.has(widgetDefaultParams, 'pivots') && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="pivots"
                          label={t('dashboard.widget.form.pivots')}
                          value={form.values.pivots || ''}
                          helperText={t('dashboard.widget.form.pivots_helper')}
                        />
                      </Box>
                    )}
                    {displayList && (
                      <Box sx={{ flexGrow: '1' }}>
                        <Field
                          {...textfieldProps}
                          name="list"
                          label={t('dashboard.widget.form.list')}
                          value={form.values.list}
                          helperText={t('dashboard.widget.form.list_helper')}
                        />
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
              {creation && !refreshingForm && (
                <DashboardPreviewChartsContainer widget={{ ...form.values, dashboard_widget: 0 }} />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.save')}
              </LoadingButton>
            </DialogActions>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

FormDashboardWidget.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  isInDialog: PropTypes.bool,
  formTitle: PropTypes.string,
  creation: PropTypes.bool,
  canCreateCooccurrencesWidget: PropTypes.bool,
  canCreateRelationsWidget: PropTypes.bool,
};

export default FormDashboardWidget;
