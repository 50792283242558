import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

const AvatarUploadDialog = ({
  cropperImage,
  handleClose,
  handleSubmit,
  loading = false,
}) => {
  const [crop, setCrop] = useState(null);

  const { t } = useTranslation();

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const initialCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop(initialCrop);
  };

  return (
    <Dialog
      scroll="paper"
      onKeyPress={(event) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
          handleSubmit(crop);
        }
      }}
      open
      PaperProps={{
        sx: {
          margin: '16px',

        },
      }}
    >
      <DialogTitle>
        {t('Crop Image')}
      </DialogTitle>
      <DialogContent
        dividers
      >
        <ReactCrop
          crop={crop}
          aspect={1}
          circularCrop
          minWidth={50}
          minHeight={50}
          onChange={(cropPixel, cropPercent) => setCrop(cropPercent)}
        >
          <img
            src={cropperImage}
            alt="Crop me"
            onLoad={onImageLoad}
          />
        </ReactCrop>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => handleSubmit(crop)}
        >
          {t('form.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

AvatarUploadDialog.propTypes = {
  cropperImage: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default AvatarUploadDialog;
