import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, useTheme } from '@mui/material';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm';
import Hover from 'wavesurfer.js/dist/plugins/hover.esm';
import _ from 'lodash';
import { formatTime } from 'generic/utils/dateUtils';

const Waveform = ({ mediaRef = null, regions = [], handleOpenEditAnnotationDialog }) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const hoverPlugin = useRef(null);
  const regionsPlugin = useRef(null);
  const theme = useTheme();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    waveSurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      cursorColor: '#333',
      barWidth: 2,
      height: 80,
      responsive: true,
      media: mediaRef.current,
      normalize: true,
      fetchParams: {
        credentials: 'include',
      },
    });

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, [mediaRef]);

  useEffect(() => {
    const unsubscribeDecode = waveSurferRef.current.on('decode', () => {
      setIsReady(true);
    });

    const unsubscribeDblClick = waveSurferRef.current.on('dblclick', () => {
      handleOpenEditAnnotationDialog(parseFloat(waveSurferRef.current.getCurrentTime().toFixed(3)));
    });

    return () => {
      unsubscribeDecode();
      unsubscribeDblClick();
    };
  }, [handleOpenEditAnnotationDialog]);

  useEffect(() => {
    // useEffect when WaveSurfer isReady or when regions change (show/hide speaker,
    // addition or edition of an annotation)
    if (isReady) {
      if (regionsPlugin.current) {
        regionsPlugin.current.destroy();
      }
      regionsPlugin.current = RegionsPlugin.create();
      waveSurferRef.current.registerPlugin(regionsPlugin.current);
      _.each(regions, (region) => {
        regionsPlugin.current.addRegion({
          start: region.start,
          end: region.end,
          color: region.color,
          content: region.content ? '💬' : null,
          drag: false,
          resize: false,
        });
      });
    }
  }, [isReady, regions]);

  useEffect(() => {
    // useEffect after regions are initialised or changed, to recreate hoverPLugin
    if (isReady) {
      if (hoverPlugin.current) {
        hoverPlugin.current.destroy();
      }
      hoverPlugin.current = Hover.create({
        formatTimeCallback: (seconds) => {
          const formattedTime = formatTime(seconds * 1000);
          const preciseSecond = parseFloat(seconds.toFixed(0));
          const currentRegion = _.find(
            regions,
            (region) => region.content && _.inRange(region.start.toFixed(0), preciseSecond - 1, preciseSecond + 1.5),
          );
          if (currentRegion) {
            return `${formattedTime} - ${currentRegion.content}`;
          }
          return formattedTime;
        },
        lineColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff',
        lineWidth: 1,
        labelBackground: '#555',
        labelColor: '#fff',
        labelSize: '14px',
      });
      waveSurferRef.current.registerPlugin(hoverPlugin.current);
    }
  }, [isReady, regions, theme.palette.mode]);

  useEffect(() => {
    waveSurferRef.current.setOptions({
      waveColor: theme.palette.mode === 'light' ? '#aaaaaa' : '#555555',
      progressColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff',
    });
  }, [theme.palette.mode]);

  return (
    <Fragment>
      {!isReady && (
        <Skeleton width="100%" height={80} animation="wave" variant="rectangular" />
      )}
      <Box ref={waveformRef} sx={{ display: isReady ? 'block' : 'none' }} />
    </Fragment>
  );
};

Waveform.propTypes = {
  handleOpenEditAnnotationDialog: PropTypes.func.isRequired,
  mediaRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number,
      color: PropTypes.string.isRequired,
      content: PropTypes.string,
    }),
  ),
};

export default Waveform;
