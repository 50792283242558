import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function NbResultsStrip({ children, ...rest }) {
  return (
    <Box
      sx={(theme) => ({
        lineHeight: '24px',
        padding: '3px 10px 3px 15px',
        borderRight: 'none',
        fontWeight: '500',
        fontSize: '0.875rem',
        letterSpacing: '0.02857em',
        color: theme.palette.primary.main,
        flexShrink: '0',
      })}
      {...rest}
    >
      {children}
    </Box>
  );
}

NbResultsStrip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default NbResultsStrip;
