import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import UploadFiles from 'generic/components/pages/UploadFiles';
import {
  addFileToUpload,
  cleanFilesToUpload,
  openFilePreview,
  removeFileToUpload,
} from 'generic/core/files/actions';
import useAuthorizedExtensions from 'generic/core/hooks/useAuthorizedExtensions';

const UploadFilesContainer = ({
  accept = null,
  filesContents = {},
  filesToUpload = [],
  ...props
}) => {
  const dispatch = useDispatch();

  const extensionsQTA = useSelector((state) => state.config.settings.extensionsAutorisees.file_upload_qta);

  // Cleanup des fichiers lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanFilesToUpload())
  ), [dispatch]);

  const handleFileChange = (files) => {
    if (files.length > 0) {
      dispatch(addFileToUpload(files));
      _.forEach(files, (file) => {
        if (!filesContents.current[file.path]) {
          // eslint-disable-next-line no-param-reassign
          filesContents.current[file.path] = file;
        }
      });
    }
  };

  const handleRemoveFile = (file) => {
    dispatch(removeFileToUpload(file));
    // eslint-disable-next-line no-param-reassign
    delete filesContents.current[file.path];
  };

  const handleOpenPreviewFile = (fileName) => {
    dispatch(openFilePreview(fileName));
  };

  const authorizedExtensions = useAuthorizedExtensions('dropzone').getParametre();
  const finalAccept = accept === null ? authorizedExtensions : accept;

  return (
    <UploadFiles
      {...{
        handleFileChange,
        handleRemoveFile,
        handleOpenPreviewFile,
        filesToUpload,
        extensionsQTA,
        accept: finalAccept,
        ...props,
      }}
    />
  );
};

UploadFilesContainer.propTypes = {
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  filesContents: PropTypes.shape({ current: PropTypes.shape() }),
  accept: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
};

export default UploadFilesContainer;
