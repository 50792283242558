/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Delete,
  Download,
  Edit,
  Menu,
} from '@mui/icons-material';
import _ from 'lodash';
import { TableVirtuoso } from 'react-virtuoso';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import DropdownMenu from 'generic/components/ui/DropdownMenu';

const Datatable = ({
  columns,
  data = [],
  handleExportWidget = null,
  handleOpenEdition = null,
  handleOpenDeleteDialog = null,
  onRowClick = null,
  onRowContextMenu = null,
  title = {
    text: '',
    align: 'center',
  },
}) => {
  const currentTheme = useColorSchemeDetector();

  const TableComponents = {
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
    Table: (props) => <Table size="small" {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead,
    TableRow: (props) => <TableRow hover={onRowClick !== null} {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 10px)' }}>
      <Box sx={{ display: 'flex', pb: '10px' }}>
        <Typography
          component="div"
          sx={{
            ...currentTheme.HIGHCHARTS.title.style,
            height: '35px',
            flexGrow: '1',
            paddingTop: '5px',
            paddingLeft: '15px',
            textAlign: title.align || 'center',
          }}
        >
          {title.text}
        </Typography>
        {(handleExportWidget || handleOpenEdition || handleOpenDeleteDialog) && (
          <DropdownMenu
            btnChildren={<Menu fontSize="medium" />}
          >
            {handleOpenEdition && (
              <MenuItem
                onClick={handleOpenEdition}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('dashboard.edit_widget_menu')}
                />
              </MenuItem>
            )}
            {handleOpenDeleteDialog && (
              <MenuItem
                onClick={handleOpenDeleteDialog}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('dashboard.delete_widget')}
                />
              </MenuItem>
            )}
            {handleExportWidget && (
              <MenuItem
                onClick={handleExportWidget}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('dashboard.download_all_csv')}
                />
              </MenuItem>
            )}
          </DropdownMenu>
        )}
      </Box>
      <TableVirtuoso
        style={{ height: '100%', boxShadow: 'none' }}
        data={data}
        components={TableComponents}
        itemContent={(i, row) => (
          _.map(
            row,
            (rowValue, index) => {
              let tableCellSx = {};
              if (onRowClick !== null) {
                tableCellSx = { cursor: 'pointer' };
              }
              return (
                <TableCell
                  key={rowValue}
                  align={columns[index].align || 'left'}
                  onClick={onRowClick ? (event) => onRowClick(event, row) : _.noop}
                  onContextMenu={onRowContextMenu ? (event) => onRowContextMenu(event, row) : _.noop}
                  sx={tableCellSx}
                >
                  {columns[index].cellRenderer ? (
                    columns[index].cellRenderer(rowValue)
                  ) : (
                    <Typography
                      sx={{
                        width: index === 0 ? '240px' : 'auto',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontSize: '14px',
                      }}
                    >
                      {rowValue}
                    </Typography>
                  )}
                </TableCell>
              );
            },
          )
        )}
      />
    </Box>
  );
};

Datatable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string,
      cellRenderer: PropTypes.func,
      name: PropTypes.string,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  handleExportWidget: PropTypes.func,
  handleOpenEdition: PropTypes.func,
  handleOpenDeleteDialog: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowContextMenu: PropTypes.func,
  title: PropTypes.shape({
    text: PropTypes.string,
    align: PropTypes.oneOf(['start', 'end', 'left', 'right', 'center', 'justify', 'match-parent']),
  }),
};

export default Datatable;
