import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import HeaderContainer from 'generic/containers/HeaderContainer';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import GEDModalContainer from 'generic/containers/GEDModalContainer';

const Layout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={0}
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          height: '100%',
        }}
      >
        <HeaderContainer />

        <Grid
          container
          zIndex="1"
          wrap="nowrap"
          className="desktopOnlyOverflow"
          sx={{ flexGrow: '1' }}
        >
          <Suspense fallback={<GlobalLoading />}>
            <Component {...matchProps} />
          </Suspense>
        </Grid>
        <GEDModalContainer />
      </Grid>
    )}
  />
);

Layout.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default Layout;
