import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { cleanupQuickResults, refineAddFacetsValues } from 'generic/core/search/actions';
import { OpenInNewOutlined } from '@mui/icons-material';
import { clearSelection } from 'generic/core/selection/actions';

const Tag = ({
  tags = [],
  icon,
  idChamp = 1000,
  disableRefine = false,
  externalLink = null,
  color,
  title = '',
  variant = 'filledPrimary',
}) => {
  const dispatch = useDispatch();

  return tags.map((tag) => {
    const upperProps = {
      icon,
      key: tag,
      label: <span title={title}>{tag}</span>,
      size: 'extraSmall',
      variant,
      sx: [
        (theme) => ({
          marginRight: theme.spacing(1),
          marginBottom: theme.spacing(0.5),
        }),
        color && { backgroundColor: `${color}45` },
      ],
    };

    if (!disableRefine) {
      upperProps.onClick = () => {
        dispatch(cleanupQuickResults());
        dispatch(clearSelection({ quickResultsScope: true }));
        dispatch(refineAddFacetsValues([{ champ: idChamp, strategie: tag }]));
      };
    }

    const type = typeof externalLink;
    if (['string', 'function'].includes(type)) {
      upperProps.deleteIcon = <OpenInNewOutlined />;
      upperProps.sx.push({
        '& .MuiChip-deleteIcon': {
          color: 'primary.attenuated',
        },
        '& .MuiChip-deleteIcon:hover': {
          color: 'primary.main',
        },
      });

      if (typeof externalLink === 'string') {
        upperProps.onDelete = () => window.open(externalLink);
      } else if (typeof externalLink === 'function') {
        upperProps.onDelete = () => externalLink(tag);
      }
    }

    return <Chip {...upperProps} />;
  });
};

Tag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.element,
  idChamp: PropTypes.number.isRequired,
  disableRefine: PropTypes.bool,
  externalLink: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  color: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default Tag;
