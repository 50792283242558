import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  useTheme,
} from '@mui/material';
import { OpenWith } from '@mui/icons-material';
import { types } from 'generic/core/dashboard/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { widgetPropType } from 'generic/core/qes/proptypes';
import EditWidgetDashboardContainer from 'generic/containers/EditWidgetDashboardContainer';

// eslint-disable-next-line react/prop-types
const DragHandle = ({ absolute }) => (
  <Box
    className="drag-handle"
    sx={{
      zIndex: '1',
      top: '15px',
      position: absolute ? 'absolute' : 'relative',
      cursor: 'move',
      userSelect: 'all',
      height: '20px',
      display: 'block',
    }}
  >
    <OpenWith sx={(theme) => (
      {
        userSelect: 'all',
        fontSize: '20px',
        color: theme.palette.mode === 'light' ? 'rgb(102, 102, 102)' : 'rgb(255, 255, 255)',
      })}
    />
  </Box>
);

const DashboardWidget = ({
  highchartsOptions = {},
  handleExportWidget = null,
  componentProps = {},
  component: Component,
  flat = false,
  widgetParams,
  canEditDashboard = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEditionOpened, setIsEditionOpened] = useState(false);

  useEffect(() => {
    if (widgetParams.isNew) {
      document.getElementById(`widget_${widgetParams.dashboard_widget}`)
        .scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [widgetParams]);

  const handleOpenDeleteDialog = () => {
    dispatch(setConfirmDialogData({
      title: t('dashboard.delete_widget'),
      textButton: 'form.delete',
      message: (
        <Trans>
          {t('dashboard.delete_widget_sure', { widgetTitle: widgetParams.title })}
        </Trans>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_WIDGET_SUCCESS, types.DELETE_WIDGET_ERROR],
      action: {
        type: types.DELETE_WIDGET,
        widgetId: widgetParams.dashboard_widget,
      },
    }));
  };

  let menuItems = [
    'viewFullscreen', 'separator', 'downloadJPEG', 'downloadPNG',
    'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS', 'downloadCSV',
  ];
  let editMethods = {};
  if (canEditDashboard) {
    menuItems = ['editWidget', 'deleteWidget'].concat(menuItems);
    editMethods = {
      editWidget: {
        onclick: () => setIsEditionOpened(true),
        text: t('dashboard.edit_widget_menu'),
      },
      deleteWidget: {
        onclick: handleOpenDeleteDialog,
        text: t('dashboard.delete_widget'),
      },
    };
  }
  if (handleExportWidget) {
    menuItems.push('downloadAllCSV');
  }
  const globalHighChartsOptions = {
    options: {
      accessibility: { enabled: false },
      credits: { enabled: false },
      exporting: {
        menuItemDefinitions: {
          ...editMethods,
          downloadAllCSV: {
            onclick: handleExportWidget,
            text: t('dashboard.download_all_csv'),
          },
        },
        buttons: {
          contextButton: {
            symbolStrokeWidth: 2,
            menuItems,
          },
        },
      },
    },
  };
  const finalOptions = _.merge({}, globalHighChartsOptions, highchartsOptions);

  const cardSx = {};
  if (theme.palette.mode === 'dark') {
    cardSx.border = '1px solid rgb(255 255 255 / 30%)';
    cardSx.boxShadow = 'none';
    cardSx.backgroundColor = theme.palette.background.default;
  }
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {flat ? (
        <Box>
          {Component == null
            ? ''
            : (
              <Component
                highchartsOptions={finalOptions}
                handleExportWidget={handleExportWidget}
                {...componentProps}
              />
            )}
        </Box>
      ) : (
        <Card
          sx={{
            width: '100%',
            height: '100%',
            ...cardSx,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: 'calc(100% - 10px)',
              px: isEditionOpened ? 0 : 2,
              pt: 0.5,
            }}
          >
            {isEditionOpened ? (
              <EditWidgetDashboardContainer
                widgetParams={widgetParams}
                handleClose={() => setIsEditionOpened(false)}
              />
            ) : (
              <Fragment>
                {canEditDashboard && (
                  <DragHandle absolute />
                )}
                <div style={{ height: '100%' }}>
                  <Component
                    highchartsOptions={finalOptions}
                    handleExportWidget={handleExportWidget}
                    handleOpenEdition={canEditDashboard ? () => setIsEditionOpened(true) : null}
                    handleOpenDeleteDialog={canEditDashboard ? handleOpenDeleteDialog : null}
                    {...componentProps}
                  />
                </div>
              </Fragment>
            )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

DashboardWidget.propTypes = {
  component: PropTypes.elementType.isRequired,
  componentProps: PropTypes.shape(),
  flat: PropTypes.bool,
  handleExportWidget: PropTypes.func,
  highchartsOptions: PropTypes.shape(),
  widgetParams: widgetPropType.isRequired,
  canEditDashboard: PropTypes.bool,
};

export default DashboardWidget;
