import React, {
  Fragment, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  List,
  ListItem,
  Box,
  Checkbox,
  Drawer,
  Pagination,
  Stack,
  Button,
  Select,
  InputAdornment,
  MenuItem,
  useMediaQuery,
  Skeleton,
  Portal,
} from '@mui/material';
import { NotificationAddOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { overrideRessource } from 'generic/utils/utils';
import { actionsPropType } from 'generic/core/qes/proptypes';

import SelectionContainer from 'generic/containers/SelectionContainer';
import ResultsCompleteContainer from 'generic/containers/ResultsCompleteContainer';
import QuickResultsHeaderContainer from 'generic/containers/QuickResultsHeaderContainer';
import SummarizeContainer from 'generic/containers/SummarizeContainer';
import SortContainer from 'generic/containers/SortContainer';
import CommentsContainer from 'generic/containers/CommentsContainer';
import ResultsActions from 'generic/components/ui/ResultsActions';
import ResultSingleActions from 'generic/components/ui/ResultSingleAction';
import ResultItemActionsMenu from 'generic/components/ui/ResultItemActionsMenu';
import MoreLikeThis from 'generic/components/ui/MoreLikeThis';
import DocumentsListSkeleton from 'generic/components/skeletons/DocumentsListSkeleton';
import DocumentLiteSkeleton from 'generic/components/skeletons/DocumentLiteSkeleton';

const DocumentWrapper = overrideRessource('components/documents/DocumentWrapper');

/** @type {import('@mui/material').SxProps<import('@mui/material').Theme>} */
const rootSx = (theme) => ({
  '& .qes_highlight_keyword': theme.custom.highlightKeyword,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Results = ({
  handleChangeRowsPerPage,
  handleFetchMoreLikeThis,
  handleGetDocumentComplete,
  handleOpenAddToCart,
  handleOpenAddToNewsletter,
  handleOpenCreateAlert,
  handleOpenMultipleGed,
  handleOpenSendToFriend,
  handleOpenExport,
  handleOpenDeleteArticleDialog,
  handleOpenUpdateStatusArticleDialog,
  handlePageChangePagination,
  handleToggleCheckbox,
  handleToggleCheckboxAll,
  allArticlesChecked = false,
  buttonsRights = {
    canAddToCart: false,
    canAddToNewsletter: false,
    canEditInGED: false,
    canFastEditInGed: false,
    canDeleteDocument: false,
    canSendToFriend: false,
    canExport: false,
    canSummarize: false,
  },
  canCreateAlerts = false,
  canComment = false,
  canSortResults = false,
  withMoreLikeThis = true,
  checkedItems = {},
  isRAGSearch = false,
  loading = false,
  loadingBlank = false,
  openedCompleteId = null,
  results = {
    tranche: 20,
    actions: [],
  },
  quickResultsScope = false,
  rowsPerPageList = null,
  openFastGed = _.noop,
  someArticlesChecked = false,
  resultsCompleteVisibleInResults = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const scrollTo = useRef();
  const [openSelectionStatus, setOpenSelectionStatus] = useState(false);
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleMoreLikeThis, setVisibleMoreLikeThis] = useState({});
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const noResultsFound = !loading && _.isEmpty(results.documents);
  const showAtLeastOneButton = _.some(buttonsRights, Boolean);

  useEffect(() => {
    if (scrollTo.current) {
      scrollTo.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [openedCompleteId]);

  useEffect(() => {
    if (_.isEmpty(checkedItems)) {
      setOpenSelectionStatus(false);
    }
  }, [checkedItems]);

  useEffect(() => {
    if (!loading) {
      setVisibleComments({});
      setVisibleMoreLikeThis({});
    }

    const scrollEl = document.getElementById('pageScrollQuick') || document.getElementById('pageScroll');
    if (scrollEl) {
      scrollEl.scrollTop = 0;
    }
  }, [loading]);

  const handleToggleComments = (document) => {
    setVisibleComments({ ...visibleComments, [document.idext]: !visibleComments[document.idext] });
  };

  const handleToggleMoreLikeThis = (document) => {
    setVisibleMoreLikeThis({ ...visibleMoreLikeThis, [document.idext]: !visibleMoreLikeThis[document.idext] });
    if (_.isEmpty(document.morelikethis)) {
      handleFetchMoreLikeThis(document);
    }
  };

  let documentsListStyle = { width: '100%' };
  if (resultsCompleteVisibleInResults && !isRAGSearch) {
    documentsListStyle = { flexShrink: '0', flexBasis: '600px' };
  }
  const bulkActionsProps = {
    actions: results.actions,
    canAddToCart: buttonsRights.canAddToCart,
    canAddToNewsletter: buttonsRights.canAddToNewsletter,
    canDeleteDocument: buttonsRights.canDeleteDocument,
    canEditInGED: buttonsRights.canEditInGED,
    canExport: buttonsRights.canExport,
    canSendToFriend: buttonsRights.canSendToFriend,
    handleOpenAddToCart,
    handleOpenAddToNewsletter,
    handleOpenDeleteArticleDialog,
    handleOpenExport,
    handleOpenMultipleGed,
    handleOpenSendToFriend,
    handleOpenUpdateStatusArticleDialog,
  };
  return (
    <Box
      className="desktopOnlyOverflow"
      id={`pageScroll${quickResultsScope ? 'Quick' : ''}`}
      sx={rootSx}
    >
      {results.qaReponse && (
        <Box sx={{ p: 4, mb: 2, backgroundColor: theme.palette.mode === 'dark' ? '#404040' : '#f5f5f5' }}>
          <Box
            sx={{
              p: 2,
              borderLeft: '5px solid',
              borderColor: theme.palette.mode === 'dark' ? '#b3b3b3' : '#d3d3d3',
              fontSize: '18px',
              whiteSpace: 'pre-wrap',
              lineHeight: '24px',
            }}
          >
            {results.qaReponse}
          </Box>
        </Box>
      )}
      <Box
        className="stickyHeader"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'space-between',
          p: '12px',
          px: smallerThanMedium ? 1 : 2,
          zIndex: '1200',
        }}
      >
        {/* Quand loadingBlank vaut true (après un clearResults),
        on affiche le Skeleton de la toolbar */}
        {loadingBlank && !quickResultsScope && (
          <Box sx={{ pl: 0.5, width: '100%' }}>
            <Skeleton variant="rectangular" height="32px" width="100%" />
          </Box>
        )}
        {quickResultsScope && <QuickResultsHeaderContainer />}
        {/* Quand loadingBlank vaut false on affiche la toolbar */}
        {!loadingBlank && (
          <Fragment>
            <Box sx={{ display: noResultsFound ? 'none' : 'block' }}>
              <Stack direction="row" gap={1} flexWrap="wrap">
                {showAtLeastOneButton && (
                  <Fragment>
                    <Checkbox
                      sx={{ m: 0, padding: '4px 4px 4px 5px' }}
                      checked={allArticlesChecked}
                      indeterminate={!allArticlesChecked && someArticlesChecked}
                      onChange={handleToggleCheckboxAll}
                    />
                    <ResultsActions
                      {...bulkActionsProps}
                      handleOpenSelection={() => setOpenSelectionStatus(true)}
                      loading={loading}
                      nbCheckedItems={_.size(checkedItems)}
                    />
                  </Fragment>
                )}
              </Stack>
            </Box>
            {!isRAGSearch && (
              <Box sx={{ display: noResultsFound ? 'none' : 'flex', flexWrap: 'wrap', flexShrink: '0' }}>
                {!smallerThanLarge && (
                  <Fragment>
                    {canSortResults && <SortContainer />}
                    {!_.isEmpty(rowsPerPageList) && (
                      <Select
                        onChange={handleChangeRowsPerPage}
                        startAdornment={
                          <InputAdornment position="start">{t('results.pagination.display_rows')}</InputAdornment>
                        }
                        sx={{ mr: 1 }}
                        value={results.tranche}
                        variant="standard"
                      >
                        {rowsPerPageList.map((nb) => (
                          <MenuItem key={nb} value={nb}>
                            {nb}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Fragment>
                )}
                <Pagination
                  count={Math.ceil(results.nbresults / results.tranche)}
                  page={Math.ceil(results.premier / results.tranche)}
                  onChange={handlePageChangePagination}
                  showFirstButton
                  showLastButton
                  siblingCount={0}
                  boundaryCount={smallerThanLarge ? 0 : 1}
                  size="small"
                  color="secondary"
                />
              </Box>
            )}
          </Fragment>
        )}
      </Box>

      {/* Quand loading vaut false et qu'aucun doc n'a été ramené on affiche le message "aucun résultat" */}
      {noResultsFound && (
        <Portal container={document.querySelector('.containerResults')}>
          <Box
            sx={{
              position: 'absolute',
              textAlign: 'center',
              width: '100%',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Box sx={{ px: 4 }}>
              {canCreateAlerts && !isRAGSearch
                ? t('results.no_search_results_create_alert')
                : t('results.no_search_results')}
              {canCreateAlerts && !isRAGSearch && (
                <Button
                  size="extraSmall"
                  color="secondary"
                  sx={{
                    boxShadow: 'none',
                    '&:active': { boxShadow: 'none' },
                    ml: '5px',
                    p: '5px 10px',
                    minWidth: 'auto',
                  }}
                  onClick={handleOpenCreateAlert}
                >
                  <NotificationAddOutlined />
                </Button>
              )}
            </Box>
          </Box>
        </Portal>
      )}
      <Box sx={{ display: 'flex', overflow: isRAGSearch ? 'unset' : 'auto', flexGrow: '1' }}>
        {/* Quand loading vaut true on affiche le Skeleton des résultats */}
        {loading ? (
          <Box
            sx={{
              overflow: 'auto',
              ...documentsListStyle,
            }}
          >
            <DocumentsListSkeleton nbDocs={results.tranche || 20} withLastLine={withMoreLikeThis || canComment} />
          </Box>
        ) : (
          !_.isEmpty(results.documents) && (
            <List className="overflowAuto" sx={{ ...documentsListStyle, py: 0 }}>
              {results.documents.map((document) => {
                let commentsButtonLabel = t('results.comments.comment');
                if (visibleComments[document.idext]) {
                  commentsButtonLabel = t('results.comments.hide_comments');
                } else if (!_.isEmpty(document.commentaires)) {
                  commentsButtonLabel = t('results.comments.show_comments', {
                    count: document.commentaires.length,
                  });
                }
                let seeAlsoButtonLabel = t('results.morelikethis.show_see_also');
                if (visibleMoreLikeThis[document.idext]) {
                  seeAlsoButtonLabel = t('results.morelikethis.hide_see_also');
                }
                let hasOpened = false;
                if (openedCompleteId === document.idext) {
                  hasOpened = true;
                }
                const DocFallback = <DocumentLiteSkeleton withCheckboxMargin={false} withLastLine={false} />;

                return (
                  <ListItem
                    ref={hasOpened ? scrollTo : _.noop}
                    alignItems="flex-start"
                    key={document.documentitem}
                    sx={{
                      px: smallerThanMedium ? 1 : 2,
                      mb: 1,
                      backgroundColor: 'inherit',
                      ...(hasOpened && {
                        backgroundColor:
                          theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.06)',
                      }),
                    }}
                  >
                    {showAtLeastOneButton && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Checkbox
                          sx={{ margin: 0 }}
                          onClick={() => handleToggleCheckbox(document)}
                          checked={checkedItems[document.idext] !== undefined}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                          <ResultItemActionsMenu>
                            <ResultSingleActions
                              canAddToCart={buttonsRights.canAddToCart}
                              canAddToNewsletter={buttonsRights.canAddToNewsletter}
                              canEditInGED={buttonsRights.canEditInGED}
                              canFastEditInGed={buttonsRights.canFastEditInGed}
                              canDeleteDocument={buttonsRights.canDeleteDocument}
                              canSendToFriend={buttonsRights.canSendToFriend}
                              canSummarize={buttonsRights.canSummarize}
                              handleOpenFastGed={() => {
                                openFastGed(document.idext, document.base);
                              }}
                              document={document}
                            />
                          </ResultItemActionsMenu>
                        </Box>
                      </Box>
                    )}
                    <Box sx={{ width: '100%' }}>
                      <DocumentWrapper
                        document={document}
                        disableTagsRefine={isRAGSearch}
                        handleGetDocumentComplete={() => handleGetDocumentComplete(document)}
                        shrinkDocumentDisplay={resultsCompleteVisibleInResults}
                        fallback={DocFallback}
                        fallbackForLoadable={DocFallback}
                      />
                      <Stack direction="row" gap={1} flexWrap="wrap">
                        {withMoreLikeThis && (
                          <Button
                            variant="text"
                            disableElevation
                            color="secondary"
                            size="extraSmall"
                            sx={{ textTransform: 'none' }}
                            onClick={() => handleToggleMoreLikeThis(document)}
                          >
                            {seeAlsoButtonLabel}
                          </Button>
                        )}
                        {canComment && (
                          <Button
                            variant="text"
                            disableElevation
                            size="extraSmall"
                            color="secondary"
                            sx={{ textTransform: 'none' }}
                            onClick={() => handleToggleComments(document)}
                          >
                            {commentsButtonLabel}
                          </Button>
                        )}
                      </Stack>
                      {withMoreLikeThis && (
                        <MoreLikeThis
                          qesdocuments={document.morelikethis}
                          loading={document.loadingMoreLikeThis}
                          moreLikeThisIsVisible={visibleMoreLikeThis[document.idext]}
                        />
                      )}
                      {canComment && (
                        <CommentsContainer document={document} commentIsVisible={visibleComments[document.idext]} />
                      )}
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          )
        )}
        <ResultsCompleteContainer />
      </Box>
      <Drawer
        anchor="right"
        variant="temporary"
        open={openSelectionStatus}
        onClose={() => setOpenSelectionStatus(false)}
        PaperProps={{ sx: { width: { xs: '80%', md: '50%', lg: '30%' } } }}
      >
        <SelectionContainer
          bulkActionsProps={bulkActionsProps}
        />
      </Drawer>
      <SummarizeContainer scope="results" hasDrawer />
    </Box>
  );
};

Results.propTypes = {
  allArticlesChecked: PropTypes.bool,
  buttonsRights: PropTypes.shape({
    canAddToCart: PropTypes.bool,
    canAddToNewsletter: PropTypes.bool,
    canEditInGED: PropTypes.bool,
    canFastEditInGed: PropTypes.bool,
    canDeleteDocument: PropTypes.bool,
    canSendToFriend: PropTypes.bool,
    canExport: PropTypes.bool,
    canSummarize: PropTypes.bool,
  }),
  canCreateAlerts: PropTypes.bool,
  canComment: PropTypes.bool,
  canSortResults: PropTypes.bool,
  withMoreLikeThis: PropTypes.bool,
  checkedItems: PropTypes.shape(),
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleFetchMoreLikeThis: PropTypes.func.isRequired,
  handleGetDocumentComplete: PropTypes.func.isRequired,
  handleOpenAddToCart: PropTypes.func.isRequired,
  handleOpenAddToNewsletter: PropTypes.func.isRequired,
  handleOpenCreateAlert: PropTypes.func.isRequired,
  handleOpenMultipleGed: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleOpenDeleteArticleDialog: PropTypes.func.isRequired,
  handleOpenUpdateStatusArticleDialog: PropTypes.func.isRequired,
  handleOpenExport: PropTypes.func.isRequired,
  handlePageChangePagination: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
  handleToggleCheckboxAll: PropTypes.func.isRequired,
  isRAGSearch: PropTypes.bool,
  loading: PropTypes.bool,
  loadingBlank: PropTypes.bool,
  openedCompleteId: PropTypes.string,
  results: PropTypes.shape({
    mouvement: PropTypes.number,
    nbresults: PropTypes.number,
    premier: PropTypes.number,
    tranche: PropTypes.number,
    actions: PropTypes.arrayOf(actionsPropType),
    documents: PropTypes.arrayOf(PropTypes.shape()),
    qaReponse: PropTypes.string,
    criteres: PropTypes.arrayOf(
      PropTypes.shape({
        champ: PropTypes.number,
        istext: PropTypes.bool,
        libelle: PropTypes.string,
        libelleNombre: PropTypes.string,
        mouvementChamp: PropTypes.number,
        operateur: PropTypes.string,
        parentheseFermante: PropTypes.string,
        parentheseOuvrante: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  quickResultsScope: PropTypes.bool,
  rowsPerPageList: PropTypes.arrayOf(PropTypes.number),
  openFastGed: PropTypes.func,
  someArticlesChecked: PropTypes.bool,
  resultsCompleteVisibleInResults: PropTypes.bool,
};

export default Results;
