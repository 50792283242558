import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Slider,
  sliderClasses,
} from '@mui/material';

const TaskAdvancementSlider = ({ max, value, severity = 'info' }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Slider
      max={max}
      size="small"
      value={value}
      step={1}
      marks
      sx={{
        color: `${severity}.light`,
        height: 4,
        [`& .${sliderClasses.rail}`]: {
          borderRadius: '10px',
          height: 4,
        },
        [`& .${sliderClasses.track}`]: {
          borderRadius: '10px',
          height: 4,
          border: 'none',
        },

        '& .MuiSlider-thumb': {
          display: 'none',
        },
        '& .MuiSlider-rail': {
          opacity: 0.28,
        },
      }}
    />
    <Box
      component="span"
      sx={{
        marginLeft: 1,
        fontSize: 14,
        color: 'grey.500',
      }}
    >
      {value}
      /
      {max}
    </Box>
  </Box>
);
TaskAdvancementSlider.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
};

export default memo(TaskAdvancementSlider);
