import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Clear } from '@mui/icons-material';
import _ from 'lodash';
import ResultsActions from 'generic/components/ui/ResultsActions';
import { bulkActionsPropType } from 'generic/core/qes/proptypes';

const Selection = ({
  bulkActionsProps,
  checkedItems = {},
  handleClearSelection,
  handleToggleCheckbox,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ overflow: 'auto' }}>
      {!_.isEmpty(checkedItems) && (
        <Box
          sx={{
            overflow: 'auto',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              margin: 1,
            }}
          >
            <Typography
              variant="h5"
              component="h5"
            >
              {t('selection.your_selection')}
            </Typography>
            <ResultsActions
              {...bulkActionsProps}
              handleClearSelection={handleClearSelection}
              isSelectionActions
              nbCheckedItems={_.keys(checkedItems).length}
            />
          </Box>
          <List
            dense
            className="overflowAuto"
          >
            {_.map(checkedItems, (item, key) => (
              <ListItem key={key} sx={{ paddingTop: '0' }}>
                <ListItemIcon>
                  <IconButton
                    onClick={() => handleToggleCheckbox(key, item.title)}
                    edge="end"
                    title={t('selection.remove')}
                    size="small"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </ListItemIcon>
                <Tooltip title={item.title}>
                  <ListItemText primaryTypographyProps={{ noWrap: true }}>
                    {item.title}
                  </ListItemText>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

Selection.propTypes = {
  bulkActionsProps: PropTypes.shape(bulkActionsPropType).isRequired,
  checkedItems: PropTypes.shape({
    id: PropTypes.string,
    item: PropTypes.shape({}),
  }),
  handleClearSelection: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
};

export default Selection;
