import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CriteriaButtonsContainer from 'generic/containers/CriteriaButtonsContainer';
import CriteriaContainer from 'generic/containers/CriteriaContainer';
import SearchHeaderSkeleton from 'generic/components/skeletons/SearchHeaderSkeleton';
import NbResultsStrip from 'generic/components/ui/NbResultsStrip';
import { prettyPrintNumber } from 'generic/utils/mathUtils';

const ResultsHeader = ({
  isRAGSearch = false,
  loading = false,
  nbResults = undefined,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box sx={{ display: loading ? 'block' : 'none', width: 'inherit' }}>
        <SearchHeaderSkeleton isRAGSearch={isRAGSearch} />
      </Box>
      <Box
        sx={{
          display: loading ? 'none' : 'flex',
          mb: 1,
          flexGrow: '1',
          alignItems: 'center',
          columnGap: '5px',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ flexGrow: '1', display: 'flex', flexWrap: 'wrap' }}>
          {!isRAGSearch && <CriteriaButtonsContainer />}
          <CriteriaContainer />
        </Box>
        <Box
          sx={{
            flexShrink: '0',
            display: 'flex',
            flexGrow: '1',
            justifyContent: 'right',
          }}
        >
          {!isRAGSearch && nbResults !== undefined && (
            <NbResultsStrip>
              {`${prettyPrintNumber(nbResults)} ${t('results.document', { count: nbResults })}`}
            </NbResultsStrip>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

ResultsHeader.propTypes = {
  isRAGSearch: PropTypes.bool,
  loading: PropTypes.bool,
  nbResults: PropTypes.number,
};

export default ResultsHeader;
