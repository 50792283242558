import React from 'react';
import { Box } from '@mui/material';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import FormLoginContainer from 'generic/containers/auth/FormLoginContainer';
import FormResetPasswordConfirmationContainer from 'generic/containers/auth/FormResetPasswordConfirmationContainer';
import FormResetPasswordSuccessPage from 'generic/containers/auth/FormResetPasswordSuccessPage';

const AuthenticationLanding = () => {
  const { path } = useRouteMatch();

  return (
    <Box
      sx={{
        bgcolor: 'backgroundLogin.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ width: 480 }}>
        <Switch>
          <Route path={`${path}/reset-password-confirmation`}>
            <FormResetPasswordConfirmationContainer />
          </Route>

          <Route path={`${path}/reset-password-success`}>
            <FormResetPasswordSuccessPage />
          </Route>

          <Route>
            <FormLoginContainer />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default AuthenticationLanding;
