import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TooltipButton from 'generic/components/ui/TooltipButton';

const DashboardFooter = ({
  handleOpenDeleteDashboardDialog,
  handleOpenCreateDashboardDialog,
  handleOpenEditDashboardDialog,
  handleOpenCreateWidgetDialog,
  canCreateDashboard,
  canEditDashboard,
}) => {
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  if (smallerThanLarge) {
    return null;
  }
  return (
    <Box
      sx={{
        p: '6px',
        borderTop: '1px solid',
        textAlign: canEditDashboard ? 'left' : 'center',
        borderColor: theme.palette.mode === 'dark' ? '#e2e2e2' : '#c5c5c5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      className="dashboard-footer"
    >
      <Box
        sx={{
          flexGrow: '1',
          pl: '16px',
          fontSize: '16px',
          color: theme.palette.mode === 'dark' ? '#f2f2f2' : '#777777',
          fontStyle: 'italic',
        }}
      >
        {navigator.userAgent.toUpperCase().indexOf('MAC') >= 0 ? (
          t('dashboard.cmd_click_for_quick_results')
        ) : (
          t('dashboard.ctrl_click_for_quick_results')
        )}
      </Box>

      <Box
        sx={{
          flexShrink: '0',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {canCreateDashboard && (
          <TooltipButton
            title={t('dashboard.add_new_dashboard')}
            onClick={handleOpenCreateDashboardDialog}
            size="small"
          >
            {t('ui.new')}
          </TooltipButton>
        )}
        {canEditDashboard && (
          <Fragment>
            {canCreateDashboard && (<Box sx={{ mx: 1, py: '3px' }}><Divider orientation="vertical" /></Box>)}
            <TooltipButton
              onClick={handleOpenDeleteDashboardDialog}
              size="small"
              title={t('dashboard.delete_dashboard')}
              color="error"
              variant="text"
            >
              {t('actions.delete')}
            </TooltipButton>
            <TooltipButton
              onClick={handleOpenEditDashboardDialog}
              size="small"
              title={t('dashboard.edit_dashboard')}
              variant="text"
            >
              {t('actions.edit')}
            </TooltipButton>
            <Box sx={{ mx: 1, py: '3px' }}><Divider orientation="vertical" /></Box>
            <Button
              onClick={handleOpenCreateWidgetDialog}
              size="small"
            >
              {t('dashboard.add_new_widget')}
            </Button>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

DashboardFooter.propTypes = {
  handleOpenDeleteDashboardDialog: PropTypes.func.isRequired,
  handleOpenCreateDashboardDialog: PropTypes.func.isRequired,
  handleOpenEditDashboardDialog: PropTypes.func.isRequired,
  handleOpenCreateWidgetDialog: PropTypes.func.isRequired,
  canCreateDashboard: PropTypes.bool.isRequired,
  canEditDashboard: PropTypes.bool.isRequired,
};

export default DashboardFooter;
