import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CollectionDialog from 'generic/components/dialogs/CollectionDialog';
import {
  clearUploadCollectionImage,
  closeCollectionDialog,
  createCollection,
  editCollection,
  openCollectionDialog,
  types,
} from 'generic/core/collections/actions';
import _ from 'lodash';
import { openDialogUploadFiles } from 'generic/core/actions/actions';

const CollectionDialogContainer = ({ collections, idUser }) => {
  const {
    collectionParente,
    collaborators,
    description,
    fileUploadName,
    fileUploading,
    id,
    idCollectionNextStep,
    open,
    libelle,
    loading,
    mode,
    owner,
    typeRessource,
    visibility,
  } = useSelector((state) => state.collections.dialogCollection);

  const dispatch = useDispatch();

  // Champ désactivé si on est en mode 'edition' et que l'utilisateur connecté n'est pas le propriétaire de la
  // collection
  const isDisabled = mode === 'edit' && owner?.utilisateur !== idUser;
  let userCollections = _.filter(collections, { utilisateur: idUser });
  if (isDisabled) {
    userCollections = _.filter(collections, { collection: collectionParente });
  }

  const initialValues = {
    libelle: mode === 'edit' ? libelle : '',
    description: mode === 'edit' ? description : '',
    visibilite_collection: 2,
    ...(typeRessource === 'corpus' && {
      visibilite_collection: mode === 'edit' ? visibility : 1000,
      collaborators: mode === 'edit' ? collaborators : [],
      collection_parente: collectionParente || userCollections[0].collection,
      image: mode === 'edit' ? fileUploadName : '',
    }),
  };

  const uploadActions = {
    loading: { type: types.UPLOAD_COLLECTION_IMAGE },
    success: { type: types.UPLOAD_COLLECTION_IMAGE_SUCCESS },
    error: { type: types.UPLOAD_COLLECTION_IMAGE_ERROR },
  };

  const handleClearUploadField = () => {
    dispatch(clearUploadCollectionImage());
  };

  const handleClose = () => {
    dispatch(closeCollectionDialog());
  };

  const handleSubmit = (formValues, shouldOpenNextStep) => {
    if (mode === 'edit') {
      dispatch(editCollection(typeRessource, { ...formValues, id }));
    } else {
      dispatch(createCollection(typeRessource, formValues, shouldOpenNextStep));
    }
  };

  useEffect(() => {
    if (idCollectionNextStep) {
      if (typeRessource === 'collection') {
        dispatch(openCollectionDialog(
          'corpus',
          'create',
          { collection_parente: idCollectionNextStep },
        ));
      } else if (typeRessource === 'corpus') {
        dispatch(openDialogUploadFiles(idCollectionNextStep));
      }
    }
  }, [idCollectionNextStep, dispatch, typeRessource]);

  if (!open) {
    return null;
  }

  return (
    <CollectionDialog
      collections={userCollections}
      fileUploading={fileUploading}
      fileUploadName={fileUploadName}
      initialValues={initialValues}
      isDisabled={isDisabled}
      loading={loading}
      mode={mode}
      typeRessource={typeRessource}
      uploadActions={uploadActions}
      handleClearUploadField={handleClearUploadField}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

CollectionDialogContainer.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.shape({
    collection: PropTypes.number,
    libelle: PropTypes.string,
  })).isRequired,
  idUser: PropTypes.number.isRequired,
};

export default CollectionDialogContainer;
