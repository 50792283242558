import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  Fab,
  InputAdornment,
  Stack,
  TextField as TextFieldMUI,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Clear,
  Close,
  FilterAlt,
  FolderSpecial,
  RestartAlt,
  Search,
} from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import FormikDatePicker from 'generic/components/forms/FormikDatePicker';
import FilterFolderItem from 'generic/components/pages/anr/FilterFolderItem';
import { folderPropType } from 'generic/core/anr/proptypes';
import clsx from 'clsx';
import { subDays } from 'date-fns';
import _ from 'lodash';

const FormAnRFilters = ({
  initialValues,
  numberParams,
  stateOpenFilterNav,
  showNewsInputs,
  viewGlimpse,
  hasFavorites,
  searchedFolders,
  clickFromResults = false,
  handleOpenFilterNav,
  handleResetForm,
  handleSubmit,
  handleSearchFolders,
  handleFilterFolder,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Fragment>
      { /* ______ Bouton Access Filtre ______ */}
      <Badge
        badgeContent={numberParams}
        color="error"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <TooltipButton
          title={t('anr.actions.filters')}
          size="small"
          color="secondary"
          sx={{ minWidth: '0px' }}
          onClick={handleOpenFilterNav}
        >
          <FilterAlt />
        </TooltipButton>
      </Badge>
      { /* ______ Container Filtres ______ */}
      {stateOpenFilterNav && (
        <Drawer
          variant={smallerThanLarge ? 'temporary' : 'persistent'}
          open={stateOpenFilterNav}
          onClose={handleOpenFilterNav}
          PaperProps={{
            sx: !smallerThanLarge ? {
              height: 'auto',
              width: '25%',
              top: '64px',
              bottom: '0',
              boxShadow: theme.palette.mode === 'dark'
                ? '4px 0 8px 0 rgba(255, 255, 255, 0.1)' : '4px 0 8px 0 rgba(0, 0, 0, 0.2)',
            } : {
              width: '75%',
            },
          }}
        >
          { /* Titre */}
          <Box
            className="stickyHeader"
            sx={{
              display: 'flex',
              p: 2,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              {t('anr.actions.filters')}
            </Typography>
            <Fab
              size="extraSmall"
              color="secondary"
              onClick={handleOpenFilterNav}
            >
              <Close />
            </Fab>
          </Box>
          { /* Formulaire Filtres */}
          <Formik
            initialValues={initialValues}
            validateOnChange
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {(form) => (
              <Form
                className={
                  clsx(
                    'displayFlex',
                    'flexGrow1',
                    'flexDirectionColumn',
                    'overflowAuto',
                  )
                }
              >
                { /* Inputs */}
                <Box
                  sx={{
                    flexGrow: '1',
                    p: 2,
                  }}
                  className={
                    clsx(
                      'overflowAuto',
                    )
                  }
                >
                  { /* Inputs dédiés aux actualités */}
                  {showNewsInputs && (
                    <Fragment>
                      { /* Input Filtre Recherche */}
                      <Field
                        autoFocus
                        component={TextField}
                        name="subquery"
                        label={t('form.search')}
                        color="primary"
                        InputProps={{
                          sx: {
                            pr: 0.5,
                            mb: 1.5,
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                            >
                              <TooltipButton
                                title={t('form.clear')}
                                tag="iconButton"
                                onClick={() => {
                                  form.setFieldValue('subquery', '');
                                  form.handleSubmit();
                                }}
                                disabled={!form.values.subquery}
                              >
                                <Clear />
                              </TooltipButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      { /* Input Filtre Site */}
                      <Field
                        component={TextField}
                        name="site"
                        label={t('anr.site')}
                        color="primary"
                        InputProps={{
                          sx: {
                            pr: 0.5,
                            mb: 1.5,
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                            >
                              <TooltipButton
                                title={t('form.clear')}
                                tag="iconButton"
                                onClick={() => {
                                  form.setFieldValue('site', '');
                                  form.handleSubmit();
                                }}
                                disabled={!form.values.site}
                              >
                                <Clear />
                              </TooltipButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Fragment>
                  )}
                  { /* Input Filtre Date */}
                  <Stack direction="row" sx={{ gap: 1, mb: 1.5 }}>
                    { /* Input Date Début */}
                    <Field
                      component={FormikDatePicker}
                      name="from"
                      inputFormat="dd/MM/yyyy"
                      minDate={subDays(new Date(), 13)}
                      maxDate={new Date()}
                      label={t('anr.date_from')}
                      textFieldProps={{
                        inputProps: {
                          placeholder: t('form.datepicker.placeholder'),
                        },
                      }}
                      onAccept={form.handleSubmit}
                    />
                    { /* Input Date Fin */}
                    <Field
                      component={FormikDatePicker}
                      name="to"
                      inputFormat="dd/MM/yyyy"
                      minDate={subDays(new Date(), 13)}
                      maxDate={new Date()}
                      label={t('anr.date_to')}
                      textFieldProps={{
                        inputProps: {
                          placeholder: t('form.datepicker.placeholder'),
                        },
                      }}
                      onAccept={form.handleSubmit}
                    />
                  </Stack>
                  { /* Filtre Dossiers */}
                  {viewGlimpse && (
                    <Fragment>
                      <Divider>
                        {t('anr.folders')}
                      </Divider>
                      { /* Champ de Recherche Dossiers */}
                      <TextFieldMUI
                        label={t('anr.filter_folders.label')}
                        variant="outlined"
                        onChange={handleSearchFolders}
                      />
                      { /* Filtre Favoris */}
                      {hasFavorites && (
                        <Box
                          sx={{
                            px: 1.5,
                            py: 0.25,
                            cursor: 'pointer',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: _.includes(form.values.folders_titles, 'favorites') ? 700 : 400,
                          }}
                          onClick={() => handleFilterFolder('favorites')}
                        >
                          <FolderSpecial
                            fontSize="small"
                            sx={{ verticalAlign: 'bottom', mr: 1, color: 'text.neutral' }}
                          />
                          <span>{t('anr.filter_folders.favorites')}</span>
                        </Box>
                      )}
                      { /* Filtre sur les dossiers recherchés */}
                      {_.map(searchedFolders, (item) => (
                        <FilterFolderItem
                          item={item}
                          foldersTitles={form.values.folders_titles}
                          clickFromResults={clickFromResults}
                          handleFilterFolder={handleFilterFolder}
                          key={item.id}
                        />
                      ))}
                      { /* Filtre sur tous */}
                      <Box
                        sx={{
                          px: 1.5,
                          py: 0.25,
                          cursor: 'pointer',
                          fontSize: '14px',
                          lineHeight: '20px',
                          fontWeight: _.includes(form.values.folders_titles, 'all') ? 700 : 400,
                        }}
                        onClick={() => handleFilterFolder('all')}
                      >
                        {t('anr.filter_folders.all')}
                      </Box>
                    </Fragment>
                  )}
                </Box>
                { /* Bouton Filtrer et Reset */}
                <Stack
                  className="bottomBtnsContainer"
                  direction="row"
                  spacing={2}
                  sx={{ justifyContent: 'center' }}
                >
                  { /* Bouton Filtrer */}
                  <Tooltip title={t('anr.actions.filter')} placement="left">
                    <Button color="primary" type="submit" disabled={!form.isValid}>
                      <Search />
                    </Button>
                  </Tooltip>
                  { /* Bouton Reset */}
                  <Tooltip title={t('anr.actions.reset')} placement="right">
                    <Button
                      color="primary"
                      onClick={() => {
                        form.resetForm();
                        handleResetForm();
                      }}
                    >
                      <RestartAlt />
                    </Button>
                  </Tooltip>
                </Stack>
              </Form>
            )}
          </Formik>
        </Drawer>
      )}
    </Fragment>
  );
};

FormAnRFilters.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  numberParams: PropTypes.number.isRequired,
  stateOpenFilterNav: PropTypes.bool.isRequired,
  showNewsInputs: PropTypes.bool.isRequired,
  viewGlimpse: PropTypes.bool.isRequired,
  hasFavorites: PropTypes.bool.isRequired,
  searchedFolders: PropTypes.arrayOf(folderPropType).isRequired,
  clickFromResults: PropTypes.bool,
  handleOpenFilterNav: PropTypes.func.isRequired,
  handleResetForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSearchFolders: PropTypes.func.isRequired,
  handleFilterFolder: PropTypes.func.isRequired,
};

export default FormAnRFilters;
