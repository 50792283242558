import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Skeleton,
} from '@mui/material';

const DocumentLiteSkeleton = ({
  withCheckboxMargin = true,
  withImages = false,
  withLastLine = true,
}) => (
  <Fragment>
    <Skeleton variant="text" width="75%" height={34} />
    <Box sx={{ display: 'flex', ml: withCheckboxMargin ? 3 : 0 }}>
      {withImages && (
        <Box sx={{ flexShrink: '0', mr: 1, mt: 0.5 }}>
          <Skeleton variant="rectangular" width={100} height={60} mr={2} />
        </Box>
      )}
      <Box sx={{ flexGrow: '1', lineHeight: '2rem' }}>
        <Skeleton variant="text" width="100%" height={25} />
        <Skeleton variant="text" width="100%" height={25} />
        <Skeleton variant="text" width="100%" height={25} />
        {withLastLine && <Skeleton variant="text" width="75%" height={25} />}
      </Box>
    </Box>
  </Fragment>
);

DocumentLiteSkeleton.propTypes = {
  withCheckboxMargin: PropTypes.bool,
  withImages: PropTypes.bool,
  withLastLine: PropTypes.bool,
};

export default DocumentLiteSkeleton;
