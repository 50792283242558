import React from 'react';
import PropTypes from 'prop-types';
import {
  Article,
  Audiotrack,
  Image,
  Videocam,
} from '@mui/icons-material';

const videoRegex = /\.(mp4|avi|mov|mkv)$/i;
const imageRegex = /\.(jpg|jpeg|png|bmp|gif|webp)$/i;
const audioRegex = /\.(mp3|wav|ogg|m4a)$/i;

function IconTypeFile({ urlFile }) {
  let iconTypeFile;
  if (videoRegex.test(urlFile)) {
    iconTypeFile = <Videocam fontSize="small" color="primary" />;
  } else if (imageRegex.test(urlFile)) {
    iconTypeFile = <Image fontSize="small" color="primary" />;
  } else if (audioRegex.test(urlFile)) {
    iconTypeFile = <Audiotrack fontSize="small" color="primary" />;
  } else {
    iconTypeFile = <Article fontSize="small" color="primary" />;
  }
  return iconTypeFile;
}

IconTypeFile.propTypes = {
  urlFile: PropTypes.string.isRequired,
};

export default IconTypeFile;
