import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closePatchSpeakerDialog } from 'generic/core/ged/actions';
import PatchSpeakerDialog from 'generic/components/ui/PatchSpeakerDialog';

const PatchSpeakerDialogContainer = ({ patchDocumentInComplete }) => {
  const loading = useSelector((state) => state.ged.loading);
  const open = useSelector((state) => state.ged.dialogPatchSpeaker.open);
  const speakerId = useSelector((state) => state.ged.dialogPatchSpeaker.speakerId);
  const sentenceStart = useSelector((state) => state.ged.dialogPatchSpeaker.sentenceStart);
  const existingSpeakers = useSelector((state) => state.ged.dialogPatchSpeaker.existingSpeakers);
  const dispatch = useDispatch();

  const handleSubmit = (newSpeakerId) => {
    const params = {
      transcription_detection: 'Text_json',
      transcription_speaker_id: speakerId,
      transcription_new_speaker_id: newSpeakerId,
    };
    if (sentenceStart !== null) {
      params.transcription_sentence_start = sentenceStart;
    }
    patchDocumentInComplete(params);
  };
  const handleClose = () => {
    dispatch(closePatchSpeakerDialog());
  };

  if (!open) {
    return null;
  }
  return (
    <PatchSpeakerDialog
      handleSubmit={handleSubmit}
      loading={loading}
      handleClose={handleClose}
      speakerId={speakerId}
      sentenceStart={sentenceStart}
      existingSpeakers={existingSpeakers}
    />
  );
};

PatchSpeakerDialogContainer.propTypes = {
  patchDocumentInComplete: PropTypes.func.isRequired,
};

export default PatchSpeakerDialogContainer;
