import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box, CircularProgress, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { sourcePropType } from 'generic/core/anr/proptypes';

const ResultsBannedSites = ({
  bannedSites = [],
  columns = [],
  count = 0,
  handlePageChangePagination,
  handleSearch,
  loading = false,
  page = 0,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const options = {
    count,
    download: false,
    filter: false,
    fixedHeader: true,
    jumpToPage: true,
    page,
    print: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    selectableRows: 'none',
    serverSide: true,
    responsive: responsiveTableType,
    setTableProps: () => ({ size: 'small' }),
    sort: false,
    textLabels: t('datatable.textLabels', { returnObjects: true }),
    viewColumns: false,
    onChangePage: () => {
      const table = document.querySelector('#datatable .MuiTable-root');
      table.scrollIntoView(true);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChangePagination(tableState);
          break;
        case 'sort':
        case 'search':
        case 'filterChange':
        case 'resetFilters':
          handleSearch(tableState);
          break;
        default:
          break;
      }
    },
  };
  return (
    <Box
      sx={{
        width: '100%',
        m: smallerThanLarge ? 0 : 2,
      }}
      id="datatable"
    >
      <MUIDataTable
        title={(
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">{t('anr.banned_sites')}</Typography>
            {loading && <CircularProgress size={16} style={{ marginLeft: 10 }} />}
          </Box>
        )}
        data={bannedSites}
        columns={columns}
        options={options}
        dense
      />
    </Box>
  );
};

ResultsBannedSites.propTypes = {
  bannedSites: PropTypes.arrayOf(sourcePropType),
  columns: PropTypes.arrayOf(PropTypes.shape()),
  count: PropTypes.number,
  handlePageChangePagination: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  page: PropTypes.number,
};

export default ResultsBannedSites;
