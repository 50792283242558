import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, ButtonGroup } from '@mui/material';
import { NotificationAddOutlined } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import Criterion from 'generic/components/ui/Criterion';
import NbResultsStrip from 'generic/components/ui/NbResultsStrip';
import { prettyPrintNumber } from 'generic/utils/mathUtils';
import QuickResultsHeaderSkeleton from 'generic/components/skeletons/QuickResultsHeaderSkeleton';

const QuickResultsHeader = ({
  canCreateAlerts = false,
  criteria = [],
  handleOpenCreateAlert,
  loadingBlank,
  nbResults = undefined,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        pb: 0.5,
        flexGrow: '1',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          margin: 2,
          textAlign: 'center',
          fontSize: '24px',
          textTransform: 'uppercase',
        }}
      >
        {t('dashboard.quick_results_title')}
      </Box>
      {loadingBlank ? (
        <QuickResultsHeaderSkeleton />
      ) : (
        <Fragment>
          <Box sx={{ flexGrow: '1', display: 'flex', flexWrap: 'wrap' }}>
            {canCreateAlerts && (
              <ButtonGroup
                sx={{ height: '32px', mr: 2 }}
                disableElevation
              >
                <TooltipButton
                  key="alert"
                  sx={{ borderColor: 'primary', borderRadius: '15px' }}
                  title={t('actions.create_alert')}
                  onClick={handleOpenCreateAlert}
                >
                  <NotificationAddOutlined />
                </TooltipButton>
              </ButtonGroup>
            )}
            {(
              _.map(
                criteria,
                (criterion) => (
                  <Criterion
                    key={criterion.mouvementChamp}
                    criterion={criterion}
                    highlighted={criterion.isNew}
                    readOnly
                  />
                ),
              )
            )}
          </Box>
          <Box
            sx={{
              flexShrink: '0',
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'right',
            }}
          >
            {nbResults !== undefined && (
              <NbResultsStrip>
                {`${prettyPrintNumber(nbResults)} ${t('results.document', { count: nbResults })}`}
              </NbResultsStrip>
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

QuickResultsHeader.propTypes = {
  canCreateAlerts: PropTypes.bool,
  criteria: PropTypes.arrayOf(PropTypes.shape({
    champ: PropTypes.number,
    istext: PropTypes.bool,
    libelle: PropTypes.string,
    libelleNombre: PropTypes.string,
    mouvementChamp: PropTypes.number,
    operateur: PropTypes.string,
    parentheseFermante: PropTypes.string,
    parentheseOuvrante: PropTypes.string,
    url: PropTypes.string,
  })),
  handleOpenCreateAlert: PropTypes.func.isRequired,
  loadingBlank: PropTypes.bool.isRequired,
  nbResults: PropTypes.number,
};

export default QuickResultsHeader;
