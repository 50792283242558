import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Avatar,
  Box,
  Link,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import {
  getLogonFromStorage,
  getTokenFromStorage,
} from 'generic/utils/utils';
import {
  humanizeNewsletterSendingSchedule,
} from 'generic/utils/qesUtils';
import { cartOrNewsletterPropType } from 'generic/core/qes/proptypes';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const nameBodyRendered = (itemsType) => (value, tableMeta) => {
  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  const itemLink = itemsType === 'newsletters' ? 'newsletters' : 'carts';
  const { rowData } = tableMeta;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Link
        component={RouterLink}
        underline="none"
        to={`${itemLink}/${rowData[0]}`}
      >
        {value}
      </Link>
      {!_.isEmpty(rowData[1]) && (
        <Avatar
          alt=""
          src={`${API_ENTRY_POINT}/file?type=0&file=${rowData[1]}&logon=${logon}&key=${key}`}
          sx={{ width: 30, height: 30 }}
        />
      )}
    </Box>
  );
};

const CartsOrNewsletters = ({
  items,
  handleDelete,
  itemsType,
  loading = false,
  themes = [],
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'image',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: t(`${itemsType}.name`),
      options: {
        filter: false,
        customBodyRender: nameBodyRendered(itemsType),
      },
    },
    {
      name: 'visibility',
      label: t(`${itemsType}.visibility`),
      options: {
        filter: true,
      },
    },
    {
      name: 'owner',
      label: t(`${itemsType}.owner`),
      options: {
        filter: true,
      },
    },
    {
      name: 'contributors',
      label: t(`${itemsType}.contributors`),
      options: {
        filter: false,
      },
    },
    {
      name: 'theme_panier',
      label: t(`${itemsType}.theme`),
      options: {
        filter: false,
        display: itemsType === 'newsletters' ? true : 'excluded',
        download: itemsType === 'newsletters',
      },
    },
    {
      name: 'sending',
      label: t(`${itemsType}.sending`),
      options: {
        filter: false,
        display: itemsType === 'newsletters' ? true : 'excluded',
        download: itemsType === 'newsletters',
      },
    },
    {
      name: 'number_of_documents',
      label: t(`${itemsType}.number_of_documents`),
      options: {
        filter: false,
      },
    },
    {
      name: 'date_of_creation',
      label: t(`${itemsType}.date_of_creation`),
      options: {
        filter: false,
        customBodyRender: (value) => {
          const date = new Date(value);
          return date.toLocaleDateString();
        },
      },
    },
  ];

  const data = items.map(
    (item) => [
      item.panier,
      item.image,
      item.intitule,
      t(`${itemsType}.visibility_values.${item.visibilite_panier}`),
      item.utilisateur_owner_libelle,
      _.map(item.panierUtilisateurs, 'utilisateur_libelle').join(', '),
      _.get(_.find(themes, { theme_panier: item.theme_panier }), 'libelle', ''),
      humanizeNewsletterSendingSchedule(item),
      item.panierDocumentsCount,
      item.date_creation,
    ],
  );

  const options = {
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    filterType: 'checkbox',
    fixedHeader: true,
    textLabels: t('datatable.textLabels', { returnObjects: true }),
    downloadOptions: { filename: `${itemsType}_list.csv` },
    selectableRowsOnClick: false,
    onRowsDelete: (rowsDeleted) => handleDelete(rowsDeleted, data),
    onChangePage: () => {
      const table = document.querySelector('#datatable .MuiTable-root');
      table.scrollIntoView(true);
    },
    responsive: responsiveTableType,
    sortOrder: {
      name: 'date_of_creation',
      direction: 'desc',
    },
    setTableProps: () => ({ size: 'small' }),
  };
  return (
    <Box
      sx={{
        width: '100%',
        m: smallerThanLarge ? 0 : 2,
      }}
      id="datatable"
    >
      {loading && (
        <GlobalLoading />
      )}
      <MUIDataTable
        dense
        title={t(`${itemsType}.list`)}
        data={data}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

CartsOrNewsletters.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(cartOrNewsletterPropType).isRequired,
  itemsType: PropTypes.oneOf(['carts', 'newsletters']).isRequired,
  loading: PropTypes.bool,
  themes: PropTypes.arrayOf(PropTypes.shape({
    libelle: PropTypes.string,
    theme_panier: PropTypes.number,
  })),
};

export default CartsOrNewsletters;
