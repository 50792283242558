import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from 'formik-mui';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import {
  Email, Lock, Visibility, VisibilityOff,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from 'generic/core/constants';

const commonProps = {
  sx: (theme) => ({
    borderRadius: '4px',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.common.white : 'unset',
    border: '1px solid',
    borderColor:
      theme.palette.mode === 'light' ? '#E2E8F0' : 'rgba(255, 255, 255, 0.23)',
    width: '100%',
    padding: '0 0.75rem',
    margin: 0,
    '&:has(.Mui-error)': {
      '& .MuiInputAdornment-positionStart': {
        color: theme.palette.text.error,
      },
      borderColor: theme.palette.text.error,
    },
    '& .MuiInput-root': {
      margin: 0,
    },
    '& .MuiInput-input': {
      fontWeight: 500,
      fontSize: '14px',
    },
    '& .MuiInput-input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.backgroundLogin.main} inset`,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'currentColor',
    },
    '&:not(.forcedClassicFieldsetField) .MuiInputLabel-root': {
      transform: 'translate(0px, -24px)',
    },
    '&.MuiTextField-root:not(.forcedClassicFieldsetField):has(> label)': {
      marginTop: '24px',
    },
    '& > .MuiFormHelperText-root': {
      position: 'absolute',
      top: '-1.6rem',
      right: 0,
      '&.Mui-error': {
        color: theme.palette.text.error,
      },
    },
  }),
};

export const EmailTextField = ({ innerRef = null, ...rest }) => (
  <TextField
    sx={commonProps.sx}
    variant="standard"
    margin="normal"
    size="medium"
    InputLabelProps={{
      shrink: true,
      margin: 'dense',
    }}
    InputProps={{
      disableUnderline: true,
      inputProps: {
        ref: innerRef,
      },
      startAdornment: (
        <InputAdornment position="start">
          <Email fontSize="small" />
        </InputAdornment>
      ),
    }}
    type="text"
    {...rest}
  />
);
EmailTextField.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export const PasswordTextField = ({ innerRef = null, ...rest }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      sx={commonProps.sx}
      variant="standard"
      margin="normal"
      size="medium"
      InputProps={{
        disableUnderline: true,
        inputProps: {
          ref: innerRef,
        },
        startAdornment: (
          <InputAdornment position="start">
            <Lock fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label={showPassword ? t('logon.password_aria_hide') : t('logon.password_aria_show')}
              onClick={() => setShowPassword((v) => !v)}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff fontSize="small" />
              ) : (
                <Visibility fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
      {...rest}
    />
  );
};
PasswordTextField.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export const LoadingButtonWithIndicator = ({ ...props }) => (
  <LoadingButton
    sx={{
      minWidth: '180px',
      background: '#2563EB',
      color: 'white',
      '&:hover': { background: '#1E40AF' },
    }}
    fullWidth
    loadingIndicator={<CircularProgress sx={{ color: 'white' }} size={16} />}
    {...props}
  />
);

export function ForgottenPasswordButton({ onClick, children }) {
  return (
    <Button
      size="small"
      variant="text"
      onClick={onClick}
      sx={{
        textTransform: 'none',
        color: 'backgroundLogin.secondaryContrastText',
        alignSelf: 'flex-end',
        // Negative value of `FormFieldsContainer` spacing
        marginTop: '-24px',
      }}
    >
      {children}
    </Button>
  );
}
ForgottenPasswordButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export function FormFieldsContainer({ children }) {
  return (
    <Stack
      direction="column"
      spacing="24px"
      useFlexGap
      sx={(theme) => ({
        width: 480,
        padding: '1.5rem',
        background: theme.palette.background.special,
        borderRadius: '16px',
        border: '2px solid',
        borderImageSource:
          'linear-gradient(90deg, rgba(37, 99, 235, 0) 20%, #2563EB 50%, rgba(37, 99, 235, 0) 80%)',
        borderImageSlice: 10,
      })}
    >
      {children}
    </Stack>
  );
}
FormFieldsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export function FormContainer({ title, desc = null, children }) {
  return (
    <Stack
      direction="column"
      spacing="32px"
      alignItems="center"
      useFlexGap
      sx={{
        width: 480,
      }}
    >
      <Grid item container direction="column" sx={{ alignItems: 'center' }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '1.75rem',
            color: 'backgroundLogin.contrastText',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '1rem',
            color: 'backgroundLogin.secondaryContrastText',
            textAlign: 'center',
            maxWidth: 400,
          }}
        >
          {desc}
        </Typography>
      </Grid>
      {children}
      <Typography
        sx={{
          fontSize: 14,
          color: 'backgroundLogin.secondaryContrastText',
          position: 'absolute',
          bottom: '7rem',
        }}
      >
        {`${CONSTANTS.PROJECT_NAME} by ChapsVision`}
      </Typography>
    </Stack>
  );
}
FormContainer.propTypes = {
  title: PropTypes.node.isRequired,
  desc: PropTypes.node,
  children: PropTypes.node.isRequired,
};
