import PropTypes from 'prop-types';
import { stringOrArrayOfStrings } from 'generic/utils/propTypeUtils';

const qesdocumentPropType = PropTypes.shape({
  BASE_ID: stringOrArrayOfStrings.isRequired,
  idext: stringOrArrayOfStrings.isRequired,
  PUB_DATE: stringOrArrayOfStrings,
  QUOTE_URL: stringOrArrayOfStrings,
  SOURCE: stringOrArrayOfStrings,
  TITLE: stringOrArrayOfStrings,
  URL: stringOrArrayOfStrings,
});

const documentPropType = PropTypes.shape({
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};
