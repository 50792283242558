import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  IconButton,
} from '@mui/material';
import { Search } from '@mui/icons-material';

const ToggleMobileSearchIcon = ({
  handleClick,
  isVisible,
  isBadgeVisible = false,
  headerBackgroundColorIsDark = true,
}) => (
  <IconButton
    onClick={handleClick}
    sx={{
      backgroundColor: headerBackgroundColorIsDark ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.1)',
      display: isVisible ? 'inherit' : 'none',
      padding: '8px',
      color: headerBackgroundColorIsDark ? 'common.white' : 'common.black',
      '&:hover': {
        backgroundColor: headerBackgroundColorIsDark ? 'rgba(255,255,255,0.4)' : 'rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    <Badge
      color="error"
      variant="dot"
      invisible={!isBadgeVisible}
    >
      <Search />
    </Badge>
  </IconButton>
);

ToggleMobileSearchIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isBadgeVisible: PropTypes.bool,
  headerBackgroundColorIsDark: PropTypes.bool,
};

export default ToggleMobileSearchIcon;
