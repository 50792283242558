import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import i18next from 'i18next';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSunburst from 'highcharts/modules/sunburst';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);
HighchartsSunburst(Highcharts);

const Sunburst = ({ highchartsOptions }) => {
  const defaultOptions = {
    highcharts: Highcharts,
    options: {
      plotOptions: {
        series: {
          animation: false,
        },
      },
      chart: { type: 'sunburst' },
      credits: { enabled: false },
      colors: ['transparent'].concat(Highcharts.getOptions().colors),
      series: [{
        name: i18next.t('dashboard.root'),
        allowDrillToNode: true,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
          filter: {
            property: 'innerArcLength',
            operator: '>',
            value: 16,
          },
          rotationMode: 'circular',
        },
        levels: [{
          level: 1,
          levelIsConstant: false,
          dataLabels: {
            filter: {
              property: 'outerArcLength',
              operator: '>',
              value: 64,
            },
          },
        }, {
          level: 2,
          colorByPoint: true,
        },
        {
          level: 3,
          colorVariation: {
            key: 'brightness',
            to: -0.5,
          },
        }],
      }],
      title: { text: 'Titre Sunburst' },
    },
  };
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
    />
  );
};

Sunburst.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default Sunburst;
