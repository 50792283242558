import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Collapse } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Highcharts from 'highcharts';
import TooltipButton from 'generic/components/ui/TooltipButton';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';

const DateHistogram = ({
  isHistogramVisible,
  handleToggleHistogramVisibility,
  highchartsOptions = {},
}) => {
  const currentTheme = useColorSchemeDetector();
  Highcharts.setOptions(currentTheme.HIGHCHARTS);

  const globalHighChartsOptions = {
    options: {
      accessibility: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
    },
  };
  const finalOptions = _.merge({}, globalHighChartsOptions, highchartsOptions);

  return (
    <div>
      <TooltipButton
        sx={{
          bottom: isHistogramVisible ? '70px' : '20px',
          position: 'absolute',
          right: '20px',
          transition: 'bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          zIndex: 1200,
        }}
        tag="fab"
        size="extraSmall"
        color="secondary"
        onClick={handleToggleHistogramVisibility}
      >
        {isHistogramVisible ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      </TooltipButton>
      <Collapse
        in={isHistogramVisible}
      >
        <ColumnHisto highchartsOptions={finalOptions} />
      </Collapse>
    </div>
  );
};

DateHistogram.propTypes = {
  isHistogramVisible: PropTypes.bool.isRequired,
  handleToggleHistogramVisibility: PropTypes.func.isRequired,
  highchartsOptions: PropTypes.shape(),
};

export default DateHistogram;
