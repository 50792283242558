import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      column: {
        borderRadius: 3,
      },
      series: {
        animation: false,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '9px',
          },
        },
      },
    },
    xAxis: {
      type: 'categories',
      gridLineWidth: 0,
      labels: {
        align: 'right',
        style: {
          font: 'normal 9px Verdana, sans-serif',
        },
        maxStaggerLines: 1,
      },
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: '',
      },
    },
    chart: {
      type: 'column',
      defaultSeriesType: 'column',
      zoomType: 'x',
    },
  },
};

const Column = ({ highchartsOptions }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
    />
  );
};

Column.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default Column;
