import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Divider,
} from '@mui/material';

const JsxJoinerWithSeparator = ({
  items,
  separator = <Box sx={{ mx: 1, py: '2px' }}><Divider orientation="vertical" /></Box>,
}) => _.compact(items).map((item, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <Fragment key={index}>
    {!!index && separator}
    {item}
  </Fragment>
));

JsxJoinerWithSeparator.propTypes = {
  separator: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default JsxJoinerWithSeparator;
