import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    chart: { type: 'pie' },
    credits: { enabled: false },
    plotOptions: {
      series: {
        animation: false,
      },
      pie: {
        borderWidth: 0,
        showInLegend: false,
        size: '75%',
        innerSize: '60%',
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          connectorWidth: 1,
          distance: 20,
          softConnector: false,
          style: {
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    tooltip: {
      pointFormat: '<b>{point.y} ({point.percentage:.1f}%)</b>',
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                size: '60%',
                dataLabels: {
                  formatter() {
                    if (this.point.name.length > 20) {
                      return `${this.point.name.substring(0, 20)}...`;
                    }
                    return this.point.name;
                  },
                },
              },
            },
          },
        },
        {
          condition: {
            minWidth: 800,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                dataLabels: {
                  style: {
                    textOverflow: 'undefined',
                  },
                },
              },
            },
          },
        },
      ],
    },
    series: [{
      name: 'Data',
    }],
    title: { text: 'Titre Pie' },
  },
};

const Pie = ({ highchartsOptions }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
    />
  );
};

Pie.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default Pie;
