import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Box } from '@mui/material';
import { cleanupCarts, fetchCarts, types } from 'generic/core/carts/actions';
import CartsOrNewsletters from 'generic/components/pages/CartsOrNewsletters';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import CenteredMessage from 'generic/components/ui/CenteredMessage';

const CartsContainer = () => {
  const loading = useSelector((state) => state.carts.loading);
  const carts = useSelector((state) => state.carts.carts);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOpenDeleteCartsConfirmDialog = (rowsDeleted, data) => {
    const ids = [];
    for (let i = 0; i < rowsDeleted.data.length; i++) {
      if (data[rowsDeleted.data[i].dataIndex]) {
        ids.push(data[rowsDeleted.data[i].dataIndex][0]);
      }
    }
    dispatch(setConfirmDialogData({
      title: t('carts.delete'),
      textButton: 'form.delete',
      message: `${t('carts.delete_carts', { count: ids.length })} ?`,
      submitColor: 'error',
      waitForActions: [types.DELETE_CARTS_SUCCESS, types.DELETE_CARTS_ERROR],
      action: {
        type: types.DELETE_CARTS,
        ids,
      },
    }));
    return false;
  };

  useEffect(() => {
    dispatch(fetchCarts());

    // Cleanup des paniers lorsque le composant est "unmount" (donc quand on
    // change de page)
    return () => dispatch(cleanupCarts());
  }, [dispatch]);

  if (_.isEmpty(carts)) {
    if (loading) {
      return <GlobalLoading />;
    }
    return (
      <Box sx={{ width: '100%' }}>
        <CenteredMessage>
          {t('carts.no_carts')}
        </CenteredMessage>
      </Box>
    );
  }

  return (
    <CartsOrNewsletters
      handleDelete={handleOpenDeleteCartsConfirmDialog}
      items={carts}
      itemsType="carts"
      loading={loading}
    />
  );
};

export default CartsContainer;
