import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { formatTime } from 'generic/utils/dateUtils';

const EditTranscriptionAnnotationDialog = ({
  annotation = '',
  handleClose,
  handleSubmit,
  loading = false,
  start,
}) => {
  const { t } = useTranslation();
  const [newAnnotation, setNewAnnotation] = useState(annotation);

  return (
    <Dialog
      disableRestoreFocus
      scroll="paper"
      onKeyPress={(event) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
          handleSubmit(newAnnotation);
        }
      }}
      open
      PaperProps={{
        sx: { minWidth: '700px' },
      }}
    >
      <DialogTitle>
        {t(`ged.${annotation ? 'edit' : 'add'}_transcription_annotation`)}
      </DialogTitle>
      <DialogContent
        dividers
      >
        <Trans>
          {t(`ged.${annotation ? 'edit' : 'add'}_transcription_annotation_sure`, { start: formatTime(start * 1000) })}
        </Trans>
        <FormControl
          sx={{ my: 2, width: '100%' }}
        >
          <TextField
            value={newAnnotation}
            autoFocus
            onChange={(e) => setNewAnnotation(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          disabled={!_.trim(newAnnotation)}
          onClick={() => handleSubmit(newAnnotation)}
        >
          {t('form.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

EditTranscriptionAnnotationDialog.propTypes = {
  annotation: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  start: PropTypes.number.isRequired,
};

export default EditTranscriptionAnnotationDialog;
