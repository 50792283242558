import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const TabPanel = ({
  children, value, index, sx = {}, ...other
}) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    sx={{
      ...sx,
      p: 2,
      width: '100%',
      flex: 1,
    }}
    {...other}
  >
    {value === index && children}
  </Box>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  sx: PropTypes.shape(),
};

const memoized = React.memo(TabPanel);
export default memoized;
